import React, { useState, useEffect, useRef } from "react";
import DashboardCurrencyStatus from "./DashboardCurrencyStatus";
import "./Styles/page_dashboard.css";

const Dashboard = () => {

    const [tabValue, setTabValue] = useState("Top Gainers");
    const tabArea = useRef(null);

    useEffect(() => {
        tabArea.current.style.animation = "none";
        let tempForAnimation = tabArea.current.offsetHeight;
        tabArea.current.style.animation = "open-animation 1.5s";
    }, [tabValue]);
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border section-centertabs">
                <div className="section-centertabs-tabs">
                    <div className={tabValue == "Hot" ? "active" : undefined} onClick={() => setTabValue("Hot")}><i className="fa-solid fa-fire"></i>Hot</div>
                    <div className={tabValue == "Top Gainers" ? "active" : undefined} onClick={() => setTabValue("Top Gainers")}>Top Gainers</div>
                    <div className={tabValue == "Top Losers" ? "active" : undefined} onClick={() => setTabValue("Top Losers")}>Top Losers</div>
                    <div className={tabValue == "Spot Deactive" ? "active" : undefined} onClick={() => setTabValue("Spot Deactive")}>Spot Deactive</div>
                    <div className={tabValue == "Future Deactive" ? "active" : undefined} onClick={() => setTabValue("Future Deactive")}>Future Deactive</div>
                </div>
                <div className="section-centertabs-tab-area" ref={tabArea}>
                    {tabValue == "Hot" && <DashboardCurrencyStatus type="hot" />}
                    {tabValue == "Top Gainers" && <DashboardCurrencyStatus type="positive" />}
                    {tabValue == "Top Losers" && <DashboardCurrencyStatus type="negative" />}
                    {tabValue == "Spot Deactive" && <DashboardCurrencyStatus type="spot_deactive" />}
                    {tabValue == "Future Deactive" && <DashboardCurrencyStatus type="future_deactive" />}
                </div>
            </div>
        </div>
    )

}

export default Dashboard;