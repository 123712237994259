import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import { useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import FutureProcessEvents from "./FutureProcessEvents";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment-timezone";
import Decimal from 'decimal.js';
import LinearProgress from '@mui/material/LinearProgress';
import { formatValuePriceControl, formatValue3, formatValue4, formatValue5 } from '../Lib/calculationFunctions';

const CreateFutureProcessHistory = ({ fromPage }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);

    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const tableArea = useRef(null);//tablo alanı

    const [historyDataScroll, setHistoryDataScroll] = useState(null);
    const [historyDataScrollLastEvaluatedKey, setHistoryDataScrollLastEvaluatedKey] = useState(null);
    const [getNewHistory, setGetNewHistory] = useState(false);
    const itemsScrollPosition = useRef(0);
    const newHistoryLoading = useRef(false);

    useEffect(() => {//link değiştikçe animasyon tetikleniyor ve açılıştaki veriler düzenleniyor
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }

        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
        }
    }, [location]);

    useEffect(() => {//açılıştaki veriler düzenleniyor
        setHistoryDataScroll(null);
        getHistoryInfo("", "initial");
    }, [customerId, fromPage]);

    useEffect(() => {
        const historyElements = document.querySelector('.MuiTableContainer-root');
        if(historyElements){
            if(getNewHistory){
                historyElements.scrollTop = historyElements.scrollHeight - historyElements.clientHeight;
            }
            else{
                historyElements.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [getNewHistory]);

    useEffect(() => {
        const tableContainer = document.querySelector('.processes-history .MuiTableContainer-root');

        if (tableContainer) {
            tableContainer.addEventListener('scroll', historyElementsOnScroll);
        }
    
        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', historyElementsOnScroll);
            }
        };
    }, [historyDataScroll]);

    const getHistoryInfo = async (linkInfo, getType) => {
        if((fromPage == "profile" && customerId) || fromPage != "profile"){
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            try {
                const response = await axios.get(`https://operationapi.kpriceapp.com/futureprocess/history/?${fromPage == "profile" && customerId ? `type=user&customer_id=${customerId}` : "type=general"}${linkInfo}`, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                
                if(response.status == "200"){
                    let currentData = response.data.history_items;
                    currentData.forEach(item => {
                        if(item.entry_timestamp){
                            item.entry_timestamp = Number(item.entry_timestamp);
                            item.entry_date = moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                        if(item.result_timestamp){
                            item.result_timestamp = Number(item.result_timestamp);
                            item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                    });

                    if(getType == "initial"){
                        setHistoryDataScroll(currentData);
                    }
                    else if(getType == "additional"){
                        setHistoryDataScroll((prevItems) => (Array.isArray(prevItems) ? [...prevItems, ...currentData] : [...currentData]));
                    }
                    setHistoryDataScrollLastEvaluatedKey(response.data.last_evaluated_key);
                }
                else{
                    toast.error("There was a problem loading the information!");
                }
            }
            catch (error) {
                toast.error("There was a problem loading the information!");
            }
        }
    }

    const historyElementsOnScroll = async () => {
        const historyElements = document.querySelector('.processes-history .MuiTableContainer-root');
        const { scrollTop, scrollHeight, clientHeight } = historyElements;
        if(scrollTop + clientHeight + 2 >= scrollHeight && historyDataScrollLastEvaluatedKey && !newHistoryLoading.current){//eğer divin en altına gelindiyse
            newHistoryLoading.current = true;
            setGetNewHistory(true);
            if(historyElements){
                itemsScrollPosition.current = historyElements.scrollTop;
            }

            let linkInfo = `&order_id=${historyDataScrollLastEvaluatedKey.order_id ? historyDataScrollLastEvaluatedKey.order_id : null}&result_timestamp=${historyDataScrollLastEvaluatedKey.result_timestamp ? historyDataScrollLastEvaluatedKey.result_timestamp : null}`;

            if(fromPage == "history"){
                linkInfo = `${linkInfo}&last_key_customer_id=${historyDataScrollLastEvaluatedKey.customer_id ? historyDataScrollLastEvaluatedKey.customer_id : null}`;
            }
            
            getHistoryInfo(linkInfo, "additional");
        }
        else if(!newHistoryLoading.current){
            setGetNewHistory(false);
        }
    }

    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const ItemMarket = ({ product_id }) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const ItemPrice = ({ product_id, value }) => {
        const show_places = productsData && productsData[product_id] && productsData[product_id].max_order_asking_price_places ? productsData[product_id].max_order_asking_price_places : null;
        
        if(value == "" || value == null || value == undefined){
            return "";
        } else{
            const response = formatValuePriceControl(value, show_places);
            if(response){
                return formatValue3(value, show_places);
            }
            else{
                return formatValue4(value);
            }
        }
    }

    const ItemFutureType = ({ type }) => {
        if(type === "buy"){
            return <span className="success">Buy</span>;
        } else{
            return <span className="danger">Sell</span>;
        }
    }

    const processesDataControl_CalcProfit = (future_type, product_id, entry_price, available_count) => {
        let profit = 0;

        if(future_type == "buy"){
            profit = (new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0).minus(new Decimal(entry_price))).times(new Decimal(available_count));
        }
        else if(future_type == "sell"){
            profit = (new Decimal(entry_price).minus(new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0))).times(new Decimal(available_count));
        }
        
        return Number(profit);
    }
    
    const ItemProfit = ({ item }) => {
        if(item.state && item.future_type){
            const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;
            const currentProfit = item.state == "active" ? processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count) : item.process_profit;
            return <span className={currentProfit != "" && currentProfit != null && currentProfit != undefined ? (currentProfit > 0 ? "success" : (currentProfit < 0 ? "danger" : undefined)) : undefined}>{currentProfit != "" && currentProfit != null && currentProfit != undefined ? formatValue5(currentProfit, showPlacesUSDT) : ""}</span>
        }
        return "";
    }

    const tableColumns = useMemo(() => [//tablo başlıkları
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'process_result_timestamp', header: 'Close Date', Cell: ({ row }) => row.original.process_result_date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'future_type', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
    ], []);

    const tableData = useMemo(() => {//tablo bilgileri
        const newData = [];

        currenciesData && productsData && historyDataScroll && historyDataScroll.map((history) => {
            newData.push({
                customer_id: history.customer_id ? history.customer_id : "",
                customerIdForTable: <ProfileButton customer_id={history.customer_id ? history.customer_id : ""} />,
                market: history.product_id,
                marketForTable: <ItemMarket product_id={history.product_id} />,
                leverage: history.leverage ? Number(history.leverage) : 0,
                stopLossForTable: <ItemPrice product_id={history.product_id} value={history.stop_loss} />,
                takeProfitForTable: <ItemPrice product_id={history.product_id} value={history.take_profit} />,
                process_timestamp: history.result_timestamp ? history.result_timestamp : history.entry_timestamp,
                process_order_id: history.order_id ? history.order_id : "",
                process_entry_date: history.entry_date ? history.entry_date : "",
                process_result_date: history.result_date ? history.result_date : "",
                process_result_timestamp: history.result_timestamp ? history.result_timestamp : 0,
                future_type: history.future_type ? history.future_type : "",
                futureTypeForTable: history.future_type ? <ItemFutureType type={history.future_type} /> : "",
                profit: history.process_profit ? Number(history.process_profit) : 0,
                profitForTable: history.process_profit ? <ItemProfit item={history} /> : "",
            })
        })

        return newData;
    }, [historyDataScroll, currenciesData, productsData]);

    useEffect(() => {
        setGetNewHistory(false);
        newHistoryLoading.current = false;
    }, [historyDataScroll, historyDataScrollLastEvaluatedKey, tableData]);

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon
        let row = null;
        let rowIsValid = false;
        if(event.target.tagName == "TR"){
            rowIsValid = true;
            row = event.target;
        }
        else{
            rowIsValid = true;
            row = event.target.closest('tr');
        }
        
        if(row && rowIsValid){
            const expandButton = row.querySelector("td:first-child button");
            if(expandButton){
                expandButton.click();
            }
        }
    };

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.processes-history .withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [tableData]);
    
    return (
        <div className="table-area processes-history">
            <div className={historyDataScroll ? "list" : "list loading"}>
                {historyDataScroll ? 
                    <div className="column5 withExpandMenu noPagination">
                        <MaterialReactTable columns={tableColumns} data={tableData} enablePagination={false}
                            renderDetailPanel={({ row }) => (
                                <div className="submenu-values process">
                                    <div className="submenu-process-values-left" data-orderid={row.original.process_order_id}>
                                        <div>
                                            <span>Entry Date:</span>
                                            <label>{row.original.process_entry_date}</label>
                                        </div>
                                        <div>
                                            <span>Close Date:</span>
                                            <label>{row.original.process_result_date}</label>
                                        </div>
                                        <div>
                                            <span>Leverage:</span>
                                            <label>{row.original.leverage}</label>
                                        </div>
                                        <div className="take_profit">
                                            <span>Take Profit:</span>
                                            <label>{row.original.takeProfitForTable}</label>
                                        </div>
                                        <div className="stop_loss">
                                            <span>Stop Loss:</span>
                                            <label>{row.original.stopLossForTable}</label>
                                        </div>
                                    </div>
                                    <div className="submenu-process-values-right">
                                        <FutureProcessEvents orderId={row.original.process_order_id} customerId={row.original.customer_id} />
                                    </div>
                                </div>
                            )}
                            />
                    </div>
                : <Loading />}
            </div>
            {getNewHistory && (
                <div className="table-area-loading-animation">
                    <LinearProgress />
                </div>
            )}
        </div>
    );
    
}

const FutureProcessHistory = ({ fromPage }) => {
    
    return (
        <>
            {fromPage == "history" ? 
                <div id="main" className="main">
                    <div className="allsections allsections-border">
                        <CreateFutureProcessHistory fromPage={fromPage} />
                    </div>
                </div>
                : <CreateFutureProcessHistory fromPage={fromPage} />
            }
        </>
    );
    
}

export default FutureProcessHistory;