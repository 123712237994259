import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import FutureProcessEvents from "./FutureProcessEvents";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment-timezone";
import Decimal from 'decimal.js';
import { formatValuePriceControl, formatValue3, formatValue4, formatValue5 } from '../Lib/calculationFunctions';

const SpotFutureHistoryDetails = ({ dataType, pageType }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);

    const [tabValue, setTabValue] = useState("approved");
    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const tableArea = useRef(null);//tablo alanı
    const dateArea = useRef(null);//tarih seçme alanı

    const [historyDataDate, setHistoryDataDate] = useState(null);
    const [startDateInfo, setStartDateInfo] = useState(null);
    const [endDateInfo, setEndDateInfo] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");

    useEffect(() => {//link değiştikçe animasyon tetikleniyor ve açılıştaki veriler düzenleniyor
        if(dateArea && dateArea.current){
            dateArea.current.style.animation = "none";
            let tempForAnimation = dateArea.current.offsetHeight;
            dateArea.current.style.animation = "open-animation 1s";
        }

        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
        }

        const currentStartDate = query.get("start_date");
        if(currentStartDate){
            setStartDateInfo(currentStartDate);
        }
        else{
            setStartDateInfo(null);
        }

        const currentEndDate = query.get("end_date");
        if(currentEndDate){
            setEndDateInfo(currentEndDate);
        }
        else{
            setEndDateInfo(null);
        }
    }, [location]);

    useEffect(() => {
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }
    }, [tabValue]);

    useEffect(() => {
        setSelectedStartDate("");
        setSelectedEndDate("");
    }, [startDateInfo, endDateInfo]);

    useEffect(() => {
        setHistoryDataDate(null);
        if(startDateInfo || endDateInfo){
            let error = false;

            if(startDateInfo != null && startDateInfo != "" && endDateInfo != null && endDateInfo != ""){
                const datePartsStart = startDateInfo.split('-').map(Number);
                const datePartsEnd = endDateInfo.split('-').map(Number);
    
                if(datePartsStart[0] > datePartsEnd[0] || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] > datePartsEnd[1]) || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] == datePartsEnd[1] && datePartsStart[2] > datePartsEnd[2])){
                    toast.error("The start date cannot be greater than the end date!");
                    error = true;
                }
                else{
                    let linkInfo = "";

                    if((dataType == "spotorder" || dataType == "futureorder") && pageType == "customer"){
                        linkInfo = `type=general&customer_id=${customerId}&state=${tabValue}&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }
                    else if((dataType == "spotorder" || dataType == "futureorder") && pageType == "all"){
                        linkInfo = `type=general&state=${tabValue}&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }
                    else if(dataType == "futurepositionhistory" && pageType == "customer"){
                        linkInfo = `type=user&customer_id=${customerId}&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }
                    else if(dataType == "futurepositionhistory" && pageType == "all"){
                        linkInfo = `type=general&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }

                    if(linkInfo != ""){
                        getHistoryInfo(linkInfo);
                    }
                }
            }
            else if((startDateInfo == null || startDateInfo == "") && (endDateInfo == null || endDateInfo == "")){
                toast.error("Please select start and end date!");
                error = true;
            }
            else if(startDateInfo == null || startDateInfo == ""){
                toast.error("Please select start date!");
                error = true;
            }
            else if(endDateInfo == null || endDateInfo == ""){
                toast.error("Please select end date!");
                error = true;
            }

            if(error){
                navigate(`?type=general&customer_id=${customerId}`);
            }
        }
    }, [startDateInfo, endDateInfo, tabValue]);

    const getHistoryInfo = async (linkInfo) => {
        if(linkInfo != ""){
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            let getLink = "";

            if(dataType == "spotorder" || dataType == "futureorder"){
                getLink = `https://operationapi.kpriceapp.com${pageType == "customer" ? "/customer" : ""}/${dataType == "spotorder" ? "spotorder" : (dataType == "futureorder" ? "futureorder" : "")}/date/?${linkInfo}`;
            }
            else if(dataType == "futurepositionhistory"){
                getLink = `https://operationapi.kpriceapp.com/futureprocess/history/date/?${linkInfo}`;
            }
    
            try {
                const response = await axios.get(getLink, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                
                if(response.status == "200"){
                    let currentData = response.data.history_items;
                    currentData.forEach(item => {
                        if(item.entry_timestamp){
                            item.entry_timestamp = Number(item.entry_timestamp);
                            item.entry_date = moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                        if(item.result_timestamp){
                            item.result_timestamp = Number(item.result_timestamp);
                            item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                    });
                    setHistoryDataDate(currentData);
                }
                else{
                    toast.error("There was a problem loading history information!");
                }
            }
            catch (error) {
                toast.error("There was a problem loading history information!");
            }
        }
    }

    const viewHistoryWithDate = () => {
        if(selectedStartDate != null && selectedStartDate != "" && selectedEndDate != null && selectedEndDate != ""){
            const datePartsStart = selectedStartDate.split('-').map(Number);
            const datePartsEnd = selectedEndDate.split('-').map(Number);

            if(datePartsStart[0] > datePartsEnd[0] || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] > datePartsEnd[1]) || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] == datePartsEnd[1] && datePartsStart[2] > datePartsEnd[2])){
                toast.error("The start date cannot be greater than the end date!");
            }
            else{
                if((dataType == "spotorder" || dataType == "futureorder") && pageType == "customer" && customerId){
                    navigate(`?type=general&customer_id=${customerId}&start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
                else if((dataType == "spotorder" || dataType == "futureorder") && pageType == "all"){
                    navigate(`?type=general&start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
                else if(dataType == "futurepositionhistory" && pageType == "customer" && customerId){
                    navigate(`?type=user&customer_id=${customerId}&start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
                else if(dataType == "futurepositionhistory" && pageType == "all"){
                    navigate(`?type=general&start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
            }
        }
        else if((selectedStartDate == null || selectedStartDate == "") && (selectedEndDate == null || selectedEndDate == "")){
            toast.error("Please select start and end date!");
        }
        else if(selectedStartDate == null || selectedStartDate == ""){
            toast.error("Please select start date!");
        }
        else if(selectedEndDate == null || selectedEndDate == ""){
            toast.error("Please select end date!");
        }
    }

    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const ItemMarket = ({ product_id }) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const ItemTypeText = ({ type }) => {
        let typeText = "";
        
        if (type === "limitbuy") {
            typeText = "Limit Buy";
        } else if (type === "limitsell" || type === "stoploss" || type === "takeprofit") {
            typeText = "Limit Sell";
        } else if (type === "marketbuy") {
            typeText = "Market Buy";
        } else if (type === "marketsell") {
            typeText = "Market Sell";
        }

        return typeText;
    }

    const ItemType = ({ type }) => {
        let typeText = <ItemTypeText type={type} />;
        if (type === "limitbuy") {
            typeText = "Limit Buy";
        } else if (type === "limitsell" || type === "stoploss" || type === "takeprofit") {
            typeText = "Limit Sell";
        } else if (type === "marketbuy") {
            typeText = "Market Buy";
        } else if (type === "marketsell") {
            typeText = "Market Sell";
        }

        let typeClass = "";
        if(typeText === "Limit Buy" || typeText === "Market Buy"){
            typeClass = "success";
        } else{
            typeClass = "danger";
        }

        if(typeText === "Limit Buy" || typeText === "Limit Sell"){
            return <span className={typeClass}>Limit</span>;
        } else{
            return <span className={typeClass}>Market</span>;
        }
    }

    const ItemPrice = ({ product_id, value }) => {
        const show_places = productsData && productsData[product_id] && productsData[product_id].max_order_asking_price_places ? productsData[product_id].max_order_asking_price_places : null;
        
        if(value == "" || value == null || value == undefined){
            return "";
        } else{
            const response = formatValuePriceControl(value, show_places);
            if(response){
                return formatValue3(value, show_places);
            }
            else{
                return formatValue4(value);
            }
        }
    }

    const ItemTotal = ({ product_id, price }) => {
        const show_places = currenciesData && currenciesData[product_id.split("-")[1]] && currenciesData[product_id.split("-")[1]].show_places ? currenciesData[product_id.split("-")[1]].show_places : null;
        let editedValue = price != "" && price != null && price != undefined ? formatValue3(price, show_places) : "";
        let fullValue = price != "" && price != null && price != undefined ? formatValue4(price) : "";

        return <div className="total-value">
                    ≈ {editedValue}
                    <Tooltip title={fullValue} placement="top">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>;
    }

    const ItemFutureType = ({ type }) => {
        if(type === "buy"){
            return <span className="success">Buy</span>;
        } else{
            return <span className="danger">Sell</span>;
        }
    }

    const processesDataControl_CalcProfit = (future_type, product_id, entry_price, available_count) => {
        let profit = 0;

        if(future_type == "buy"){
            profit = (new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0).minus(new Decimal(entry_price))).times(new Decimal(available_count));
        }
        else if(future_type == "sell"){
            profit = (new Decimal(entry_price).minus(new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0))).times(new Decimal(available_count));
        }
        
        return Number(profit);
    }
    
    const ItemProfit = ({ item }) => {
        if(item.state && item.future_type){
            const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;
            const currentProfit = item.state == "active" ? processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count) : item.process_profit;
            return <span className={currentProfit != "" && currentProfit != null && currentProfit != undefined ? (currentProfit > 0 ? "success" : (currentProfit < 0 ? "danger" : undefined)) : undefined}>{currentProfit != "" && currentProfit != null && currentProfit != undefined ? formatValue5(currentProfit, showPlacesUSDT) : ""}</span>
        }
        return "";
    }

    const tableColumnsDateCustomerSpot1 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stopLossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.takeProfitForTable },
    ], []);

    const tableColumnsDateCustomerSpot2 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'total', header: 'Total', Cell: ({ row }) => row.original.totalForTable },
    ], []);

    const tableColumnsDateCustomerFuture1 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stopLossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.takeProfitForTable },
    ], []);

    const tableColumnsDateCustomerFuture2 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'total', header: 'Total', Cell: ({ row }) => row.original.totalForTable },
    ], []);

    const tableColumnsDateAllSpot1 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stopLossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.takeProfitForTable },
    ], []);

    const tableColumnsDateAllSpot2 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'total', header: 'Total', Cell: ({ row }) => row.original.totalForTable },
    ], []);

    const tableColumnsDateAllFuture1 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stopLossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.takeProfitForTable },
    ], []);

    const tableColumnsDateAllFuture2 = useMemo(() => [//tablo başlıkları
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count', Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'total', header: 'Total', Cell: ({ row }) => row.original.totalForTable },
    ], []);

    const tableColumnsDateFutureProcessHistory = useMemo(() => [//tablo başlıkları
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'process_result_timestamp', header: 'Close Date', Cell: ({ row }) => row.original.process_result_date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'future_type', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
    ], []);

    const tableDataDate = useMemo(() => {//tablo bilgileri
        const newData = [];

        currenciesData && productsData && historyDataDate && historyDataDate.map((history) => {
            newData.push({
                timestamp: tabValue == "processing" || tabValue == "waiting" ? history.entry_timestamp : history.result_timestamp,
                date: tabValue == "processing" || tabValue == "waiting" ? history.entry_date : history.result_date,
                customer_id: history.customer_id ? history.customer_id : "",
                customerIdForTable: <ProfileButton customer_id={history.customer_id ? history.customer_id : ""} />,
                market: history.product_id,
                marketForTable: <ItemMarket product_id={history.product_id} />,
                type: <ItemTypeText type={history.type} />,
                typeForTable: <ItemType type={history.type} />,
                price: tabValue == "approved" ? Number(history.processing_price) : Number(history.asking_price),
                priceForTable: tabValue == "approved" ? <ItemPrice product_id={history.product_id} value={history.processing_price} /> : <ItemPrice product_id={history.product_id} value={history.asking_price} />,
                count: tabValue == "approved" ? Number(history.realized_count) : Number(history.count),
                countForTable: tabValue == "approved" && history.realized_count != "" && history.realized_count != null && history.realized_count != undefined && history.count != "" && history.count != null && history.count != undefined ? `${formatValue4(history.realized_count)}/${formatValue4(history.count)}` : formatValue4(history.count),
                leverage: history.leverage ? Number(history.leverage) : 0,
                stop_loss: history.stop_loss ? Number(history.stop_loss) : 0,
                stopLossForTable: <ItemPrice product_id={history.product_id} value={history.stop_loss} />,
                take_profit: history.take_profit ? Number(history.take_profit) : 0,
                takeProfitForTable: <ItemPrice product_id={history.product_id} value={history.take_profit} />,
                total: history.total ? Number(history.total) : 0,
                totalForTable: history.total ? <ItemTotal product_id={history.product_id} price={history.total} /> : "",
                process_timestamp: history.result_timestamp ? history.result_timestamp : history.entry_timestamp,
                process_order_id: history.order_id ? history.order_id : "",
                process_entry_date: history.entry_date ? history.entry_date : "",
                process_result_date: history.result_date ? history.result_date : "",
                process_result_timestamp: history.result_timestamp ? history.result_timestamp : 0,
                future_type: history.future_type ? history.future_type : "",
                futureTypeForTable: history.future_type ? <ItemFutureType type={history.future_type} /> : "",
                profit: history.process_profit ? Number(history.process_profit) : 0,
                profitForTable: history.process_profit ? <ItemProfit item={history} /> : "",
            })
        })

        return newData;
    }, [historyDataDate, tabValue, currenciesData, productsData]);

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon
        let row = null;
        let rowIsValid = false;
        if(event.target.tagName == "TR"){
            rowIsValid = true;
            row = event.target;
        }
        else{
            rowIsValid = true;
            row = event.target.closest('tr');
        }
        
        if(row && rowIsValid){
            const expandButton = row.querySelector("td:first-child button");
            if(expandButton){
                expandButton.click();
            }
        }
    };

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [dataType, tableDataDate]);
    
    return (
        <div className="main">
            <div className="allsections allsections-border" ref={dateArea}>
                <div className="allsections-title allsections-title2"><i className="fa-solid fa-calendar-days"></i>Choose Date</div>
                {pageType == "customer" && <div className="allsections-title allsections-title2"><i className="fa-solid fa-user-large"></i><ProfileButton customer_id={customerId} /></div>}
                <div className="choose-date-area">
                    <div>
                        <label>Start Date:</label>
                        <input type="date" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.target.value)} />
                    </div>
                    <div>
                        <label>End Date:</label>
                        <input type="date" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.target.value)} />
                    </div>
                </div>
                <button type="button" className="choose-date-button" onClick={() => viewHistoryWithDate()}>View History</button>
            </div>
            {startDateInfo && endDateInfo && 
                <div className="allsections allsections-border table-area">
                    {dataType != "futurepositionhistory" && 
                        <div className="tabs-section">
                            <div className={`item ${tabValue == "approved" ? "active" : ""}`} onClick={() => setTabValue("approved")}>Approved</div>
                            <div className={`item ${tabValue == "denied" ? "active" : ""}`} onClick={() => setTabValue("denied")}>Denied</div>
                            <div className={`item ${tabValue == "processing" ? "active" : ""}`} onClick={() => setTabValue("processing")}>Processing</div>
                            <div className={`item ${tabValue == "waiting" ? "active" : ""}`} onClick={() => setTabValue("waiting")}>Waiting</div>
                            <div className={`item ${tabValue == "cancelled" ? "active" : ""}`} onClick={() => setTabValue("cancelled")}>Cancelled</div>
                        </div>
                    }
                    <div ref={tableArea} className={historyDataDate ? "list" : "list loading"}>
                        {historyDataDate ? <>
                            {(dataType == "spotorder" || dataType == "futureorder") && 
                                <div className={tabValue == "processing" || tabValue == "waiting" ? (pageType == "customer" ? (dataType == "spotorder" ? "column7" : "column8") : (dataType == "spotorder" ? "column8" : "column9")) : (pageType == "customer" ? (dataType == "spotorder" ? "column6" : "column7") : (dataType == "spotorder" ? "column7" : "column8"))}>
                                    <div className="spot-currency-info"><i className="fa-solid fa-calendar-day"></i>{`${startDateInfo} - ${endDateInfo}`}</div>
                                    <MaterialReactTable columns={tabValue == "processing" || tabValue == "waiting" ? (pageType == "customer" ? (dataType == "spotorder" ? tableColumnsDateCustomerSpot1 : tableColumnsDateCustomerFuture1) : (dataType == "spotorder" ? tableColumnsDateAllSpot1 : tableColumnsDateAllFuture1)) : (pageType == "customer" ? (dataType == "spotorder" ? tableColumnsDateCustomerSpot2 : tableColumnsDateCustomerFuture2) : (dataType == "spotorder" ? tableColumnsDateAllSpot2 : tableColumnsDateAllFuture2))} data={tableDataDate} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                                </div>
                            }
                            {dataType == "futurepositionhistory" && 
                                <div className="column4 withExpandMenu">
                                    <div className="spot-currency-info"><i className="fa-solid fa-calendar-day"></i>{`${startDateInfo} - ${endDateInfo}`}</div>
                                    <MaterialReactTable columns={tableColumnsDateFutureProcessHistory} data={tableDataDate} initialState={{sorting: [{id: 'process_result_timestamp', desc: true}]}}
                                        renderDetailPanel={({ row }) => (
                                            <div className="submenu-values process">
                                                <div className="submenu-process-values-left" data-orderid={row.original.process_order_id}>
                                                    <div>
                                                        <span>Entry Date:</span>
                                                        <label>{row.original.process_entry_date}</label>
                                                    </div>
                                                    <div>
                                                        <span>Close Date:</span>
                                                        <label>{row.original.process_result_date}</label>
                                                    </div>
                                                    <div>
                                                        <span>Leverage:</span>
                                                        <label>{row.original.leverage}</label>
                                                    </div>
                                                    <div className="take_profit">
                                                        <span>Take Profit:</span>
                                                        <label>{row.original.takeProfitForTable}</label>
                                                    </div>
                                                    <div className="stop_loss">
                                                        <span>Stop Loss:</span>
                                                        <label>{row.original.stopLossForTable}</label>
                                                    </div>
                                                </div>
                                                <div className="submenu-process-values-right">
                                                    <FutureProcessEvents orderId={row.original.process_order_id} customerId={row.original.customer_id} />
                                                </div>
                                            </div>
                                        )}
                                        />
                                </div>
                            }
                        </> : <Loading />}
                    </div>
                </div>
            }
        </div>
    );
    
}

export default SpotFutureHistoryDetails;