import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import axios from "axios";
import moment from "moment-timezone";
import "./Styles/page_profile.css";
import ReactCountryFlag from "react-country-flag";
import VerificationSteps from "./VerificationSteps";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Tooltip from '@mui/material/Tooltip';

const Profile = () => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customerVerificationInfo, setCustomerVerificationInfo] = useState(null);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [confirmDialogIsAccept, setConfirmDialogIsAccept] = useState(false);
    const [setverificationParameters, setSetVerificationParameters] = useState(null);
    const [phoneNumberForDenied, setPhoneNumberForDenied] = useState(null);
    const [imageInfo, setImageInfo] = useState(null);
    const [imageDialogHeader, setImageDialogHeader] = useState("");

    useEffect(() => {//customerId gelirse
        if(customerId){
            getCustomerInfo();
        }
        else{
            navigate(`/profile`);
        }
    }, [customerId]);

    useEffect(() => {
        if(customerInfo && customerInfo != "User not found" && customerVerificationInfo == null){
            getCustomerVerificationInfo();
        }
    }, [customerInfo]);

    useEffect(() => {
        if(confirmDialogIsAccept && setverificationParameters){
            setVerification(setverificationParameters[0], setverificationParameters[1], setverificationParameters[2]);
            setDisplayConfirmDialog(false);
            setSetVerificationParameters(null);
            setConfirmDialogIsAccept(false);
        }
    }, [confirmDialogIsAccept]);

    useEffect(() => {
        if(!displayConfirmDialog){
            setSetVerificationParameters(null);
            setConfirmDialogIsAccept(false);
        }
    }, [displayConfirmDialog]);

    useEffect(() => {
        if(setverificationParameters){
            setDisplayConfirmDialog(true);
        }
    }, [setverificationParameters]);

    useEffect(() => {
        if(imageDialogHeader != ""){
            setDisplayImageDialog(true);
        }
        else{
            setImageInfo(null);
        }
    }, [imageDialogHeader]);

    const getCustomerInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer?customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.customer && response.data.customer[0]){
                    let newData = response.data.customer[0];
                    newData.status_level = Number(newData.status_level);
                    newData.registration_time = Number(newData.registration_time);
                    newData.registration_date = moment(newData.registration_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    setCustomerInfo(response.data.customer[0]);
                }
                else{
                    setCustomerInfo("User not found");
                }
            }
            else{
                setCustomerInfo("User not found");
            }
        }
        catch (error) {
            setCustomerInfo("User not found");
            if(!(error && error.response && error.response.data && error.response.data.error && error.response.data.error == "Invalid User")){
                toast.error("There was a problem loading customer information!");
            }
        }
    }

    const getCustomerVerificationInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer/verification?customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200" && response.data){
                const newData = response.data;
                newData.customer.status_level = Number(newData.customer.status_level);
                setCustomerVerificationInfo(newData);
            }
            else{
                toast.error("There was a problem loading the customer's verification information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the customer's verification information!");
        }
    }

    const setVerification = async (type, newStatus, id) => {
        setCustomerInfo(null);
        setCustomerVerificationInfo(null);
        let updateData = {};

        if(type == "info"){
            updateData = {
                customer_id: customerId,
                update_type: `${type}_verification`,
                verification_result: newStatus,
                log_id: id
            }
        }
        else if(type == "phone" && phoneNumberForDenied){
            updateData = {
                customer_id: customerId,
                update_type: `${type}_verification`,
                verification_result: newStatus,
                log_id: id,
                phone_number: phoneNumberForDenied
            }
        }
        else{
            updateData = {
                customer_id: customerId,
                update_type: `${type}_verification`,
                verification_result: newStatus,
                file_id: id
            }
        }

        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        axios.post('https://operationapi.kpriceapp.com/customer/verification', 
            JSON.stringify(updateData),
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
        )
        .then(function (response) {
            if(response.status == "200"){
                if(type == "info" || type == "phone" || type == "identity1" || type == "identity2" || type == "customer_image" || type == "residence"){
                    let messageTitle = "";
                    if(type == "info"){
                        messageTitle = "Info";
                    }
                    else if(type == "phone"){
                        messageTitle = "Phone";
                    }
                    else if(type == "identity1"){
                        messageTitle = "Identity 1";
                    }
                    else if(type == "identity2"){
                        messageTitle = "Identity 2";
                    }
                    else if(type == "customer_image"){
                        messageTitle = "Customer Image";
                    }
                    else if(type == "residence"){
                        messageTitle = "Residence";
                    }
                    toast.success(`${messageTitle} verification successfully ${newStatus}!`);
                }
            }
            else{
                toast.error(`There was a problem during the verification process!${response.data.message ? ` (${response.data.message})` : ""}`);
            }
        })
        .catch(function (error) {
            toast.error(`There was a problem during the verification process!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
        })
        .finally(function () {
            setTimeout(() => {
                getCustomerInfo();
                setPhoneNumberForDenied(null);
            }, 1000);
        });

    }

    const getImage = async (fileId) => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/image?file_id=${fileId}&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data){
                    setImageInfo(response.data);
                }
            }
            else{
                toast.error("There was a problem loading the image!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the image!");
        }
    }

    const SuccessIcon = () => {
        return <div className="status-icon success"><i className="fa-solid fa-circle-check"></i></div>;
    }

    const WarningIcon = () => {
        return <div className="status-icon warning" title="Customer needs to complete verification steps"><i className="fa-solid fa-circle-exclamation"></i></div>;
    }

    const DangerIcon = () => {
        return <div className="status-icon danger"><i className="fa-solid fa-circle-xmark"></i></div>;
    }

    const SuccessIconArea = () => {
        return <div className="title-icon approved"><i className="fa-solid fa-circle-check"></i>Approved</div>;
    }

    const PendingIconArea = () => {
        return <div className="title-icon pending"><i className="fa-solid fa-clock"></i>Pending</div>;
    }

    const DeniedIconArea = () => {
        return <div className="title-icon denied"><i className="fa-solid fa-circle-xmark"></i>Denied</div>;
    }
    
    return(
        <div id="main" className="main">
            <Dialog header={imageDialogHeader} visible={displayImageDialog} draggable={false} resizable={false} className="info-dialog" onHide={() => {setDisplayImageDialog(false);setImageDialogHeader("");}}>
                {imageInfo ? <div className="image-area">
                    <img src={`data:image/jpeg;base64,${imageInfo.image_base64}`} />
                </div> : <Loading />}
            </Dialog>
            <ConfirmDialog 
                visible={displayConfirmDialog}
                draggable={false}
                resizable={false}
                onHide={() => setDisplayConfirmDialog(false)}
                message={setverificationParameters && setverificationParameters[1] ? (setverificationParameters[1] == "denied" ? "Are you sure you want to reject?" : "Are you sure you want to accept?") : ""}
                header={setverificationParameters && setverificationParameters[1] ? (setverificationParameters[1] == "denied" ? "Reject" : "Accept") : ""}
                icon={setverificationParameters && setverificationParameters[1] && setverificationParameters[1] == "approved" ? "pi pi-check-circle" : "pi pi-info-circle"}
                accept={() => setConfirmDialogIsAccept(true)}
                reject={() => setDisplayConfirmDialog(false)}
                className={setverificationParameters && setverificationParameters[1] ? (setverificationParameters[1] == "denied" ? "danger" : "success") : undefined}
            />
            {customerId && customerInfo != "User not found" ? 
                <>
                    <div className="verification-body">
                        <div className="verification-body-top">
                            <div className="profile-informations allsections allsections-border">
                                {customerInfo == null ? 
                                    <Loading />
                                    :
                                    <>
                                        <div className="item">
                                            <label>Id:</label>
                                            <span>
                                                {customerInfo && customerInfo.customer_id ? 
                                                <>
                                                    {customerInfo.customer_id}
                                                    <Tooltip title="Profile" className="detail-button" placement="right">
                                                        <a href={`/profile?customer_id=${customerInfo.customer_id}`} target="_blank">
                                                            <i className="fa-solid fa-user"></i>
                                                        </a>
                                                    </Tooltip>
                                                </> : ""}
                                            </span>
                                        </div>
                                        <div className="item">
                                            <label>Level:</label>
                                            <span>{customerInfo && customerInfo.status_level ? customerInfo.status_level : "1"}</span>
                                        </div>
                                        <div className="item">
                                            <label>Full Name:</label>
                                            <span>{customerInfo && customerInfo.full_name ? customerInfo.full_name : ""}</span>
                                        </div>
                                        <div className="item">
                                            <label>Email:</label>
                                            <span>{customerInfo && customerInfo.email ? customerInfo.email : ""}</span>
                                        </div>
                                        <div className="item">
                                            <label>Phone:</label>
                                            <span>{customerInfo && customerInfo.phone_verification && customerInfo.phone_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}{customerInfo && customerInfo.phone_number ? customerInfo.phone_number : ""}</span>
                                        </div>
                                        <div className="item">
                                            <label>Nationality:</label>
                                            <span>{customerInfo && customerInfo.nationality ? <ReactCountryFlag countryCode={customerInfo.nationality} svg title={customerInfo.nationality} /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item br">
                                            <label>Spot Transaction Permission:</label>
                                            <span>{customerInfo && customerInfo.spot_permission && customerInfo.spot_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Future Transaction Permission:</label>
                                            <span>{customerInfo && customerInfo.future_permission && customerInfo.future_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item br">
                                            <label>Deposit:</label>
                                            <span>{customerInfo && customerInfo.deposit_permission && customerInfo.deposit_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Withdraw:</label>
                                            <span>{customerInfo && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item br">
                                            <label>Account Status:</label>
                                            <span>{customerInfo && customerInfo.status && customerInfo.status == "active" ? <SuccessIcon /> : <DangerIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Pending Operation:</label>
                                            <span>{customerInfo && customerInfo.pending_operation ? customerInfo.pending_operation == "True" : ""}</span>
                                        </div>
                                        <div className="item br">
                                            <label>Info Verification:</label>
                                            <span>{customerInfo && customerInfo.info_verification && customerInfo.info_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Identity Type:</label>
                                            <span>{customerInfo && customerInfo.identity_type ? customerInfo.identity_type : ""}</span>
                                        </div>
                                        <div className="item">
                                            <label>Front ID Image Verification:</label>
                                            <span>{customerInfo && customerInfo.identity1_verification && customerInfo.identity1_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Back ID Image Verification:</label>
                                            <span>{customerInfo && customerInfo.identity2_verification && customerInfo.identity2_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>User Photo Verification:</label>
                                            <span>{customerInfo && customerInfo.customer_image_verification && customerInfo.customer_image_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                        <div className="item">
                                            <label>Residence Image Verification:</label>
                                            <span>{customerInfo && customerInfo.residence_verification && customerInfo.residence_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="verification-informations allsections allsections-border">
                                {customerInfo == null || customerVerificationInfo == null ? 
                                    <Loading />
                                    : 
                                    <>
                                        <div className="verification-informations-left">
                                            <div className={`verification1 step-area ${customerVerificationInfo.customer.status_level >= 1 ? "approved" : "denied"}`}>
                                                <div className="step-area-title">
                                                    <div>1</div>
                                                    <label>Email Verification</label>
                                                </div>
                                                <div className="step-area-info">
                                                    {customerVerificationInfo.customer.status_level >= 1 && <>{customerVerificationInfo.customer.email}<SuccessIconArea /></>}
                                                    {customerVerificationInfo.customer.status_level <= 0 && <>Registration failed<DeniedIconArea /></>}
                                                </div>
                                            </div>
                                            <div className={`verification2 step-area ${customerVerificationInfo.customer.info_verification == "approved" && customerVerificationInfo.customer.phone_verification == "approved" ? "approved" : (customerVerificationInfo.customer.info_verification == "denied" || customerVerificationInfo.customer.phone_verification == "denied" ? "denied" : (customerVerificationInfo.customer.info_verification == "pending" || customerVerificationInfo.customer.phone_verification == "pending" ? "pending" : ""))}`}>
                                                <div className="step-area-title">
                                                    <div>2</div>
                                                    <label>Informations Verification</label>
                                                </div>
                                                <div className="step-area-inner info">
                                                    <div className={`${customerVerificationInfo.customer.info_verification == "approved" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.info_verification == "approved" ? "approved" : (customerVerificationInfo.customer.info_verification == "denied" ? "denied" : "")}`}>
                                                        <div className="step-area-inner-title">
                                                            Info Verification
                                                            {customerVerificationInfo.customer.info_verification == "approved" && <SuccessIconArea />}
                                                            {customerVerificationInfo.customer.info_verification == "pending" && <PendingIconArea />}
                                                            {customerVerificationInfo.customer.info_verification == "denied" && <DeniedIconArea />}
                                                        </div>
                                                        {customerVerificationInfo.customer.info_verification == "approved" && customerVerificationInfo.info_verification_details ?
                                                        <>
                                                            <label>Residential Country</label>
                                                            <span>{customerVerificationInfo.info_verification_details.residential_country}</span>
                                                            <label>Nationality</label>
                                                            <span>{customerVerificationInfo.info_verification_details.nationality}</span>
                                                            <label>Full Name</label>
                                                            <span>{customerVerificationInfo.info_verification_details.full_name}</span>
                                                            <label>Date of Birth</label>
                                                            <span>{customerVerificationInfo.info_verification_details.date_of_birth}</span>
                                                            <label>Residential Address</label>
                                                            <span>{customerVerificationInfo.info_verification_details.residential_address}</span>
                                                            <label>Postal Code</label>
                                                            <span>{customerVerificationInfo.info_verification_details.postal_code}</span>
                                                            <label>City</label>
                                                            <span>{customerVerificationInfo.info_verification_details.city}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>
                                                                Waiting for information
                                                            </span>
                                                        </>
                                                        }
                                                        
                                                    </div>
                                                    {customerVerificationInfo.customer.info_verification == "approved" && 
                                                        <div className="step-area-inner-buttons">
                                                            <button type="button" className="denied" onClick={() => setSetVerificationParameters(["info", "denied", customerVerificationInfo.info_verification_details.log_id ? customerVerificationInfo.info_verification_details.log_id : null])}>Reject</button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="step-area-inner phone">
                                                    <div className={`${customerVerificationInfo.customer.phone_verification == "approved" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.phone_verification == "approved" ? "approved" : (customerVerificationInfo.customer.phone_verification == "denied" ? "denied" : "")}`}>
                                                        <div className="step-area-inner-title">
                                                            Phone Verification
                                                            {customerVerificationInfo.customer.phone_verification == "approved" && <SuccessIconArea />}
                                                            {customerVerificationInfo.customer.phone_verification == "pending" && <PendingIconArea />}
                                                            {customerVerificationInfo.customer.phone_verification == "denied" && <DeniedIconArea />}
                                                        </div>
                                                        {customerVerificationInfo.customer.phone_verification == "approved" ?
                                                        <>
                                                            <span>{customerVerificationInfo && customerVerificationInfo.phone_verification_details && customerVerificationInfo.phone_verification_details.phone_number ? customerVerificationInfo.phone_verification_details.phone_number : ""}</span>
                                                            <span>{customerVerificationInfo && customerVerificationInfo.phone_verification_details && customerVerificationInfo.phone_verification_details.event_timestamp ? moment(Number(customerVerificationInfo.phone_verification_details.event_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm") : ""}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>
                                                                Waiting for information
                                                            </span>
                                                        </>
                                                        }
                                                        
                                                    </div>
                                                    {customerVerificationInfo.customer.phone_verification == "approved" && 
                                                        <div className="step-area-inner-buttons">
                                                            <button type="button" className="denied" onClick={() => {setSetVerificationParameters(["phone", "denied", customerVerificationInfo.phone_verification_details.log_id ? customerVerificationInfo.phone_verification_details.log_id : null]);setPhoneNumberForDenied(customerVerificationInfo && customerVerificationInfo.phone_verification_details && customerVerificationInfo.phone_verification_details.phone_number ? customerVerificationInfo.phone_verification_details.phone_number : "");}}>Reject</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="verification-informations-right">
                                            <div className={`verification3 step-area ${customerVerificationInfo.customer.identity1_verification == "approved" && customerVerificationInfo.customer.identity2_verification == "approved" && customerVerificationInfo.customer.customer_image_verification == "approved" && customerVerificationInfo.customer.residence_verification == "approved" ? "approved" : (customerVerificationInfo.customer.identity1_verification == "denied" || customerVerificationInfo.customer.identity2_verification == "denied" || customerVerificationInfo.customer.customer_image_verification == "denied" || customerVerificationInfo.customer.residence_verification == "denied" ? "denied" : (customerVerificationInfo.customer.identity1_verification == "pending" || customerVerificationInfo.customer.identity2_verification == "pending" || customerVerificationInfo.customer.customer_image_verification == "pending" || customerVerificationInfo.customer.residence_verification == "pending" ? "pending" : ""))}`}>
                                                <div className="step-area-title">
                                                    <div>3</div>
                                                    <label>Images Verification</label>
                                                </div>
                                                <div className="step-area-inner step-area-inner-content no-buttons">
                                                    <label>Identity Type</label>
                                                    <span>{customerVerificationInfo && customerVerificationInfo.customer && customerVerificationInfo.customer.identity_type ? customerVerificationInfo.customer.identity_type : "Waiting for information"}</span>
                                                    <div className="step-area-inner image1">
                                                        <div className={`${customerVerificationInfo.customer.identity1_verification == "approved" || customerVerificationInfo.customer.identity1_verification == "pending" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.identity1_verification == "approved" ? "approved" : (customerVerificationInfo.customer.identity1_verification == "denied" ? "denied" : (customerVerificationInfo.customer.identity1_verification == "pending" ? "pending" : ""))}`}>
                                                            <div className="step-area-inner-title">
                                                                Identity 1 Verification
                                                                {customerVerificationInfo.customer.identity1_verification == "approved" && <SuccessIconArea />}
                                                                {customerVerificationInfo.customer.identity1_verification == "pending" && <PendingIconArea />}
                                                                {customerVerificationInfo.customer.identity1_verification == "denied" && <DeniedIconArea />}
                                                            </div>
                                                            {customerVerificationInfo.customer.identity1_verification == "approved" || customerVerificationInfo.customer.identity1_verification == "pending" ?
                                                            <>
                                                                <button type="button" className="show-image" onClick={() => {getImage(customerVerificationInfo.identity1_verification_details && customerVerificationInfo.identity1_verification_details.file_id ? customerVerificationInfo.identity1_verification_details.file_id : null);setImageDialogHeader("Front ID Image");}}><i className="fa-solid fa-file-image"></i>Show Front ID Image</button>
                                                            </>
                                                            :
                                                            <>
                                                                <span>
                                                                    {customerVerificationInfo.customer.identity1_verification == "denied" ? "Image denied, waiting for image to be reloaded" : "Waiting for information"}
                                                                </span>
                                                            </>
                                                            }
                                                            
                                                        </div>
                                                        {(customerVerificationInfo.customer.identity1_verification == "approved" || customerVerificationInfo.customer.identity1_verification == "pending") && 
                                                            <div className="step-area-inner-buttons">
                                                                {customerVerificationInfo.customer.identity1_verification == "pending" && 
                                                                    <button type="button" className="approved" onClick={() => setSetVerificationParameters(["identity1", "approved", customerVerificationInfo.identity1_verification_details && customerVerificationInfo.identity1_verification_details.file_id ? customerVerificationInfo.identity1_verification_details.file_id : null])}>Accept</button>
                                                                }
                                                                {(customerVerificationInfo.customer.identity1_verification == "approved" || customerVerificationInfo.customer.identity1_verification == "pending") && 
                                                                    <button type="button" className="denied" onClick={() => setSetVerificationParameters(["identity1", "denied", customerVerificationInfo.identity1_verification_details && customerVerificationInfo.identity1_verification_details.file_id ? customerVerificationInfo.identity1_verification_details.file_id : null])}>Reject</button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="step-area-inner image1">
                                                        <div className={`${customerVerificationInfo.customer.identity2_verification == "approved" || customerVerificationInfo.customer.identity2_verification == "pending" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.identity2_verification == "approved" ? "approved" : (customerVerificationInfo.customer.identity2_verification == "denied" ? "denied" : (customerVerificationInfo.customer.identity2_verification == "pending" ? "pending" : ""))}`}>
                                                            <div className="step-area-inner-title">
                                                                Identity 2 Verification
                                                                {customerVerificationInfo.customer.identity2_verification == "approved" && <SuccessIconArea />}
                                                                {customerVerificationInfo.customer.identity2_verification == "pending" && <PendingIconArea />}
                                                                {customerVerificationInfo.customer.identity2_verification == "denied" && <DeniedIconArea />}
                                                            </div>
                                                            {customerVerificationInfo.customer.identity2_verification == "approved" || customerVerificationInfo.customer.identity2_verification == "pending" ?
                                                            <>
                                                                <button type="button" className="show-image" onClick={() => {getImage(customerVerificationInfo.identity2_verification_details && customerVerificationInfo.identity2_verification_details.file_id ? customerVerificationInfo.identity2_verification_details.file_id : null);setImageDialogHeader("Back ID Image");}}><i className="fa-solid fa-file-image"></i>Show Back ID Image</button>
                                                            </>
                                                            :
                                                            <>
                                                                <span>
                                                                    {customerVerificationInfo.customer.identity2_verification == "denied" ? "Image denied, waiting for image to be reloaded" : "Waiting for information"}
                                                                </span>
                                                            </>
                                                            }
                                                            
                                                        </div>
                                                        {(customerVerificationInfo.customer.identity2_verification == "approved" || customerVerificationInfo.customer.identity2_verification == "pending") && 
                                                            <div className="step-area-inner-buttons">
                                                                {customerVerificationInfo.customer.identity2_verification == "pending" && 
                                                                    <button type="button" className="approved" onClick={() => setSetVerificationParameters(["identity2", "approved", customerVerificationInfo.identity2_verification_details && customerVerificationInfo.identity2_verification_details.file_id ? customerVerificationInfo.identity2_verification_details.file_id : null])}>Accept</button>
                                                                }
                                                                {(customerVerificationInfo.customer.identity2_verification == "approved" || customerVerificationInfo.customer.identity2_verification == "pending") && 
                                                                    <button type="button" className="denied" onClick={() => setSetVerificationParameters(["identity2", "denied", customerVerificationInfo.identity2_verification_details && customerVerificationInfo.identity2_verification_details.file_id ? customerVerificationInfo.identity2_verification_details.file_id : null])}>Reject</button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="step-area-inner image1">
                                                    <div className={`${customerVerificationInfo.customer.customer_image_verification == "approved" || customerVerificationInfo.customer.customer_image_verification == "pending" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.customer_image_verification == "approved" ? "approved" : (customerVerificationInfo.customer.customer_image_verification == "denied" ? "denied" : (customerVerificationInfo.customer.customer_image_verification == "pending" ? "pending" : ""))}`}>
                                                        <div className="step-area-inner-title">
                                                            Customer Image Verification
                                                            {customerVerificationInfo.customer.customer_image_verification == "approved" && <SuccessIconArea />}
                                                            {customerVerificationInfo.customer.customer_image_verification == "pending" && <PendingIconArea />}
                                                            {customerVerificationInfo.customer.customer_image_verification == "denied" && <DeniedIconArea />}
                                                        </div>
                                                        {customerVerificationInfo.customer.customer_image_verification == "approved" || customerVerificationInfo.customer.customer_image_verification == "pending" ?
                                                        <>
                                                            <button type="button" className="show-image" onClick={() => {getImage(customerVerificationInfo.customer_image_verification_details && customerVerificationInfo.customer_image_verification_details.file_id ? customerVerificationInfo.customer_image_verification_details.file_id : null);setImageDialogHeader("Customer Image");}}><i className="fa-solid fa-file-image"></i>Show Customer Image</button>
                                                        </>
                                                        :
                                                        <>
                                                            <span>
                                                                {customerVerificationInfo.customer.customer_image_verification == "denied" ? "Image denied, waiting for image to be reloaded" : "Waiting for information"}
                                                            </span>
                                                        </>
                                                        }
                                                        
                                                    </div>
                                                    {(customerVerificationInfo.customer.customer_image_verification == "approved" || customerVerificationInfo.customer.customer_image_verification == "pending") && 
                                                        <div className="step-area-inner-buttons">
                                                            {customerVerificationInfo.customer.customer_image_verification == "pending" && 
                                                                <button type="button" className="approved" onClick={() => setSetVerificationParameters(["customer_image", "approved", customerVerificationInfo.customer_image_verification_details && customerVerificationInfo.customer_image_verification_details.file_id ? customerVerificationInfo.customer_image_verification_details.file_id : null])}>Accept</button>
                                                            }
                                                            {(customerVerificationInfo.customer.customer_image_verification == "approved" || customerVerificationInfo.customer.customer_image_verification == "pending") && 
                                                                <button type="button" className="denied" onClick={() => setSetVerificationParameters(["customer_image", "denied", customerVerificationInfo.customer_image_verification_details && customerVerificationInfo.customer_image_verification_details.file_id ? customerVerificationInfo.customer_image_verification_details.file_id : null])}>Reject</button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="step-area-inner image1">
                                                    <div className={`${customerVerificationInfo.customer.residence_verification == "approved" || customerVerificationInfo.customer.residence_verification == "pending" ? "step-area-inner-content" : "step-area-inner-content no-buttons"} ${customerVerificationInfo.customer.residence_verification == "approved" ? "approved" : (customerVerificationInfo.customer.residence_verification == "denied" ? "denied" : (customerVerificationInfo.customer.residence_verification == "pending" ? "pending" : ""))}`}>
                                                        <div className="step-area-inner-title">
                                                            Residence Verification
                                                            {customerVerificationInfo.customer.residence_verification == "approved" && <SuccessIconArea />}
                                                            {customerVerificationInfo.customer.residence_verification == "pending" && <PendingIconArea />}
                                                            {customerVerificationInfo.customer.residence_verification == "denied" && <DeniedIconArea />}
                                                        </div>
                                                        {customerVerificationInfo.customer.residence_verification == "approved" || customerVerificationInfo.customer.residence_verification == "pending" ?
                                                        <>
                                                            <button type="button" className="show-image" onClick={() => {getImage(customerVerificationInfo.residence_verification_details && customerVerificationInfo.residence_verification_details.file_id ? customerVerificationInfo.residence_verification_details.file_id : null);setImageDialogHeader("Residence Image");}}><i className="fa-solid fa-file-image"></i>Show Residence Image</button>
                                                        </>
                                                        :
                                                        <>
                                                            <span>
                                                                {customerVerificationInfo.customer.residence_verification == "denied" ? "Image denied, waiting for image to be reloaded" : "Waiting for information"}
                                                            </span>
                                                        </>
                                                        }
                                                        
                                                    </div>
                                                    {(customerVerificationInfo.customer.residence_verification == "approved" || customerVerificationInfo.customer.residence_verification == "pending") && 
                                                        <div className="step-area-inner-buttons">
                                                            {customerVerificationInfo.customer.residence_verification == "pending" && 
                                                                <button type="button" className="approved" onClick={() => setSetVerificationParameters(["residence", "approved", customerVerificationInfo.residence_verification_details && customerVerificationInfo.residence_verification_details.file_id ? customerVerificationInfo.residence_verification_details.file_id : null])}>Accept</button>
                                                            }
                                                            {(customerVerificationInfo.customer.residence_verification == "approved" || customerVerificationInfo.customer.residence_verification == "pending") && 
                                                                <button type="button" className="denied" onClick={() => setSetVerificationParameters(["residence", "denied", customerVerificationInfo.residence_verification_details && customerVerificationInfo.residence_verification_details.file_id ? customerVerificationInfo.residence_verification_details.file_id : null])}>Reject</button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="verification-body-bottom">
                            <div className="verification-steps allsections allsections-border">
                                <div className="verification-steps-title">User Screen</div>
                                <div className="section-verification">
                                    <VerificationSteps pageType="readonly" userInfo={customerInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            : 
                <div className="allsections allsections-border profile-no-user">
                    <i className="fa-solid fa-user-slash"></i>
                    <div>User not found</div>
                </div>
            }
        </div>
    )

}

export default Profile;