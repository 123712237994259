import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment-timezone";
import { formatValuePriceControl, formatValue3, formatValue4 } from '../Lib/calculationFunctions';

const CreateOrderHistory = ({ fromPage, dataType, dataCurrency, dataCustomerId }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);

    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const tableArea = useRef(null);//tablo alanı

    const [tabValue, setTabValue] = useState("approved");
    const [historyDataScroll, setHistoryDataScroll] = useState(null);
    const [historyDataScrollLastEvaluatedKey, setHistoryDataScrollLastEvaluatedKey] = useState(null);
    const [getNewHistory, setGetNewHistory] = useState(false);
    const itemsScrollPosition = useRef(0);
    const historyElements = useRef(null);

    useEffect(() => {//link değiştikçe animasyon tetikleniyor ve açılıştaki veriler düzenleniyor
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }

        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
        }
    }, [location, tabValue]);

    useEffect(() => {//açılıştaki veriler düzenleniyor
        if((customerId && tabValue) || (fromPage == "history" && tabValue) || (fromPage == "spot-all" || fromPage == "spot-currency")){
            setHistoryDataScroll(null);
            let linkInfo = "";
            if(fromPage == "profile" || fromPage == "history"){
                linkInfo = `&state=${tabValue}`;
            }
            else if(fromPage == "spot-all" && dataType && dataCurrency){
                linkInfo = `&type=${dataType}&currency=${dataCurrency}`;
            }
            else if(fromPage == "spot-currency" && dataType && dataCurrency && dataCustomerId){
                linkInfo = `&customer_id=${dataCustomerId}&type=${dataType}&currency=${dataCurrency}`;
            }
            getHistoryInfo(linkInfo, "initial");
        }
    }, [customerId, tabValue, fromPage, dataType, dataCurrency]);

    useEffect(() => {
        if(historyElements && historyElements.current){
            if(getNewHistory){
                historyElements.current.scrollTop = historyElements.current.scrollHeight - historyElements.current.clientHeight;
            }
            else{
                historyElements.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [getNewHistory]);

    useEffect(() => {
        setGetNewHistory(false);
    }, [historyDataScroll]);

    const getHistoryInfo = async (linkInfo, getType) => {
        if(linkInfo != ""){
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            let getLink = "";

            if(fromPage == "profile"){
                getLink = `https://operationapi.kpriceapp.com/customer/${dataType == "spot" ? "spotorder" : "futureorder"}?customer_id=${customerId}&type=general${linkInfo}`;
            }
            else if(fromPage == "history"){
                getLink = `https://operationapi.kpriceapp.com/${dataType == "spot" ? "spotorder" : "futureorder"}?type=general${linkInfo}`;
            }
            else if(fromPage == "spot-all"){
                getLink = `https://operationapi.kpriceapp.com/spotorder/?state=approved${linkInfo}`;
            }
            else if(fromPage == "spot-currency"){
                getLink = `https://operationapi.kpriceapp.com/customer/spotorder/?state=approved${linkInfo}`;
            }
    
            try {
                const response = await axios.get(getLink, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                
                if(response.status == "200"){
                    let currentData = response.data.history_items;
                    currentData.forEach(item => {
                        if(item.entry_timestamp){
                            item.entry_timestamp = Number(item.entry_timestamp);
                            item.entry_date = moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                        if(item.result_timestamp){
                            item.result_timestamp = Number(item.result_timestamp);
                            item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                    });

                    if(getType == "initial"){
                        setHistoryDataScroll(currentData);
                    }
                    else if(getType == "additional"){
                        setHistoryDataScroll((prevItems) => (Array.isArray(prevItems) ? [...prevItems, ...currentData] : [...currentData]));
                    }
                    setHistoryDataScrollLastEvaluatedKey(response.data.last_evaluated_key);
                }
                else{
                    toast.error("There was a problem loading the information!");
                }
            }
            catch (error) {
                toast.error("There was a problem loading the information!");
            }
        }
    }

    const historyElementsOnScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight + 2 >= scrollHeight && historyDataScrollLastEvaluatedKey && !getNewHistory) {//eğer divin en altına gelindiyse
            if(historyElements && historyElements.current){
                itemsScrollPosition.current = historyElements.current.scrollTop;
            }
            setGetNewHistory(true);

            let linkInfo = "";

            if(fromPage == "profile"){
                linkInfo = `&state=${tabValue}&order_id=${historyDataScrollLastEvaluatedKey.order_id ? historyDataScrollLastEvaluatedKey.order_id : null}`;
                if(tabValue == "processing" || tabValue == "waiting"){
                    linkInfo = `${linkInfo}&entry_timestamp=${historyDataScrollLastEvaluatedKey.entry_timestamp ? historyDataScrollLastEvaluatedKey.entry_timestamp : null}`
                }
                else{
                    linkInfo = `${linkInfo}&result_timestamp=${historyDataScrollLastEvaluatedKey.result_timestamp ? historyDataScrollLastEvaluatedKey.result_timestamp : null}`
                }
            }
            else if(fromPage == "history"){
                linkInfo = `&state=${tabValue}&order_id=${historyDataScrollLastEvaluatedKey.order_id ? historyDataScrollLastEvaluatedKey.order_id : null}&last_key_customer_id=${historyDataScrollLastEvaluatedKey.customer_id ? historyDataScrollLastEvaluatedKey.customer_id : null}`;
                if(tabValue == "processing" || tabValue == "waiting"){
                    linkInfo = `${linkInfo}&entry_timestamp=${historyDataScrollLastEvaluatedKey.entry_timestamp ? historyDataScrollLastEvaluatedKey.entry_timestamp : null}`
                }
                else{
                    linkInfo = `${linkInfo}&result_timestamp=${historyDataScrollLastEvaluatedKey.result_timestamp ? historyDataScrollLastEvaluatedKey.result_timestamp : null}`
                }
            }
            else if(fromPage == "spot-all" && dataType && dataCurrency){
                linkInfo = `&type=${dataType}&currency=${dataCurrency}&order_id=${historyDataScrollLastEvaluatedKey.order_id ? historyDataScrollLastEvaluatedKey.order_id : null}&last_key_customer_id=${historyDataScrollLastEvaluatedKey.customer_id ? historyDataScrollLastEvaluatedKey.customer_id : null}&result_timestamp=${historyDataScrollLastEvaluatedKey.result_timestamp ? historyDataScrollLastEvaluatedKey.result_timestamp : null}`;
            }
            else if(fromPage == "spot-currency" && dataType && dataCurrency && dataCustomerId){
                linkInfo = `&customer_id=${dataCustomerId}&type=${dataType}&currency=${dataCurrency}&order_id=${historyDataScrollLastEvaluatedKey.order_id ? historyDataScrollLastEvaluatedKey.order_id : null}&result_timestamp=${historyDataScrollLastEvaluatedKey.result_timestamp ? historyDataScrollLastEvaluatedKey.result_timestamp : null}`;
            }
            
            getHistoryInfo(linkInfo, "additional");
        }
    }

    const ItemMarket = ({ product_id }) => {
        const productCurrency = product_id.split("-")[0];
        if(currenciesData && currenciesData[productCurrency] && currenciesData[productCurrency].picture_url){
            return <div className="market"><img src={currenciesData[productCurrency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const ItemType = ({ type }) => {
        let typeText = "";
        if (type === "limitbuy") {
            typeText = "Limit Buy";
        } else if (type === "limitsell" || type === "stoploss" || type === "takeprofit") {
            typeText = "Limit Sell";
        } else if (type === "marketbuy") {
            typeText = "Market Buy";
        } else if (type === "marketsell") {
            typeText = "Market Sell";
        }

        let typeClass = "";
        if(typeText === "Limit Buy" || typeText === "Market Buy"){
            typeClass = "success";
        } else{
            typeClass = "danger";
        }

        if(typeText === "Limit Buy" || typeText === "Limit Sell"){
            return <span className={typeClass}>Limit</span>;
        } else{
            return <span className={typeClass}>Market</span>;
        }
    }

    const ItemPrice = ({ product_id, value }) => {
        const show_places = productsData && productsData[product_id] && productsData[product_id].max_order_asking_price_places ? productsData[product_id].max_order_asking_price_places : null;
        
        if(value == "" || value == null || value == undefined){
            return "";
        } else{
            const response = formatValuePriceControl(value, show_places);
            if(response){
                return formatValue3(value, show_places);
            }
            else{
                return formatValue4(value);
            }
        }
    }
    
    return (
        <>
            {(fromPage == "profile" || fromPage == "history") && 
                <div className="tabs-section">
                    <div className={`item ${tabValue == "approved" ? "active" : ""}`} onClick={() => setTabValue("approved")}>Approved</div>
                    <div className={`item ${tabValue == "denied" ? "active" : ""}`} onClick={() => setTabValue("denied")}>Denied</div>
                    <div className={`item ${tabValue == "processing" ? "active" : ""}`} onClick={() => setTabValue("processing")}>Processing</div>
                    <div className={`item ${tabValue == "waiting" ? "active" : ""}`} onClick={() => setTabValue("waiting")}>Waiting</div>
                    <div className={`item ${tabValue == "cancelled" ? "active" : ""}`} onClick={() => setTabValue("cancelled")}>Cancelled</div>
                </div>
            }
            <div className={`scrollable-section table-format ${tabValue == "processing" || tabValue == "waiting" ? (fromPage != "profile" ? (dataType == "future" ? "column9" : "column8") : (dataType == "future" ? "column8" : "column7")) : (fromPage != "profile" ? (dataType == "future" ? "column8" : "column7") : (dataType == "future" ? "column7" : "column6"))}`} ref={tableArea}>
                <div className="scrollable-section-titles">
                    {tabValue == "processing" || tabValue == "waiting" ? <>
                        <span>Date</span>
                        {fromPage != "profile" && <span>Customer ID</span>}
                        <span>Market</span>
                        <span>Type</span>
                        <span>Price</span>
                        <span>Count</span>
                        {dataType == "future" && <span>Leverage</span>}
                        <span>Stop Loss</span>
                        <span>Take Profit</span>
                    </> : <>
                        <span>Date</span>
                        {fromPage != "profile" && <span>Customer ID</span>}
                        <span>Market</span>
                        <span>Type</span>
                        <span>Price</span>
                        <span>Count</span>
                        {dataType == "future" && <span>Leverage</span>}
                        <span>Total</span>
                    </>}
                </div>
                <div className="scrollable-section-elements" ref={historyElements} onScroll={(e) => historyElementsOnScroll(e)}>
                    {historyDataScroll ? <>
                        {historyDataScroll.length > 0 ? 
                        <>
                            {historyDataScroll.map((history, index) => (
                                <div className="element" key={index}>
                                    <div className="item">
                                        {(tabValue == "processing" || tabValue == "waiting") ? 
                                            <span>{history.entry_date ? history.entry_date : ""}</span> 
                                            : 
                                            <span>{history.result_date ? history.result_date : ""}</span>
                                        }
                                    </div>
                                    {fromPage != "profile" && 
                                        <div className="item">
                                            <span>{history.customer_id ? 
                                            <>
                                                <div className="td-text-with-button customer-id">{history.customer_id}</div>
                                                <Tooltip title="Profile" className="detail-button" placement="right">
                                                    <a href={`/profile?customer_id=${history.customer_id}`} target="_blank">
                                                        <i className="fa-solid fa-user"></i>
                                                    </a>
                                                </Tooltip>
                                            </> 
                                            : ""}</span>
                                        </div>
                                    }
                                    <div className="item">
                                        <span>{history.product_id ? <ItemMarket product_id={history.product_id} /> : ""}</span>
                                    </div>
                                    <div className="item">
                                        <span>{history.type ? <ItemType type={history.type} /> : ""}</span>
                                    </div>
                                    <div className="item">
                                        {tabValue == "approved" ? 
                                            <span>{history.processing_price ? (history.product_id ? <ItemPrice product_id={history.product_id} value={history.processing_price} /> : history.asking_price) : ""}</span>
                                            :
                                            <span>{history.asking_price ? (history.product_id ? <ItemPrice product_id={history.product_id} value={history.asking_price} /> : history.asking_price) : ""}</span>
                                        }
                                    </div>
                                    <div className="item">
                                        {tabValue == "approved" ? 
                                            <span>{history.realized_count && history.count ? `${formatValue4(history.realized_count)}/${formatValue4(history.count)}` : ""}</span>
                                            :
                                            <span>{history.count ? formatValue4(history.count) : ""}</span>
                                        }
                                    </div>
                                    {dataType == "future" && 
                                        <div className="item">
                                            <span>{history.leverage ? history.leverage : ""}</span> 
                                        </div>
                                    }
                                    {(tabValue == "approved" || tabValue == "denied" || tabValue == "cancelled") && 
                                        <div className="item">
                                            <span>{tabValue == "approved" && history.total ? (history.product_id ? 
                                            <div className="total-value">
                                                ≈ {formatValue3(history.total, currenciesData && currenciesData[history.product_id.split("-")[1]] && currenciesData[history.product_id.split("-")[1]].show_places ? currenciesData[history.product_id.split("-")[1]].show_places : null)}
                                                <Tooltip title={formatValue4(history.total)} placement="top" className="total-value-tooltip">
                                                    <IconButton>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div> : history.total) : ""}</span> 
                                        </div>
                                    }
                                    {(tabValue == "processing" || tabValue == "waiting") && 
                                        <div className="item">
                                            <span>{history.stop_loss ? (history.product_id ? <ItemPrice product_id={history.product_id} value={history.stop_loss} /> : history.stop_loss) : ""}</span> 
                                        </div>
                                    }
                                    {(tabValue == "processing" || tabValue == "waiting") && 
                                        <div className="item">
                                            <span>{history.take_profit ? (history.product_id ? <ItemPrice product_id={history.product_id} value={history.take_profit} /> : history.take_profit) : ""}</span> 
                                        </div>
                                    }
                                </div>
                            ))}
                            {getNewHistory && <div className="loading"><Loading /></div>}
                        </> : 
                        <div className="no-data">
                            <i className="fa-regular fa-folder-open"></i>
                            <span>There are no order data</span>
                        </div>}
                    </> : <Loading />}
                </div>
            </div>
        </>
    );
    
}

const SpotFutureHistory = ({ fromPage, dataType, dataCurrency, dataCustomerId }) => {
    
    return (
        <>
            {fromPage == "history" ? 
                <div id="main" className="main">
                    <div className="allsections allsections-border">
                        <CreateOrderHistory fromPage={fromPage} dataType={dataType} dataCurrency={dataCurrency} dataCustomerId={dataCustomerId} />
                    </div>
                </div>
                : <CreateOrderHistory fromPage={fromPage} dataType={dataType} dataCurrency={dataCurrency} dataCustomerId={dataCustomerId} />
            }
        </>
    );
    
}

export default SpotFutureHistory;