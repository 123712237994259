import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { Auth } from 'aws-amplify';
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import axios from "axios";
import moment from "moment-timezone";
import ProfileWallet from "./ProfileWallet";
import ProfileWalletList from "./ProfileWalletList";
import Popup_DepositNetworkAddressesDetails from "./Popup_DepositNetworkAddressesDetails";
import DepositWithdrawTransferHistory from "./DepositWithdrawTransferHistory";
import SpotFutureHistory from "./SpotFutureHistory";
import FutureProcessHistory from "./FutureProcessHistory";
import FutureProcessEvents from "./FutureProcessEvents";
import "./Styles/page_profile.css";
import ReactCountryFlag from "react-country-flag";
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import isEqual from "react-fast-compare";
import withLoadingData from "../Container/withLoadingData";
import { onFutureProfileOpen, onFutureProfileClose } from "../Reducer/kpriceReducer";
import Decimal from 'decimal.js';
import { debounce } from 'lodash';
import { formatValuePriceControl, formatValue3, formatValue4, formatValue5 } from '../Lib/calculationFunctions';

const Profile = ({ pageType, isFutureProfilesLoading, isFutureProcessesLoading }) => {
    
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();
    
    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customerWalletInfo, setCustomerWalletInfo] = useState(null);
    const [showAllCount, setShowAllCount] = useState(false);
    const [firstDispatch, setFirstDispatch] = useState(false);
    const tableArea = useRef(null);//tablo alanı
    
    const [selectedCustomerIdUserDetails, setSelectedCustomerIdUserDetails] = useState(null);
    const [selectedCustomerIdUserAndAddressesDetails, setSelectedCustomerIdUserAndAddressesDetails] = useState(null);

    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const futureProfiles = useSelector((state) => state.kprice.futureProfiles.data);
    const futureProcesses = useSelector((state) => state.kprice.futureProcesses.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

    const [balance, setBalance] = useState(0);
    const [credit, setCredit] = useState(0);
    const [displayCreditInfo, setDisplayCreditInfo] = useState(false);
    const [totalProfit, setTotalProfit] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0);
    const [totalSurety, setTotalSurety] = useState(0);
    const [freeMargin, setFreeMargin] = useState(0);
    const [marginLevel, setMarginLevel] = useState(null);

    useEffect(() => {//customerId gelirse
        if(customerId){
            getCustomerInfo();
        }
        else{
            navigate(`/profile`);
        }
    }, [customerId]);

    useEffect(() => {
        if(customerInfo && customerInfo != "User not found"){
            getCustomerWalletInfo();
        }
    }, [customerInfo]);

    useEffect(() => {
        if(!firstDispatch){
            setFirstDispatch(true);
            return;
        }
        
        if(customerId && customerInfo && customerInfo != "User not found"){
            if(pageType == "future"){
                dispatch(onFutureProfileOpen({ customerId }));
            }
        }

        return () => {//component kapanırken abonelik sonlandırılıyor
            dispatch(onFutureProfileClose());
        };
    }, [customerId, customerInfo, pageType]);

    useEffect(() => {
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }
    }, [pageType]);

    const getCustomerInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer?customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.customer && response.data.customer[0]){
                    let newData = response.data.customer[0];
                    newData.status_level = Number(newData.status_level);
                    newData.registration_time = Number(newData.registration_time);
                    newData.registration_date = moment(newData.registration_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    setCustomerInfo(newData);
                }
                else{
                    setCustomerInfo("User not found");
                }
            }
            else{
                setCustomerInfo("User not found");
            }
        }
        catch (error) {
            setCustomerInfo("User not found");
            if(!(error && error.response && error.response.data && error.response.data.error && error.response.data.error == "Invalid User")){
                toast.error("There was a problem loading customer information!");
            }
        }
    }

    const getCustomerWalletInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/wallet?type=user&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.wallet_items){
                    let newData = {};
                    response.data.wallet_items.forEach((item) => {
                        newData[item.currency] = {
                            currency: item.currency,
                            count: Number(item.total_count)
                        };
                    });
                    
                    setCustomerWalletInfo(newData);
                }
            }
            else{
                toast.error("There was a problem loading the customer's wallet information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the customer's wallet information!");
        }
    }

    const SuccessIcon = () => {
        return <div className="status-icon success"><i className="fa-solid fa-circle-check"></i></div>;
    }

    const WarningIcon = () => {
        return <div className="status-icon warning" title="Customer needs to complete verification steps"><i className="fa-solid fa-circle-exclamation"></i></div>;
    }

    const DangerIcon = () => {
        return <div className="status-icon danger" title="Please contact our support team"><i className="fa-solid fa-circle-xmark"></i></div>;
    }

    const ItemMarket = ({ product_id }) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const ItemPrice = ({ product_id, value }) => {
        const show_places = productsData && productsData[product_id] && productsData[product_id].max_order_asking_price_places ? productsData[product_id].max_order_asking_price_places : null;
        
        if(value == "" || value == null || value == undefined){
            return "";
        } else{
            const response = formatValuePriceControl(value, show_places);
            if(response){
                return formatValue3(value, show_places);
            }
            else{
                return formatValue4(value);
            }
        }
    }

    const ItemFutureType = ({ type }) => {
        if(type === "buy"){
            return <span className="success">Buy</span>;
        } else{
            return <span className="danger">Sell</span>;
        }
    }

    const processesDataControl_PriceForTable = (item) => {
        const productMaxOrderAskingPricePlaces = productsData && productsData[item.product_id] && productsData[item.product_id].max_order_asking_price_places ? productsData[item.product_id].max_order_asking_price_places : null;
        const productShowPlaces = productsData && productsData[item.product_id] && productsData[item.product_id].show_places ? productsData[item.product_id].show_places : null;
        
        let firstPrice = null;//item.entry_price
        let secondPrice = null;//productsMiniTickerData[item.product_id].currentPrice

        if(item.entry_price != "" && item.entry_price != null && item.entry_price != undefined){
            const response_firstPrice = formatValuePriceControl(item.entry_price, productMaxOrderAskingPricePlaces);
            if(response_firstPrice){
                firstPrice = formatValue3(item.entry_price, productMaxOrderAskingPricePlaces);
            }
            else{
                firstPrice = formatValue4(item.entry_price);
            }
        }

        const response_secondPrice = productsMiniTickerData && productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? formatValuePriceControl(productsMiniTickerData[item.product_id].currentPrice, productShowPlaces) : null;
        if(response_secondPrice){
            secondPrice = productsMiniTickerData && productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? formatValue3(productsMiniTickerData[item.product_id].currentPrice, productShowPlaces) : null;
        }
        else{
            secondPrice = productsMiniTickerData && productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? formatValue4(productsMiniTickerData[item.product_id].currentPrice) : null;
        }

        return <span className="priceForTable">{firstPrice}<i className="fa-solid fa-arrow-right"></i>{secondPrice}</span>
    }

    const processesDataControl_CalcProfit = (future_type, product_id, entry_price, available_count) => {
        let profit = 0;

        if(future_type == "buy"){
            profit = (new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0).minus(new Decimal(entry_price))).times(new Decimal(available_count));
        }
        else if(future_type == "sell"){
            profit = (new Decimal(entry_price).minus(new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0))).times(new Decimal(available_count));
        }
        
        return Number(profit);
    }
    
    const processesDataControl_ProfitForTable = (item) => {
        const currentProfit = item.state == "active" ? processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count) : item.process_profit;
        return <span className={currentProfit != "" && currentProfit != null && currentProfit != undefined ? (currentProfit > 0 ? "success" : (currentProfit < 0 ? "danger" : undefined)) : undefined}>{currentProfit != "" && currentProfit != null && currentProfit != undefined ? formatValue5(currentProfit, showPlacesUSDT) : ""}</span>
    }

    useEffect(() => {//sabit bilgi alanı için hesaplamalar
        if(pageType == "future" && productsMiniTickerData && futureProcesses && futureProcesses.process_items && futureProfiles && futureProfiles.profile_items){
            let tempTotalBalance = futureProfiles.profile_items[0].balance ? futureProfiles.profile_items[0].balance : 0;
            let tempTotalProfit = 0;
            let tempTotalSurety = 0;
            let tempTotalAssets = 0;
            let tempCredit = futureProfiles.profile_items[0].credit ? futureProfiles.profile_items[0].credit : 0;
            
            futureProcesses.process_items.map((item, index) => {
                if(item.future_type == "buy"){
                    tempTotalProfit = productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? new Decimal(tempTotalProfit).plus((new Decimal(productsMiniTickerData[item.product_id].currentPrice).minus(new Decimal(item.entry_price))).times(new Decimal(item.available_count))) : tempTotalProfit;
                }
                else if(item.future_type == "sell"){
                    tempTotalProfit = productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? new Decimal(tempTotalProfit).plus((new Decimal(item.entry_price).minus(new Decimal(productsMiniTickerData[item.product_id].currentPrice))).times(new Decimal(item.available_count))) : tempTotalProfit;
                }
                
                tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
            });

            setTotalProfit(tempTotalProfit);
            setTotalSurety(tempTotalSurety);
            
            setBalance(tempTotalBalance);
            setCredit(tempCredit);
            tempTotalAssets = new Decimal(tempTotalBalance).plus(new Decimal(tempTotalProfit));
            tempTotalAssets = tempTotalAssets.plus(new Decimal(tempCredit));
            setTotalAssets(tempTotalAssets);

            setFreeMargin(tempTotalAssets ? new Decimal(tempTotalAssets).minus(new Decimal(tempTotalSurety)) : 0);
            setMarginLevel(futureProcesses.process_items.length > 0 ? (tempTotalAssets && tempTotalSurety ? (new Decimal(tempTotalAssets).div(new Decimal(tempTotalSurety))).times(100) : 0) : null);
        }
    }, [productsMiniTickerData, futureProcesses, futureProfiles, pageType]);

    const tableProcessesColumns = useMemo(() => [//tablo başlıkları
        { accessorKey: 'process_timestamp', header: 'Timestamp' },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'future_type', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'lot', header: 'Lot', Cell: ({ row }) => row.original.lotForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
    ], []);

    const tableProcessesData = useMemo(() => {//tablo bilgileri
        const newData = [];

        productsMiniTickerData && currenciesData && productsData && futureProcesses && futureProcesses.process_items && futureProcesses.process_items.map((history) => {
            newData.push({
                customer_id: history.customer_id ? history.customer_id : "",
                customerIdForTable: 
                    <>
                        <div className="td-text-with-button customer-id">{history.customer_id}</div>
                        <Tooltip title="Profile" className="detail-button" placement="right">
                            <a href={`/profile?customer_id=${history.customer_id}`} target="_blank">
                                <i className="fa-solid fa-user"></i>
                            </a>
                        </Tooltip>
                    </>,
                market: history.product_id,
                marketForTable: <ItemMarket product_id={history.product_id} />,
                leverage: history.leverage ? Number(history.leverage) : 0,
                stopLossForTable: <ItemPrice product_id={history.product_id} value={history.stop_loss} />,
                takeProfitForTable: <ItemPrice product_id={history.product_id} value={history.take_profit} />,
                process_timestamp: history.result_timestamp ? history.result_timestamp : history.entry_timestamp,
                process_order_id: history.order_id ? history.order_id : "",
                process_entry_date: history.entry_date ? history.entry_date : "",
                process_result_date: history.result_date ? history.result_date : "",
                future_type: history.future_type ? history.future_type : "",
                futureTypeForTable: history.future_type ? <ItemFutureType type={history.future_type} /> : "",
                lot: history.available_count ? history.available_count : 0,
                lotForTable: history.available_count != "" && history.available_count != null && history.available_count != undefined ? formatValue4(history.available_count) : "",
                price: history.entry_price ? history.entry_price : 0,
                priceForTable: processesDataControl_PriceForTable(history),
                profit: processesDataControl_CalcProfit(history.future_type, history.product_id, history.entry_price, history.available_count),
                profitForTable: processesDataControl_ProfitForTable(history),
            })
        })

        return newData;
    }, [futureProcesses, pageType, currenciesData, productsData, productsMiniTickerData]);

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon
        let row = null;
        let rowIsValid = false;
        if(event.target.tagName == "TR"){
            rowIsValid = true;
            row = event.target;
        }
        else{
            rowIsValid = true;
            row = event.target.closest('tr');
        }
        
        if(row && rowIsValid){
            const expandButton = row.querySelector("td:first-child button");
            if(expandButton){
                expandButton.classList.add("clicked");
                expandButton.click();
                setTimeout(() => {
                    expandButton.classList.remove("clicked");
                }, 500);
            }
        }
    };

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.profile-future .withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [tableProcessesData]);

    useEffect(() => {//tabloyu observer ile dinleme alanı (scroll ile ilgili)
        const tableContainer = document.querySelector('.profile-future-processes .withExpandMenu');
        if (!tableContainer) return;

        let allMutations = []; // Tüm mutationları biriktireceğimiz array

        // Tüm mutationları işleyecek fonksiyon
        const handleMutations = debounce(() => {
            let hasDetailPanel = false;
            for (const mutation of allMutations) {
                if(mutation){
                    if(mutation.target.classList.contains("Mui-TableBodyCell-DetailPanel") || mutation.target.classList.contains("submenu-process-values-right") || mutation.target.classList.contains("MuiTouchRipple-root")){
                        hasDetailPanel = true;
                    }
                }
            }

            if(!hasDetailPanel){
                const submenuElements = document.querySelectorAll('.submenu-values');
                submenuElements.forEach(element => {
                    const closestTrElement = element.closest('tr');
                    const closestTrElementPrevious = closestTrElement.previousElementSibling;
                    if(closestTrElementPrevious){
                        const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                        if(elementExpandButton && !elementExpandButton.classList.contains("clicked")){
                            elementExpandButton.classList.add("clicked");
                            elementExpandButton.click();
                            setTimeout(() => {
                                elementExpandButton.classList.remove("clicked");
                            }, 500);
                        }
                    }
                });
            }
            allMutations = []; // İşlemden sonra array'i sıfırla
        }, 100);

        // Observer callback fonksiyonu, her bir mutation'ı biriktirir
        const observerCallback = (mutationsList, observer) => {
            allMutations = allMutations.concat(mutationsList); // Mevcut mutationları biriktir
            handleMutations(); // Debounce fonksiyonunu tetikle
        };

        const observer = new MutationObserver(observerCallback);
        observer.observe(tableContainer, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            handleMutations.cancel(); // Debounce fonksiyonunu iptal et
        };
    }, [tableProcessesData, pageType]);
    
    return(
        <div id="main" className="main">
            <Popup_DepositNetworkAddressesDetails selectedCustomerId={selectedCustomerIdUserDetails} setSelectedCustomerId={setSelectedCustomerIdUserDetails} selectedAccountName={null} setSelectedAccountName={null} type="User Details" />
            <Popup_DepositNetworkAddressesDetails selectedCustomerId={selectedCustomerIdUserAndAddressesDetails} setSelectedCustomerId={setSelectedCustomerIdUserAndAddressesDetails} selectedAccountName={null} setSelectedAccountName={null} type="User and Addresses Details" />
            {customerId && customerInfo != "User not found" ? 
                <>
                    <div className="profile-header allsections allsections-border">
                        {customerInfo == null ? 
                            <Loading />
                        : 
                            <>
                                <div className="item">
                                    <label>Customer ID</label>
                                    <span>{customerInfo && customerInfo.customer_id ? customerInfo.customer_id : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Email</label>
                                    <span>{customerInfo && customerInfo.email ? customerInfo.email : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Full Name</label>
                                    <span>{customerInfo && customerInfo.full_name ? customerInfo.full_name : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Pending Operation</label>
                                    <span>{customerInfo && (customerInfo.pending_operation == true || customerInfo.pending_operation == false) ? (customerInfo.pending_operation == true ? "True" : "False") : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Registration Date</label>
                                    <span>{customerInfo && customerInfo.registration_date ? customerInfo.registration_date : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Status</label>
                                    <span>{customerInfo && customerInfo.status ? customerInfo.status : "-"}</span>
                                </div>
                                <div className="item">
                                    <label>Status Level</label>
                                    <span>{customerInfo && customerInfo.status_level ? customerInfo.status_level : "-"}</span>
                                </div>
                            </>
                        }
                        
                    </div>
                    <div className="profile-body" ref={tableArea}>
                        <div className="profile-body-top">
                            <div className="profile-body-left">
                                {pageType == "profile" && <>
                                    <div className="profile-wallet">
                                        {customerWalletInfo == null ? 
                                            <Loading />
                                        : 
                                            <>
                                                <ProfileWallet walletData={customerWalletInfo} showAllCount={showAllCount} setShowAllCount={setShowAllCount} />
                                                <ProfileWalletList walletData={customerWalletInfo} showAllCount={showAllCount} />
                                            </>
                                        }
                                    </div>
                                    <div className="profile-informations allsections allsections-border">
                                        {customerInfo == null ? 
                                            <Loading />
                                            :
                                            <>
                                                <div className="item">
                                                    <label>Id:</label>
                                                    <span>{customerInfo && customerInfo.customer_id ? customerInfo.customer_id : ""}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Level:</label>
                                                    <span>{customerInfo && customerInfo.status_level ? customerInfo.status_level : "1"}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Full Name:</label>
                                                    <span>{customerInfo && customerInfo.full_name ? customerInfo.full_name : ""}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Email:</label>
                                                    <span>{customerInfo && customerInfo.email ? customerInfo.email : ""}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Phone:</label>
                                                    <span>{customerInfo && customerInfo.phone_verification && customerInfo.phone_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}{customerInfo && customerInfo.phone_number ? customerInfo.phone_number : ""}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Nationality:</label>
                                                    <span>{customerInfo && customerInfo.nationality ? <ReactCountryFlag countryCode={customerInfo.nationality} svg title={customerInfo.nationality} /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item br">
                                                    <label>Spot Transaction Permission:</label>
                                                    <span>{customerInfo && customerInfo.spot_permission && customerInfo.spot_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Future Transaction Permission:</label>
                                                    <span>{customerInfo && customerInfo.future_permission && customerInfo.future_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item br">
                                                    <label>Deposit:</label>
                                                    <span>{customerInfo && customerInfo.deposit_permission && customerInfo.deposit_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Withdraw:</label>
                                                    <span>{customerInfo && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item br">
                                                    <label>Account Status:</label>
                                                    <span>{customerInfo && customerInfo.status && customerInfo.status == "active" ? <SuccessIcon /> : <DangerIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Pending Operation:</label>
                                                    <span>{customerInfo && customerInfo.pending_operation ? customerInfo.pending_operation == "True" : ""}</span>
                                                </div>
                                                <div className="item br">
                                                    <label>Info Verification:</label>
                                                    <span>{customerInfo && customerInfo.info_verification && customerInfo.info_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Identity Type:</label>
                                                    <span>{customerInfo && customerInfo.identity_type ? customerInfo.identity_type : ""}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Front ID Image Verification:</label>
                                                    <span>{customerInfo && customerInfo.identity1_verification && customerInfo.identity1_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Back ID Image Verification:</label>
                                                    <span>{customerInfo && customerInfo.identity2_verification && customerInfo.identity2_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>User Photo Verification:</label>
                                                    <span>{customerInfo && customerInfo.customer_image_verification && customerInfo.customer_image_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                                <div className="item">
                                                    <label>Residence Image Verification:</label>
                                                    <span>{customerInfo && customerInfo.residence_verification && customerInfo.residence_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>}
                                {pageType == "future" && <>
                                    <div className="allsections allsections-border profile-future">
                                        <div className={isFutureProfilesLoading != false ? "general-values loading" : "general-values"}>
                                        {isFutureProfilesLoading != false ? 
                                            <Loading />
                                            :
                                            <>
                                                <div>
                                                    <span>Balance: </span>
                                                    <label>{formatValue3(balance, showPlacesUSDT)}</label>
                                                </div>
                                                <div>
                                                    <span>Credit: </span>
                                                    <label>{formatValue3(credit, showPlacesUSDT)}</label>
                                                    <i className="fa-solid fa-circle-info info-icon" onMouseEnter={() => setDisplayCreditInfo(true)} onMouseLeave={() => setDisplayCreditInfo(false)}></i>
                                                    <div className="credit-info" style={displayCreditInfo ? undefined : {display: "none"}}>
                                                        {futureProfiles ? <>
                                                            <div>Credit Active: <b>{futureProfiles && futureProfiles.profile_items && futureProfiles.profile_items[0] ? futureProfiles.profile_items[0].credit_active : "False"}</b></div>
                                                            <div>Credit Available: <b>{futureProfiles && futureProfiles.profile_items && futureProfiles.profile_items[0] ? futureProfiles.profile_items[0].credit_available : "False"}</b></div>
                                                            <div>Credit Deposit: <b>{futureProfiles && futureProfiles.profile_items && futureProfiles.profile_items[0] ? futureProfiles.profile_items[0].credit_deposit : "0"}</b></div>
                                                            <div>Credit Status: <b>{futureProfiles && futureProfiles.profile_items && futureProfiles.profile_items[0] ? futureProfiles.profile_items[0].credit_status : "deactive"}</b></div>
                                                        </> : <Loading />}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>Total Assets: </span>
                                                    <label>{formatValue3(totalAssets, showPlacesUSDT)}</label>
                                                </div>
                                                <div>
                                                    <span>Total Surety: </span>
                                                    <label>{formatValue3(totalSurety, showPlacesUSDT)}</label>
                                                </div>
                                                <div>
                                                    <span>Free Margin: </span>
                                                    <label>{formatValue3(freeMargin, showPlacesUSDT)}</label>
                                                </div>
                                                <div className={marginLevel != null && marginLevel < 75 ? "danger" : undefined}>
                                                    <span>Margin Level: </span>
                                                    <label>{marginLevel ? formatValue3(marginLevel, 2) : "-"}</label>
                                                </div>
                                            </>
                                        }
                                        </div>
                                        <div className={`table-area profile-future-processes ${customerInfo && customerInfo.future_permission && customerInfo.future_permission == "False" && "with-verify-info"}`}>
                                            <div className={isFutureProcessesLoading != false ? "list loading" : "list"}>
                                                {isFutureProcessesLoading != false ? 
                                                    <Loading />
                                                    :
                                                    <div className="column6 withExpandMenu noPagination rowVirtualization">
                                                        <div className="total-profit-area">
                                                            <label className={totalProfit > 0 ? "success" : (totalProfit < 0 ? "danger" : undefined)}>{formatValue3(totalProfit, showPlacesUSDT)}</label>
                                                        </div>
                                                        <div className="back-to-profile-area">
                                                            <Link to={`/profile?customer_id=${customerId}`}><i className="fa-solid fa-reply"></i>Back to Profile</Link>
                                                        </div>
                                                        <MaterialReactTable columns={tableProcessesColumns} data={tableProcessesData} enablePagination={false} enableRowVirtualization={true} initialState={{columnVisibility: { process_timestamp: false }, sorting: [{id: 'process_timestamp', desc: true}]}}
                                                            renderDetailPanel={({ row }) => (
                                                                <div className="submenu-values process">
                                                                    <div className="submenu-process-values-left" data-orderid={row.original.process_order_id}>
                                                                        <div>
                                                                            <span>Date:</span>
                                                                            <label>{row.original.process_entry_date}</label>
                                                                        </div>
                                                                        <div>
                                                                            <span>Leverage:</span>
                                                                            <label>{row.original.leverage}</label>
                                                                        </div>
                                                                        <div className="take_profit">
                                                                            <span>Take Profit:</span>
                                                                            <label>{row.original.takeProfitForTable}</label>
                                                                        </div>
                                                                        <div className="stop_loss">
                                                                            <span>Stop Loss:</span>
                                                                            <label>{row.original.stopLossForTable}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submenu-process-values-right">
                                                                        <FutureProcessEvents orderId={row.original.process_order_id} customerId={row.original.customer_id} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            />
                                                    </div>
                                                }
                                            </div>
                                            {customerInfo && customerInfo.future_permission && customerInfo.future_permission == "False" && <div className="verify-info"><span>Customer must verify to perform future transactions!</span></div>}
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="profile-body-right">
                                <div className="profile-buttons allsections allsections-border">
                                    {customerInfo == null ? 
                                        <Loading />
                                        : 
                                        <>
                                            {customerInfo && customerInfo.deposit_permission && customerInfo.deposit_permission == "True" && <>
                                                <button type="button" className="button" onClick={() => setSelectedCustomerIdUserDetails(customerId)}><i className="fa-solid fa-address-card"></i>User Details</button> 
                                                <button type="button" className="button" onClick={() => setSelectedCustomerIdUserAndAddressesDetails(customerId)}><i className="fa-solid fa-file-invoice-dollar"></i>Deposit Network Addresses Details</button>
                                            </>}
                                            <Link to={`/verification?customer_id=${customerId}`} className="button"><i className="fa-solid fa-user-check"></i>Verification</Link>
                                            {pageType == "profile" && <Link to={`/profile/future?customer_id=${customerId}`} className="button"><i className="fa-solid fa-chart-line"></i>Future</Link>}
                                            <Link to={`/withdraw?customer_id=${customerId}`} className="button">
                                                <i className="fa-solid fa-hand-holding-dollar"></i>
                                                Create a Withdraw Request
                                                {customerInfo && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "False" && <WarningIcon />}
                                            </Link>
                                            <Link to={`/deposit?customer_id=${customerId}`} className="button">
                                                <i className="fa-solid fa-money-bill"></i>
                                                Deposit
                                                {customerInfo && customerInfo.deposit_permission && customerInfo.deposit_permission == "False" && <WarningIcon />}
                                            </Link>
                                            <Link to={`/credit-features-change?customer_id=${customerId}`} className="button">
                                                <i className="fa-solid fa-chart-line"></i>
                                                Credit Features Change
                                                {customerInfo && customerInfo.future_permission && customerInfo.future_permission == "False" && <WarningIcon />}
                                            </Link>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="profile-body-center">
                            {pageType == "profile" && <>
                                <div className="allsections allsections-border profile-spot-orders">
                                    <div className="allsections-title allsections-title2"><div className="with-button"><span>Spot Orders</span><Link to={`/customer/spotorder?type=general&customer_id=${customerId}`}><i className="fa-solid fa-calendar-days"></i>Details</Link></div></div>
                                    {customerInfo && customerInfo != "User not found" && <SpotFutureHistory fromPage="profile" dataType="spot" dataCurrency={null} dataCustomerId={null} />}
                                </div>
                            </>}
                            {pageType == "future" && <>
                                <div className="allsections allsections-border profile-spot-orders">
                                    <div className="allsections-title allsections-title2"><div className="with-button"><span>Future Position History</span><Link to={`/customer/futurepositionhistory?type=user&customer_id=${customerId}`}><i className="fa-solid fa-calendar-days"></i>Details</Link></div></div>
                                    {customerInfo && customerInfo != "User not found" && <FutureProcessHistory fromPage="profile" />}
                                </div>
                            </>}
                            <div className="allsections allsections-border profile-spot-orders">
                                <div className="allsections-title allsections-title2"><div className="with-button"><span>Future Orders</span><Link to={`/customer/futureorder?type=general&customer_id=${customerId}`}><i className="fa-solid fa-calendar-days"></i>Details</Link></div></div>
                                {customerInfo && customerInfo != "User not found" && <SpotFutureHistory fromPage="profile" dataType="future" dataCurrency={null} dataCustomerId={null} />}
                            </div>
                        </div>
                        {pageType == "profile" && <>
                            <div className="profile-body-bottom">
                                <div className="allsections allsections-border profile-history">
                                    {customerInfo && customerInfo != "User not found" && <DepositWithdrawTransferHistory dataType="deposit" listType="scroll" titleInfo="Deposits" />}
                                </div>
                                <div className="allsections allsections-border profile-history">
                                    {customerInfo && customerInfo != "User not found" && <DepositWithdrawTransferHistory dataType="withdraw" listType="scroll" titleInfo="Withdraws" />}
                                </div>
                                <div className="allsections allsections-border profile-history">
                                    {customerInfo && customerInfo != "User not found" && <DepositWithdrawTransferHistory dataType="transfer" listType="scroll" titleInfo="Transfers" />}
                                </div>
                            </div>
                        </>}
                    </div>
                </>
            : 
                <div className="allsections allsections-border profile-no-user">
                    <i className="fa-solid fa-user-slash"></i>
                    <div>User not found</div>
                </div>
            }
        </div>
    )

}

export default withLoadingData()(React.memo(Profile, isEqual));