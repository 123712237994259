import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment-timezone";
import Decimal from 'decimal.js';

const DepositWithdrawTransferHistory = ({dataType, listType, titleInfo}) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [pageType, setPageType] = useState(null);
    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const tableArea = useRef(null);//tablo alanı
    const dateArea = useRef(null);//tarih seçme alanı

    const [historyDataScroll, setHistoryDataScroll] = useState(null);
    const [historyDataScrollLastEvaluatedKey, setHistoryDataScrollLastEvaluatedKey] = useState(null);
    const [getNewHistory, setGetNewHistory] = useState(false);
    const itemsScrollPosition = useRef(0);
    const historyElements = useRef(null);

    const [historyDataDate, setHistoryDataDate] = useState(null);
    const [startDateInfo, setStartDateInfo] = useState(null);
    const [endDateInfo, setEndDateInfo] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");

    useEffect(() => {//link değiştikçe animasyon tetikleniyor ve açılıştaki veriler düzenleniyor
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }

        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
            setPageType("user");
        }
        else{
            setPageType("all");
        }

        const currentStartDate = query.get("start_date");
        if(currentStartDate){
            setStartDateInfo(currentStartDate);
        }
        else{
            setStartDateInfo(null);
        }

        const currentEndDate = query.get("end_date");
        if(currentEndDate){
            setEndDateInfo(currentEndDate);
        }
        else{
            setEndDateInfo(null);
        }
    }, [location]);

    useEffect(() => {//link değiştikçe animasyon tetikleniyor
        if(dateArea && dateArea.current){
            dateArea.current.style.animation = "none";
            let tempForAnimation = dateArea.current.offsetHeight;
            dateArea.current.style.animation = "open-animation 1s";
        }
    }, [dataType]);

    useEffect(() => {//link değiştikçe animasyon tetikleniyor ve açılıştaki veriler düzenleniyor
        setSelectedStartDate("");
        setSelectedEndDate("");
    }, [startDateInfo, endDateInfo]);

    useEffect(() => {//açılıştaki veriler düzenleniyor
        if(pageType && listType == "scroll"){
            let linkInfo = "";
            if(pageType == "user" && customerId){
                linkInfo = `?customer_id=${customerId}&type=user`;
            }
            else if(pageType == "all"){
                linkInfo = "?type=all";
            }

            if(linkInfo != ""){
                setHistoryDataScroll(null);
                getHistoryInfo(linkInfo, "initial");
            }
        }
    }, [pageType, customerId, location]);

    useEffect(() => {
        if(historyElements && historyElements.current){
            if(getNewHistory){
                historyElements.current.scrollTop = historyElements.current.scrollHeight - historyElements.current.clientHeight;
            }
            else{
                historyElements.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [getNewHistory]);

    useEffect(() => {
        setGetNewHistory(false);
    }, [historyDataScroll]);

    useEffect(() => {
        setHistoryDataDate(null);
        if((startDateInfo || endDateInfo) && listType == "date"){
            let error = false;

            if(startDateInfo != null && startDateInfo != "" && endDateInfo != null && endDateInfo != ""){
                const datePartsStart = startDateInfo.split('-').map(Number);
                const datePartsEnd = endDateInfo.split('-').map(Number);
    
                if(datePartsStart[0] > datePartsEnd[0] || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] > datePartsEnd[1]) || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] == datePartsEnd[1] && datePartsStart[2] > datePartsEnd[2])){
                    toast.error("The start date cannot be greater than the end date!");
                    error = true;
                }
                else{
                    let linkInfo = "";
    
                    if(pageType == "user" && customerId){
                        linkInfo = `/date?customer_id=${customerId}&type=user&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }
                    else if(pageType == "all"){
                        linkInfo = `/date?type=all&start_date_str=${startDateInfo}&end_date_str=${endDateInfo}`;
                    }
    
                    if(linkInfo != ""){
                        getHistoryInfo(linkInfo, "initial");
                    }
                }
            }
            else if((startDateInfo == null || startDateInfo == "") && (endDateInfo == null || endDateInfo == "")){
                toast.error("Please select start and end date!");
                error = true;
            }
            else if(startDateInfo == null || startDateInfo == ""){
                toast.error("Please select start date!");
                error = true;
            }
            else if(endDateInfo == null || endDateInfo == ""){
                toast.error("Please select end date!");
                error = true;
            }

            if(error){
                if(pageType == "user" && customerId){
                    navigate(`?customer_id=${customerId}`);
                }
                else if(pageType == "all"){
                    navigate(``);
                }
            }
        }
    }, [startDateInfo, endDateInfo]);

    const getHistoryInfo = async (linkInfo, getType) => {
        if(linkInfo != ""){
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
    
            try {
                const response = await axios.get(`https://operationapi.kpriceapp.com/${dataType}/history${linkInfo}`, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                
                if(response.status == "200"){
                    let currentData = response.data.history_items;
                    currentData.forEach(item => {
                        if(item.event_timestamp){
                            item.event_timestamp = Number(item.event_timestamp);
                            item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                        if(item.result_timestamp){
                            item.result_timestamp = Number(item.result_timestamp);
                            item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        }
                        if(item.count){
                            item.count = Decimal(item.count).toFixed();
                        }
                        if(item.fee_count){
                            item.fee_count = Decimal(item.fee_count).toFixed();
                        }
                    });
                    if(listType == "scroll"){
                        if(getType == "initial"){
                            setHistoryDataScroll(currentData);
                        }
                        else if(getType == "additional"){
                            setHistoryDataScroll((prevItems) => (Array.isArray(prevItems) ? [...prevItems, ...currentData] : [...currentData]));
                        }
                        setHistoryDataScrollLastEvaluatedKey(response.data.last_evaluated_key);
                    }
                    else{
                        setHistoryDataDate(currentData);
                    }
                }
                else{
                    toast.error("There was a problem loading history information!");
                }
            }
            catch (error) {
                toast.error("There was a problem loading history information!");
            }
        }
    }

    const historyElementsOnScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight + 2 >= scrollHeight && historyDataScrollLastEvaluatedKey && !getNewHistory) {//eğer divin en altına gelindiyse
            if(historyElements && historyElements.current){
                itemsScrollPosition.current = historyElements.current.scrollTop;
            }
            setGetNewHistory(true);

            if(pageType){
                let linkInfo = "";
                if(pageType == "user" && customerId){
                    linkInfo = `?customer_id=${customerId}&type=user&log_id=${historyDataScrollLastEvaluatedKey.log_id}&event_timestamp=${historyDataScrollLastEvaluatedKey.event_timestamp}`;
                }
                else if(pageType == "all"){
                    linkInfo = `?type=all&last_key_customer_id=${historyDataScrollLastEvaluatedKey.customer_id}&log_id=${historyDataScrollLastEvaluatedKey.log_id}&event_timestamp=${historyDataScrollLastEvaluatedKey.event_timestamp}`;
                }
                
                if(linkInfo != ""){
                    getHistoryInfo(linkInfo, "additional");
                }
            }
        }
    }

    const viewHistoryWithDate = () => {
        if(selectedStartDate != null && selectedStartDate != "" && selectedEndDate != null && selectedEndDate != ""){
            const datePartsStart = selectedStartDate.split('-').map(Number);
            const datePartsEnd = selectedEndDate.split('-').map(Number);

            if(datePartsStart[0] > datePartsEnd[0] || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] > datePartsEnd[1]) || (datePartsStart[0] == datePartsEnd[0] && datePartsStart[1] == datePartsEnd[1] && datePartsStart[2] > datePartsEnd[2])){
                toast.error("The start date cannot be greater than the end date!");
            }
            else{
                if(pageType == "user" && customerId){
                    navigate(`?customer_id=${customerId}&start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
                else if(pageType == "all"){
                    navigate(`?start_date=${selectedStartDate}&end_date=${selectedEndDate}`);
                }
            }
        }
        else if((selectedStartDate == null || selectedStartDate == "") && (selectedEndDate == null || selectedEndDate == "")){
            toast.error("Please select start and end date!");
        }
        else if(selectedStartDate == null || selectedStartDate == ""){
            toast.error("Please select start date!");
        }
        else if(selectedEndDate == null || selectedEndDate == ""){
            toast.error("Please select end date!");
        }
    }

    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const DateTableInfo = ({ history, columnNumber }) => {
        if(columnNumber == "1"){
            return <>
                <div className="element-area">
                    {history.status && 
                        <div className={`item status ${history.status}`}>
                            <label>Status:</label>
                            <span>{history.status == "approved" ? <><i className="fa-solid fa-circle-check"></i> Approved</> : (history.status == "pending" ? <><i className="fa-solid fa-clock"></i> Pending</> : <><i className="fa-solid fa-circle-xmark"></i> Denied</>)}</span>
                        </div>}
                    {history.event_date && 
                        <div className="item">
                            <label>Event Date:</label>
                            <span>{history.event_date}</span>
                        </div>}
                    {history.result_date && 
                        <div className="item">
                            <label>Result Date:</label>
                            <span>{history.result_date}</span>
                        </div>}
                </div>
            </>;
        }
        else if(columnNumber == "2"){
            return <>
                <div className="element-area">
                    {history.currency && 
                        <div className="item">
                            <label>Currency:</label>
                            <span>{history.currency}</span>
                        </div>}
                    {history.count && 
                        <div className="item">
                            <label>Count:</label>
                            <span>{history.count}</span>
                        </div>}
                    {history.fee_count && 
                        <div className="item">
                            <label>Fee:</label>
                            <span>{history.fee_count}</span>
                        </div>}
                    {history.network && 
                        <div className="item">
                            <label>Network:</label>
                            <span>{history.network}</span>
                        </div>}
                    {history.address && 
                        <div className="item">
                            <label>Address:</label>
                            <span>{history.address}</span>
                        </div>}
                    {history.deposit_type && 
                        <div className="item">
                            <label>Deposit Type:</label>
                            <span>{history.deposit_type == "crypto_address" ? "Crypto Address" : (history.deposit_type == "other" ? "Other" : (history.deposit_type == "system_correction" ? "System Correction" : history.deposit_type))}</span>
                        </div>}
                    {history.withdraw_type && 
                        <div className="item">
                            <label>Withdraw Type:</label>
                            <span>{history.withdraw_type == "crypto_address" ? "Crypto Address" : history.withdraw_type}</span>
                        </div>}
                    {history.transfer_type && 
                        <div className="item">
                            <label>Transfer Type:</label>
                            <span>{history.transfer_type == "spot_to_future" ? "Spot to Future" : (history.transfer_type == "future_to_spot" ? "Future to Spot" : history.transfer_type)}</span>
                        </div>}
                </div>
            </>;
        }
        if(columnNumber == "3"){
            return <>
                <div className="element-area">
                    {history.customer_id && 
                        <div className="item">
                            <label>Customer ID:</label>
                            <span><ProfileButton customer_id={history.customer_id} /></span>
                        </div>
                    }
                    {history.event_user_id && 
                        <div className="item">
                            <label>Event User:</label>
                            <span>{history.event_user_id}</span>
                        </div>}
                    {history.result_user_id && 
                        <div className="item">
                            <label>Result User:</label>
                            <span>{history.result_user_id}</span>
                        </div>}
                    {history.transaction_id && 
                        <div className="item">
                            <label>Transaction ID:</label>
                            <span>{history.transaction_id}</span>
                        </div>}
                    {history.extra_info && 
                        <div className="item">
                            <label>Extra Info:</label>
                            <span>{history.extra_info}</span>
                        </div>}
                </div>
            </>;
        }
        else{
            return <></>;
        }
    }

    const tableColumnsDate = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'status', header: 'Status', size: 50, Cell: ({ row }) => row.original.statusForTable },
        { accessorKey: 'transaction_info', header: 'Transaction Info', size: 50, Cell: ({ row }) => row.original.transactionInfoForTable },
        { accessorKey: 'customer_info', header: 'Customer Info', size: 50, Cell: ({ row }) => row.original.customerInfoForTable },
    ], []);

    const tableDataDate = useMemo(() => {//users tablo bilgileri
        const newData = [];

        if(listType == "date" && historyDataDate && historyDataDate.length > 0){
            historyDataDate.map((history) => {
                newData.push({
                    status: history.result_timestamp ? history.result_timestamp : (history.event_timestamp ? history.event_timestamp : 0),
                    statusForTable: <DateTableInfo history={history} columnNumber="1" />,
                    transaction_info: history.count ? history.count : 0,
                    transactionInfoForTable: <DateTableInfo history={history} columnNumber="2" />,
                    customer_info: history.customer_id ? history.customer_id : "",
                    customerInfoForTable: <DateTableInfo history={history} columnNumber="3" />,
                })
            })
        }

        return newData;
    }, [listType, historyDataDate]);
    
    return (
        <div className="main">
            {listType == "scroll" && 
                <div className="allsections allsections-border" ref={tableArea}>
                    {pageType == "user" && <div className="allsections-title allsections-title2">{titleInfo ? <div className="with-button"><span>{titleInfo}</span><Link to={`/${dataType}s?customer_id=${customerId}`}><i className="fa-solid fa-calendar-days"></i>Details</Link></div> : <><i className="fa-solid fa-user-large"></i><ProfileButton customer_id={customerId} /></>}</div>}
                    <div className="scrollable-section">
                        {historyDataScroll ? <>
                            <div className="scrollable-section-elements" ref={historyElements} onScroll={(e) => historyElementsOnScroll(e)}>
                                {historyDataScroll.length > 0 ? 
                                <>
                                    {historyDataScroll.map((history, index) => (
                                        <div className="element" key={index}>
                                            <div className="element-area">
                                                {history.status && 
                                                    <div className={`item status ${history.status}`}>
                                                        <label>Status:</label>
                                                        <span>{history.status == "approved" ? <><i className="fa-solid fa-circle-check"></i> Approved</> : (history.status == "pending" ? <><i className="fa-solid fa-clock"></i> Pending</> : <><i className="fa-solid fa-circle-xmark"></i> Denied</>)}</span>
                                                    </div>}
                                                {(history.event_date || history.result_date) && 
                                                    <div className="item">
                                                        {history.result_date ? <>
                                                            <label>Result Date:</label>
                                                            <span>{history.result_date}</span>
                                                        </> : <>
                                                            <label>Event Date:</label>
                                                            <span>{history.event_date}</span>
                                                        </>}
                                                    </div>}
                                                {history.event_user_id && 
                                                    <div className="item">
                                                        <label>Event User:</label>
                                                        <span>{history.event_user_id}</span>
                                                    </div>}
                                                {history.result_user_id && 
                                                    <div className="item">
                                                        <label>Result User:</label>
                                                        <span>{history.result_user_id}</span>
                                                    </div>}
                                                {history.transaction_id && 
                                                    <div className="item">
                                                        <label>Transaction ID:</label>
                                                        <span>{history.transaction_id}</span>
                                                    </div>}
                                                {history.extra_info && 
                                                    <div className="item">
                                                        <label>Extra Info:</label>
                                                        <span>{history.extra_info}</span>
                                                    </div>}
                                            </div>
                                            <div className="element-area">
                                                {history.customer_id && titleInfo == undefined && 
                                                    <div className="item">
                                                        <label>Customer ID:</label>
                                                        <span><ProfileButton customer_id={history.customer_id} /></span>
                                                    </div>
                                                }
                                                {history.currency && 
                                                    <div className="item">
                                                        <label>Currency:</label>
                                                        <span>{history.currency}</span>
                                                    </div>}
                                                {history.count && 
                                                    <div className="item">
                                                        <label>Count:</label>
                                                        <span>{history.count}</span>
                                                    </div>}
                                                {history.fee_count && 
                                                    <div className="item">
                                                        <label>Fee:</label>
                                                        <span>{history.fee_count}</span>
                                                    </div>}
                                                {history.network && 
                                                    <div className="item">
                                                        <label>Network:</label>
                                                        <span>{history.network}</span>
                                                    </div>}
                                                {history.address && 
                                                    <div className="item">
                                                        <label>Address:</label>
                                                        <span>{history.address}</span>
                                                    </div>}
                                                {history.deposit_type && 
                                                    <div className="item">
                                                        <label>Deposit Type:</label>
                                                        <span>{history.deposit_type == "crypto_address" ? "Crypto Address" : (history.deposit_type == "other" ? "Other" : (history.deposit_type == "system_correction" ? "System Correction" : history.deposit_type))}</span>
                                                    </div>}
                                                {history.withdraw_type && 
                                                    <div className="item">
                                                        <label>Withdraw Type:</label>
                                                        <span>{history.withdraw_type == "crypto_address" ? "Crypto Address" : history.withdraw_type}</span>
                                                    </div>}
                                                {history.transfer_type && 
                                                    <div className="item">
                                                        <label>Transfer Type:</label>
                                                        <span>{history.transfer_type == "spot_to_future" ? "Spot to Future" : (history.transfer_type == "future_to_spot" ? "Future to Spot" : history.transfer_type)}</span>
                                                    </div>}
                                            </div>
                                        </div>
                                    ))}
                                    {getNewHistory && <div className="loading"><Loading /></div>}
                                </> : 
                                <div className="no-data">
                                    <i className="fa-regular fa-folder-open"></i>
                                    <span>There are no historical data</span>
                                </div>}
                            </div>
                        </> : <Loading />}
                    </div>
                </div>
            }
            {listType == "date" && <>
                <div className="allsections allsections-border" ref={dateArea}>
                    <div className="allsections-title allsections-title2"><i className="fa-solid fa-calendar-days"></i>Choose Date</div>
                    {pageType == "user" && <div className="allsections-title allsections-title2"><i className="fa-solid fa-user-large"></i><ProfileButton customer_id={customerId} /></div>}
                    <div className="choose-date-area">
                        <div>
                            <label>Start Date:</label>
                            <input type="date" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.target.value)} />
                        </div>
                        <div>
                            <label>End Date:</label>
                            <input type="date" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.target.value)} />
                        </div>
                    </div>
                    <button type="button" className="choose-date-button" onClick={() => viewHistoryWithDate()}>View History</button>
                </div>
                {startDateInfo && endDateInfo && 
                    <div className="allsections allsections-border table-area">
                        <div ref={tableArea} className={historyDataDate ? "list" : "list loading"}>
                            {historyDataDate ? <>
                                <div className="column3">
                                    <div className="spot-currency-info"><i className="fa-solid fa-calendar-day"></i>{`${startDateInfo} - ${endDateInfo}`}</div>
                                    <MaterialReactTable columns={tableColumnsDate} data={tableDataDate} initialState={{sorting: [{id: 'status', desc: true}]}} />
                                </div>
                            </> : <Loading />}
                        </div>
                    </div>
                }
            </>}
        </div>
    );
    
}

export default DepositWithdrawTransferHistory;