import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

const GlobalTop = ({ title }) => {

    const [darkMode, setDarkMode] = useState(document.body.classList.contains("dark"));

    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        // Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setDarkMode(document.body.classList.contains("dark"));
                    if(document.body.classList.contains("dark")){
                        localStorage.setItem("darkMode", "true");
                    }
                    else{
                        localStorage.setItem("darkMode", "false");
                    }
                }
            }
        };
    
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });
        
        // Component temizlenirken observer'ı durdurma ve ekran genişliğini dinlemeyi temizleme
        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        if(isScrolled){
            document.body.classList.add("scrolled");
        }
        else{
            document.body.classList.remove("scrolled");
        }
    }, [isScrolled]);

    return(
        <div className="main topsection">
            <div className="topsectionarea">
                <div className="topsection-left">
                    <div className="sidebar-openclose" onClick={() => document.body.classList.toggle("sidebar-close")}>
                        <i className="fa-solid fa-arrow-right"></i>
                        <i className="fa-solid fa-arrow-left" style={{display: "none"}}></i>
                    </div>
                    <div className="pagetitle">{title}</div>
                </div>
                <ToastContainer style={{fontWeight: "normal"}} position="bottom-right" theme="colored" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
                <div className="topsection-right">
                    <i className={darkMode ? "bx bxs-sun lasticon" : "bx bxs-moon lasticon"} title={darkMode ? "Light Theme" : "Dark Theme"} onClick={() => document.body.classList.toggle("dark")}></i>
                </div>
            </div>
        </div>
    )

}

export default GlobalTop;