import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector } from "react-redux";
import { onChangeUsersType } from "../Reducer/kpriceReducer";
import { useDispatch } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import withLoadingData from "../Container/withLoadingData";
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import { onUsersOpen } from "../Reducer/kpriceReducer";

const Users = ({ isUsersLoading }) => {

    const dispatch = useDispatch();
    const [viewType, setViewType] = useState("pc");
    const [activeType, setActiveType] = useState("all");//seçili type değeri
    const [typeSelectDisplay, setTypeSelectDisplay] = useState(false);//type select menü görünürlüğü
    const typeSelect = useRef(null);//type select'i
    const typeSelectMenu = useRef(null);//type select menüsü
    const tableArea = useRef(null);//tablo alanı
    const isFirstRenderForTime = useRef(true);

    const currentUsersType = useSelector((state) => state.kprice.usersType);
    const usersData = useSelector((state) => state.kprice.users.data);

    useEffect(() => {//type güncellenince çalışacak
        dispatch(onUsersOpen());
    }, []);

    useEffect(() => {//type güncellenince çalışacak
        if (isFirstRenderForTime.current) {
            isFirstRenderForTime.current = false;
            return;
        }
        const newType = activeType;
        dispatch(onChangeUsersType({ newType }));
    }, [activeType]);

    useEffect(() => {//animasyon için - type değiştikçe tetiklenecek
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }
    }, [activeType]);

    useEffect(() => {//sayfaya girişte güncel type bilgisi alınıyor
        if(currentUsersType != activeType){
            setActiveType(currentUsersType);
        }
    }, [currentUsersType]);
    
    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const CustomerInfoMobile = ({ customer }) => {
        return (
            <div className="mobile-info">
                <label>Customer ID: <span>{customer.customer_id ? <ProfileButton customer_id={customer.customer_id} /> : ""}</span></label>
                <label>Full Name: <span>{customer.full_name ? customer.full_name : ""}</span></label>
                <label>Email: <span>{customer.email ? customer.email : ""}</span></label>
                <label>Registration Date: <span>{customer.registration_date ? customer.registration_date : ""}</span></label>
            </div>
        );
    }

    const CustomerStatusMobile = ({ customer }) => {
        return (
            <div className="mobile-info">
                <label>Status: <span>{customer.status ? customer.status : ""}</span></label>
                <label>Status Level: <span>{customer.status_level ? customer.status_level : ""}</span></label>
                <label>Pending Operation: <span>{customer.pending_operation == true || customer.pending_operation == false ? (customer.pending_operation == true ? <Tooltip title="True" className="pending-operation success" placement="right"><i className="fa-regular fa-circle-check"></i></Tooltip> : <Tooltip title="False" className="pending-operation danger" placement="right"><i className="fa-regular fa-circle-xmark"></i></Tooltip>) : ""}</span></label>
            </div>
        );
    }

    const tableColumns = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'customer_id', header: 'Customer ID', size: 50, Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'full_name', header: 'Full Name', size: 50 },
        { accessorKey: 'email', header: 'Email', size: 50 },
        { accessorKey: 'registration_date', header: 'Registration Date', size: 50, Cell: ({ row }) => row.original.registrationDateForTable },
        { accessorKey: 'status', header: 'Status', size: 50 },
        { accessorKey: 'status_level', header: 'Status Level', size: 50 },
        { accessorKey: 'pending_operation', header: 'Pending Operation', size: 25, Cell: ({ row }) => row.original.pendingOperationForTable },
    ], []);

    const tableData = useMemo(() => {//users tablo bilgileri
        if (!usersData.length) return [];
    
        const newData = [];
        usersData.map((item, index) => {
            newData.push({
                customer_id: item.customer_id ? item.customer_id : 0,
                customerIdForTable: item.customer_id ? <ProfileButton customer_id={item.customer_id} /> : "",
                full_name: item.full_name ? item.full_name : "",
                email: item.email ? item.email : "",
                registration_date: item.registration_time ? item.registration_time : 0,
                registrationDateForTable: item.registration_date ? item.registration_date : "",
                status: item.status ? item.status : "",
                status_level: item.status_level ? item.status_level : 0,
                pending_operation: item.pending_operation == true || item.pending_operation == false ? item.pending_operation : null,
                pendingOperationForTable: item.pending_operation == true || item.pending_operation == false ? (item.pending_operation == true ? <Tooltip title="True" className="pending-operation success" placement="right"><i className="fa-regular fa-circle-check"></i></Tooltip> : <Tooltip title="False" className="pending-operation danger" placement="right"><i className="fa-regular fa-circle-xmark"></i></Tooltip>) : "",
            });
        });
        
        return newData;
    }, [usersData]);

    const tableColumnsMobile = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'customer_info', header: 'Customer Info', size: 50, Cell: ({ row }) => row.original.customerInfoForTable },
        { accessorKey: 'customer_status', header: 'Customer Status', size: 50, Cell: ({ row }) => row.original.customerStatusForTable },
    ], []);

    const tableDataMobile = useMemo(() => {//users tablo bilgileri
        if (!usersData.length) return [];
    
        const newData = [];
        usersData.map((item, index) => {
            newData.push({
                customer_info: item.registration_time ? item.registration_time : 0,
                customerInfoForTable: <CustomerInfoMobile customer={item} />,
                customer_status: item.status_level ? item.status_level : 0,
                customerStatusForTable: <CustomerStatusMobile customer={item} />,
            });
        });
        
        return newData;
    }, [usersData]);

    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 800){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);

        //closed orders time select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
          window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//type select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (typeSelect.current && !typeSelect.current.contains(e.target) && typeSelectMenu.current && !typeSelectMenu.current.contains(e.target)) {
            setTypeSelectDisplay(false);
        }
    };

    return (
        <div className="main">
            <div className="allsections allsections-border table-area">
                <div className="table-select">
                    <div className="title" ref={typeSelect} onClick={() => {if(typeSelectDisplay){setTypeSelectDisplay(false)}else{setTypeSelectDisplay(true)}}}>
                        {activeType == "all" ? "All" : (activeType == "status_confirmed" ? "Status Confirmed" : (activeType == "status_not_confirmed" ? "Status Not Confirmed" : ""))}
                        <i className="fa-solid fa-caret-up arrow-up" style={typeSelectDisplay ? {} : {display: "none"}}></i>
                        <i className="fa-solid fa-caret-down" style={typeSelectDisplay ? {display: "none"} : {}}></i>
                    </div>
                    <div className="menu" ref={typeSelectMenu} style={typeSelectDisplay ? {display: "block"} : {display: "none"}}>
                        <label className={activeType == "all" ? "active" : undefined} onClick={() => {setActiveType("all");setTypeSelectDisplay(false);}}>All</label>
                        <label className={activeType == "status_confirmed" ? "active" : undefined} onClick={() => {setActiveType("status_confirmed");setTypeSelectDisplay(false);}}>Status Confirmed</label>
                        <label className={activeType == "status_not_confirmed" ? "active" : undefined} onClick={() => {setActiveType("status_not_confirmed");setTypeSelectDisplay(false);}}>Status Not Confirmed</label>
                    </div>
                </div>
                <div ref={tableArea} className={isUsersLoading != false ? "list loading" : "list"}>
                    {isUsersLoading != false ?
                    <Loading /> :
                    <>
                        {viewType == "pc" ? 
                            <div className="column7">
                                <MaterialReactTable columns={tableColumns} data={tableData} initialState={{sorting: [{id: 'registration_date', desc: true}]}} />
                            </div> : 
                            <div className="column2">
                                <MaterialReactTable columns={tableColumnsMobile} data={tableDataMobile} initialState={{sorting: [{id: 'customer_info', desc: true}]}} />
                            </div>
                        }
                    </>
                    }
                </div>
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(Users, isEqual));