import React, { useState, useEffect, useRef } from "react";
import logo from "../images/logo_gold.svg";
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { verificationOnKeyDown, verificationOnChange, pasteVerification } from '../Lib/globalFunctions';
import Loading from "../components/Global/Loading";
import "./Styles/page_login.css";

const Login = () => {

    const [darkMode, setDarkMode] = useState(document.body.classList.contains("dark"));

    const [formType, setFormType] = useState("login");
    const [resultMessage, setResultMessage] = useState("");
    const [resultMessageType, setResultMessageType] = useState("success");
    const resultMessageArea = useRef(null);
    const loginInfo = useRef(null);

    const [firstProcess1, setFirstProcess1] = useState(false);

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginPasswordShow, setLoginPasswordShow] = useState(false);
    const [loginUser, setLoginUser] = useState("");

    const [loginVerification, setLoginVerification] = useState(['', '', '', '', '', '']);

    const passwordInput = useRef(null);
    const confirmPasswordInput = useRef(null);

    const [signUpEmail, setSignUpEmail] = useState("");
    const [signUpEmailValid, setSignUpEmailValid] = useState(false);
    const [signUpPassword, setSignUpPassword] = useState("");
    const [signUpConfirmPassword, setSignUpConfirmPassword] = useState("");
    const [signUpPasswordInfoShow, setSignUpPasswordInfoShow] = useState(false);
    const [signUpPasswordConditions, setSignUpPasswordConditions] = useState([false, false, false, false, false]);
    const [signUpPasswordShow, setSignUpPasswordShow] = useState(false);
    const [signUpConfirmPasswordShow, setSignUpConfirmPasswordShow] = useState(false);
    const [signUpUsername, setSignUpUsername] = useState("");

    const [signUpVerification, setSignUpVerification] = useState(['', '', '', '', '', '']);

    const [forgotPasswordUsername, setForgotPasswordUsername] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [newPasswordInfoShow, setNewPasswordInfoShow] = useState(false);
    const [newPasswordConditions, setNewPasswordConditions] = useState([false, false, false, false, false]);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);
    const [newPasswordVerification, setNewPasswordVerification] = useState(['', '', '', '', '', '']);
    const newPasswordFocus = useRef(false);

    const [requiredNewPassword, setRequiredNewPassword] = useState("");
    const [requiredConfirmNewPassword, setRequiredConfirmNewPassword] = useState("");
    const [requiredNewPasswordInfoShow, setRequiredNewPasswordInfoShow] = useState(false);
    const [requiredNewPasswordConditions, setRequiredNewPasswordConditions] = useState([false, false, false, false, false]);
    const [requiredNewPasswordShow, setRequiredNewPasswordShow] = useState(false);
    const [requiredConfirmNewPasswordShow, setRequiredConfirmNewPasswordShow] = useState(false);

    const [submitLoginLoading, setSubmitLoginLoading] = useState(false);
    const [submitLoginVerificationLoading, setSubmitLoginVerificationLoading] = useState(false);
    const [submitSignUpLoading, setSubmitSignUpLoading] = useState(false);
    const [submitSignUpVerificationLoading, setSubmitSignUpVerificationLoading] = useState(false);
    const [submitForgotPasswordLoading, setSubmitForgotPasswordLoading] = useState(false);
    const [submitNewPasswordLoading, setSubmitNewPasswordLoading] = useState(false);
    const [submitNewPasswordRequiredLoading, setSubmitNewPasswordRequiredLoading] = useState(false);
    
    const authSignIn = async (username, password) => {//genel giriş yapma işlemleri
        try {
            const userInfo = await Auth.signIn(username, password);

            setLoginUser(userInfo);

            if(userInfo.challengeName === 'SMS_MFA'){
                setFormType("verificationLogin");
            }
            else if(userInfo.challengeName === 'NEW_PASSWORD_REQUIRED'){
                setFormType("newPasswordRequired");
            }
            else if(userInfo.challengeName){
                setFormType("supportPage");
            }
        } catch (err) {
            let errMessage = "An error occurred!";
            
            if (err.code === 'UserNotConfirmedException') {
                try {
                    //kod gönderildi, onaylama ekranı için bilgileri güncelliyoruz
                    await Auth.resendSignUp(username);
                    setSignUpUsername(username);
                    setSignUpEmail(username);
                    setSignUpPassword(password);
                    setFormType("verificationSignUp");
                } catch (error) {
                    setResultMessage("Your account needs to be verified but something went wrong. Please try again!");
                    setResultMessageType("danger");
                }
            } else if (err.code === 'PasswordResetRequiredException') {
                try {
                    //kod gönderilerek yeni şifre alanına geçiş yapıldı, kodu istediği username bilgisini de güncelledik
                    await Auth.forgotPassword(username);
                    setForgotPasswordUsername(username);
                    setFormType("newPassword");
                }
                catch (error) {
                    setResultMessage(error.message);
                    setResultMessageType("danger");
                }
            } else if (err.code === 'NotAuthorizedException') {
                errMessage = "Temporary password has expired, please contact with support!";
            } else if (err.code === 'UserNotFoundException') {
                errMessage = "Login information is incorrect!";
            } else if (err.code === 'InvalidParameterException') {
                errMessage = "Login information is incorrect, please check the login parameters!";
            } else {
                errMessage = err.message;
            }
            setResultMessage(errMessage);
            setResultMessageType("danger");
        } finally {
            setSubmitLoginLoading(false);
        }
    }

    const loginFunction = async () => {//giriş yapma formlarının kullandığı fonksiyon
        if(formType == "login"){
            setSubmitLoginLoading(true);
            if(loginEmail != "" && loginPassword != ""){
                authSignIn(loginEmail, loginPassword);
            }
            else{
                if(loginEmail == "" && loginPassword != ""){
                    setResultMessage("Enter your Email to login!");
                    setResultMessageType("danger");
                }
                else if(loginEmail != "" && loginPassword == ""){
                    setResultMessage("Enter your Password to login!");
                    setResultMessageType("danger");
                }
                else{
                    setResultMessage("Enter your Email and Password to login!");
                    setResultMessageType("danger");
                }
                setSubmitLoginLoading(false);
            }
        }
        else if(formType == "verificationLogin"){
            setSubmitLoginVerificationLoading(true);
            if(loginUser != "" && loginVerification.join('') != "" && loginVerification.join('') != null){
                try {
                    await Auth.confirmSignIn(loginUser, loginVerification.join(''));
                }
                catch (error) {
                    setResultMessage(`Error verifying login: ${error.message}`);
                    setResultMessageType("danger");
                } finally {
                    setSubmitLoginVerificationLoading(false);
                }
            }
            else{
                if(signUpVerification.join('') == "" || signUpVerification.join('') == null){
                    setResultMessage("Enter your code to login!");
                    setResultMessageType("danger");
                }
                else{
                    setResultMessage("There was a problem with verification. Please try logging in again.");
                    setResultMessageType("danger");
                }
                setSubmitLoginVerificationLoading(false);
            }
        }
    };

    const singUpFunction = async () => {//kayıt olma formlarının kullandığı fonksiyon
        if(formType == "verificationSignUp"){
            setSubmitSignUpVerificationLoading(true);
            if(signUpUsername != "" && signUpVerification.join('') != "" && signUpVerification.join('') != null){
                try {
                    await Auth.confirmSignUp(signUpUsername, signUpVerification.join(''));
                    authSignIn(signUpEmail, signUpPassword);
                }
                catch (error) {
                    setResultMessage(`Error verifying email: ${error.message}`);
                    setResultMessageType("danger");
                } finally {
                    setSubmitSignUpVerificationLoading(false);
                }
            }
            else{
                if(signUpVerification.join('') == "" || signUpVerification.join('') == null){
                    setResultMessage("Enter your code to confirm!");
                    setResultMessageType("danger");
                }
                else{
                    setResultMessage("There was a problem with the verification. Please try logging in or registering again.");
                    setResultMessageType("danger");
                }
                setSubmitSignUpVerificationLoading(false);
            }
        }
    };

    const changePasswordFunction = async () => {//şifre değiştirme formlarının kullandığı fonksiyon
        if(formType == "forgotPassword") {
            setSubmitForgotPasswordLoading(true);
            if(forgotPasswordUsername != ""){
                try {
                    await Auth.forgotPassword(forgotPasswordUsername);
                    setFormType("newPassword");
                }
                catch (error) {
                    setResultMessage(error.message);
                    setResultMessageType("danger");
                } finally {
                    setSubmitForgotPasswordLoading(false);
                }
            }
            else{
                setResultMessage("Enter your Username to reset password!");
                setResultMessageType("danger");
                setSubmitForgotPasswordLoading(false);
            }
        }
        else if(formType == "newPassword"){
            setSubmitNewPasswordLoading(true);
            if(forgotPasswordUsername != "" && newPassword != "" && confirmNewPassword != "" && newPassword == confirmNewPassword && !newPasswordConditions.includes(false) && newPasswordVerification.join('') != "" && newPasswordVerification.join('') != null){
                try {
                    await Auth.forgotPasswordSubmit(forgotPasswordUsername, newPasswordVerification.join(''), newPassword);

                    setResultMessage("Your password has been changed successfully!");
                    setResultMessageType("success");
                    setFormType("login");
                }
                catch (error) {
                    setResultMessage(`Error change password: ${error.message}`);
                    setResultMessageType("danger");
                } finally {
                    setSubmitNewPasswordLoading(false);
                }
            }
            else{
                if(newPasswordVerification.join('') == "" || newPasswordVerification.join('') == null){
                    setResultMessage("Enter your code to change password!");
                    setResultMessageType("danger");
                }
                else if(newPasswordConditions.includes(false)){
                    setResultMessage("Check the password you entered to change your password!");
                    setResultMessageType("danger");
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                    setNewPasswordInfoShow(true);
                }
                else if(newPassword != confirmNewPassword){
                    setResultMessage("New Password and Confirm New Password are not the same, please check!");
                    setResultMessageType("danger");
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                }
                else{
                    setResultMessage("There was a problem changing the password. Please try resetting the password again.");
                    setResultMessageType("danger");
                }
                setSubmitNewPasswordLoading(false);
            }
        }
        else if(formType == "newPasswordRequired"){
            setSubmitNewPasswordRequiredLoading(true);
            if(loginUser && requiredNewPassword != "" && requiredConfirmNewPassword != "" && requiredNewPassword == requiredConfirmNewPassword && !requiredNewPasswordConditions.includes(false)){
                try {
                    await Auth.completeNewPassword(loginUser, requiredNewPassword);
                    setResultMessage("Your password has been changed successfully!");
                    setResultMessageType("success");
                }
                catch (error) {
                    setResultMessage(`Error change password: ${error.message}`);
                    setResultMessageType("danger");
                } finally {
                    setSubmitNewPasswordRequiredLoading(false);
                }
            }
            else{
                if(requiredNewPasswordConditions.includes(false)){
                    setResultMessage("Check the password you entered to change your password!");
                    setResultMessageType("danger");
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                    setRequiredNewPasswordInfoShow(true);
                }
                else if(requiredNewPassword != requiredConfirmNewPassword){
                    setResultMessage("New Password and Confirm New Password are not the same, please check!");
                    setResultMessageType("danger");
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                }
                else{
                    setResultMessage("There was a problem changing the password. Please try resetting the password again.");
                    setResultMessageType("danger");
                }
                setSubmitNewPasswordRequiredLoading(false);
            }
        }
    };

    const resendCode = async () => {//newPassword formundayken yeni kod alabilmek için
        if(formType == "verificationLogin") {
            if(loginEmail != "" && loginPassword != ""){
                authSignIn(loginEmail, loginPassword);
                setResultMessage("Verification code has been sent successfully, please check!");
                setResultMessageType("success");
            }
            else{
                setResultMessage("An error occurred, please start over.");
                setResultMessageType("danger");
            }
        }
        else if(formType == "newPassword") {
            if(forgotPasswordUsername != ""){
                try {
                    await Auth.forgotPassword(forgotPasswordUsername);
                    setResultMessage("Verification code has been sent successfully, please check!");
                    setResultMessageType("success");
                }
                catch (error) {
                    setResultMessage(error.message);
                    setResultMessageType("danger");
                }
            }
            else{
                setResultMessage("An error occurred, please start over.");
                setResultMessageType("danger");
            }
        }
        else if(formType == "verificationSignUp"){
            try {
                if(signUpUsername != ""){
                    await Auth.resendSignUp(signUpUsername);
                    setResultMessage("Verification code has been sent successfully, please check!");
                    setResultMessageType("success");
                }
                else{
                    setResultMessage("An error occurred, please start over.");
                    setResultMessageType("danger");
                }
            } catch (error) {
                setResultMessage("Your account needs to be verified but something went wrong. Please try again!");
                setResultMessageType("danger");
            }
        }
    }

    const CreateResultMessage = ({ verification }) => {//formlarda gözüken bilgi mesajı alanı
        return (
            <div className={`result-message-area-main${verification}`}>
                <div className={`result-message-area ${resultMessageType}`} ref={resultMessageArea} style={resultMessage == "" ? {visibility: "hidden"} : undefined}>
                    <div>
                        <i className="fa-solid fa-circle-check success"></i>
                        <i className="fa-solid fa-circle-xmark danger"></i>
                        <span>{resultMessage}</span>
                    </div>
                    <i className="fa-solid fa-xmark close" onClick={() => setResultMessage("")}></i>
                </div>
            </div>
        );
    }

    const controlPassword = (password) => {//şifre kriterlerini kontrol eden genel fonksiyon
        // Uzunluk kontrolü (min 8, max 64 karakter)
        const min8Max64 = password.length >= 8 && password.length <= 64;
                
        // En az bir sayı kontrolü
        const hasNumber = /\d/.test(password);

        // En az bir büyük harf kontrolü
        const hasUppercase = /[A-Z]/.test(password);

        // En az bir küçük harf kontrolü
        const hasLowercase = /[a-z]/.test(password);

        // Özel karakter kontrolü
        const specialCharactersPattern = /[\^\$\*\.\[\]\{\}\(\)\?\-\"!@#%&\/\\,><':;\|_~`+=]/;
        const hasSpecialCharacter = specialCharactersPattern.test(password);

        return [min8Max64, hasNumber, hasSpecialCharacter, hasUppercase, hasLowercase];
    }
    
    useEffect(() => {//dark mod ile ilgili durumu algılamak için kullanıyoruz
        // Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setDarkMode(document.body.classList.contains("dark"))
                    if(document.body.classList.contains("dark")){
                        localStorage.setItem("darkMode", "true");
                    }
                    else{
                        localStorage.setItem("darkMode", "false");
                    }
                }
            }
        };
    
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });
    
        // Component temizlenirken observer'ı durdurma
        return () => observer.disconnect();
    }, []);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor. animasyon sağlıyoruz, bilgileri temizliyoruz ve verification kodunun yapıştırılmasını algılıyoruz.
        loginInfo.current.style.animation = "none";
        let tempForAnimation = loginInfo.current.offsetHeight;
        loginInfo.current.style.animation = "open-animation 1.5s";

        if(formType === "login"){
            setLoginEmail("");
            setLoginPassword("");
            setLoginPasswordShow(false);
            setLoginUser("");
            if(resultMessage != "Your password has been changed successfully!"){
                setResultMessage("");
            }
        }
        else if(formType === "verificationLogin"){
            setLoginVerification(['', '', '', '', '', '']);
            if(resultMessage != "Account confirmed successfully!"){
                setResultMessage("");
            }
        }
        else if(formType === "verificationSignUp"){
            setSignUpVerification(['', '', '', '', '', '']);
            if(resultMessage != "You have successfully registered!"){
                setResultMessage("");
            }
        }
        else if(formType === "forgotPassword"){
            setForgotPasswordUsername("");
            if(resultMessage != ""){
                setResultMessage("");
            }
        }
        else if(formType === "newPassword"){
            setNewPassword("");
            setConfirmNewPassword("");
            setNewPasswordInfoShow(false);
            setNewPasswordConditions([false, false, false, false, false]);
            setNewPasswordShow(false);
            setConfirmNewPasswordShow(false);
            setNewPasswordVerification(['', '', '', '', '', '']);
            newPasswordFocus.current = false;
            if(resultMessage != ""){
                setResultMessage("");
            }
        }
        else if(formType === "newPasswordRequired"){
            setRequiredNewPassword("");
            setRequiredConfirmNewPassword("");
            setRequiredNewPasswordInfoShow(false);
            setRequiredNewPasswordConditions([false, false, false, false, false]);
            setRequiredNewPasswordShow(false);
            setRequiredConfirmNewPasswordShow(false);
            if(resultMessage != ""){
                setResultMessage("");
            }
        }
        
        const handlePaste = (e) => {
            let pasteSuccess = true;

            if(formType == "verificationLogin"){
                if(!pasteVerification(e, setLoginVerification)){
                    pasteSuccess = false;
                }
            }
            else if(formType == "verificationSignUp"){
                if(!pasteVerification(e, setSignUpVerification)){
                    pasteSuccess = false;
                }
            }
            else if(formType == "newPassword" && newPasswordFocus.current != true){
                if(!pasteVerification(e, setNewPasswordVerification)){
                    pasteSuccess = false;
                }
            }

            if(!pasteSuccess){
                setResultMessage("Verification code must be 6 digits, please check the code you pasted!");
                setResultMessageType("danger");
            }
            else{
                setResultMessage("");
            }
        }
        window.addEventListener('paste', handlePaste);
        return () => window.removeEventListener('paste', handlePaste);
    }, [formType]);

    useEffect(() => {//bilgi mesajının gösterilme animasyonu ya da gizlenmesi
        if(resultMessageArea.current){
            if(resultMessage != ""){
                resultMessageArea.current.style.animation = "none";
                resultMessageArea.current.style.visibility = "visible";
                resultMessageArea.current.style.animation = "open-animation 1s";
            }
            else{
                resultMessageArea.current.style.visibility = "hidden";
            }
        }
    }, [resultMessage]);

    useEffect(() => {//kayıt olunan eposta kontrolü
        const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
        const isValid = emailPattern.test(signUpEmail) && signUpEmail.length <= 128;
        setSignUpEmailValid(isValid);
    }, [signUpEmail]);

    useEffect(() => {//şifre bilgilerinin kriterleri kontrol ediliyor
        if(!firstProcess1){
            setFirstProcess1(true);
        }
        else{
            if(formType == "newPassword"){
                const tempConditions = controlPassword(newPassword);
                setNewPasswordConditions(tempConditions);
        
                if(tempConditions.includes(false) || newPassword != confirmNewPassword){
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                }
                else{
                    passwordInput.current.classList.remove("danger");
                    confirmPasswordInput.current.classList.remove("danger");
                    setNewPasswordInfoShow(false);
                }
            }
            else if(formType == "newPasswordRequired"){
                const tempConditions = controlPassword(requiredNewPassword);
                setRequiredNewPasswordConditions(tempConditions);
        
                if(tempConditions.includes(false) || requiredNewPassword != requiredConfirmNewPassword){
                    passwordInput.current.classList.add("danger");
                    confirmPasswordInput.current.classList.add("danger");
                }
                else{
                    passwordInput.current.classList.remove("danger");
                    confirmPasswordInput.current.classList.remove("danger");
                    setRequiredNewPasswordInfoShow(false);
                }
            }
        }
    }, [signUpPassword, signUpConfirmPassword, newPassword, confirmNewPassword, requiredNewPassword, requiredConfirmNewPassword]);
    
    return(
        <div className="section-login">
            <div ref={loginInfo} className={`login-info ${formType == "verificationSignUp" || formType == "verificationLogin" || formType == "forgotPassword" || formType == "newPassword" || formType == "supportPage" ? "back-to" : ""}`}>
                {formType == "login" && <>
                    <Link to="/" className="back-to" style={{visibility: "hidden"}}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Dashboard
                    </Link>
                    <div className="title">
                        <span>Login</span>
                        <p>Enter your email or customer id and password to login!</p>
                    </div>
                    <CreateResultMessage verification="" />
                    <div className="inputs">
                        <label>Email or Customer Id</label>
                        <input type="text" placeholder="Email or Customer Id" onChange={(event) => setLoginEmail(event.target.value)} />
                        <label>Password</label>
                        <div className="password-show-hide" style={loginPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setLoginPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide" style={loginPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setLoginPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={loginPasswordShow ? "text" : "password"} placeholder="Password" onChange={(event) => setLoginPassword(event.target.value)} />
                    </div>
                    <div className="inputs-extra">
                        <a onClick={() => setFormType("forgotPassword")}>Forgot Password?</a>
                    </div>
                    <button type="button" onClick={() => loginFunction()} disabled={submitLoginLoading}>{submitLoginLoading ? <Loading /> : "Login"}</button>
                </>}
                {formType == "verificationSignUp" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Verification</span>
                        <p>Please check your email for the verification code!</p>
                    </div>
                    <CreateResultMessage verification=" verification" />
                    <div className="inputs verification">
                        {signUpVerification.map((code, index) => (
                            <input
                                key={index}
                                type="text"
                                placeholder="-"
                                value={code}
                                onChange={(e) => verificationOnChange(e, index, signUpVerification, setSignUpVerification)}
                                onKeyDown={(e) => verificationOnKeyDown(e, index, setSignUpVerification)}
                                autoComplete="off"
                                name={`unique-name-${index}`}
                            />
                        ))}
                    </div>
                    <button type="button" className="verification" onClick={() => singUpFunction()} disabled={submitSignUpVerificationLoading}>{submitSignUpVerificationLoading ? <Loading /> : "Verify"}</button>
                    <div className="register">
                        <a onClick={() => resendCode()}>Resend Code</a>
                    </div>
                </>}
                {formType == "verificationLogin" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Verification</span>
                        <p>Please check your phone for the verification code!</p>
                    </div>
                    <CreateResultMessage verification=" verification" />
                    <div className="inputs verification">
                        {loginVerification.map((code, index) => (
                            <input
                                key={index}
                                type="text"
                                placeholder="-"
                                value={code}
                                onChange={(e) => verificationOnChange(e, index, loginVerification, setLoginVerification)}
                                onKeyDown={(e) => verificationOnKeyDown(e, index, setLoginVerification)}
                                autoComplete="off"
                                name={`unique-name-${index}`}
                            />
                        ))}
                    </div>
                    <button type="button" className="verification" onClick={() => loginFunction()} disabled={submitLoginVerificationLoading}>{submitLoginVerificationLoading ? <Loading /> : "Verify"}</button>
                    <div className="register">
                        <a onClick={() => resendCode()}>Resend Code</a>
                    </div>
                </>}
                {formType == "forgotPassword" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Reset Password</span>
                        <p>Enter your email or customer id to reset your password!</p>
                    </div>
                    <CreateResultMessage verification="" />
                    <div className="inputs">
                        <label>Email or Customer Id</label>
                        <input type="text" placeholder="Email or Customer Id" autoComplete="new-password" onChange={(event) => setForgotPasswordUsername(event.target.value)} />
                    </div>
                    <button type="button" onClick={() => changePasswordFunction()} disabled={submitForgotPasswordLoading}>{submitForgotPasswordLoading ? <Loading /> : "Send Reset Code"}</button>
                </>}
                {formType == "newPassword" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Change Password</span>
                        <p>Please check your email for the verification code and enter your new password!</p>
                    </div>
                    <CreateResultMessage verification="" />
                    <div className="inputs verification">
                        {newPasswordVerification.map((code, index) => (
                            <input
                                key={index}
                                type="text"
                                placeholder="-"
                                value={code}
                                onChange={(e) => verificationOnChange(e, index, newPasswordVerification, setNewPasswordVerification)}
                                onKeyDown={(e) => verificationOnKeyDown(e, index, setNewPasswordVerification)}
                                autoComplete="off"
                                name={`unique-name-${index}`}
                            />
                        ))}
                    </div>
                    <div className="inputs new-password">
                        <label>New Password</label>
                        <i className="fa-solid fa-circle-info password-info" onMouseEnter={() => setNewPasswordInfoShow(true)}></i>
                        <div className="password-info-area" style={newPasswordInfoShow ? undefined : {display: "none"}}>
                            <i className="fa-solid fa-circle-xmark close" onClick={() => setNewPasswordInfoShow(false)}></i>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[0] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[0] ? undefined : {display: "none"}}></i>
                                <span>Minimum 8, Maximum 64 characters</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[1] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[1] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 number</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[2] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[2] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 special character</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[3] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[3] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 uppercase letter</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[4] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[4] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 lowercase letter</span>
                            </div>
                        </div>
                        <div className="password-show-hide first" style={newPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide first" style={newPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={newPasswordShow ? "text" : "password"} ref={passwordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" onChange={(event) => setNewPassword(event.target.value)} onFocus={() => newPasswordFocus.current = true} onBlur={() => newPasswordFocus.current = false} />
                        <label>Confirm New Password</label>
                        <div className="password-show-hide" style={confirmNewPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setConfirmNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide" style={confirmNewPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setConfirmNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={confirmNewPasswordShow ? "text" : "password"} ref={confirmPasswordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" onChange={(event) => setConfirmNewPassword(event.target.value)} onFocus={() => newPasswordFocus.current = true} onBlur={() => newPasswordFocus.current = false} />
                    </div>
                    <button type="button" onClick={() => changePasswordFunction()} disabled={submitNewPasswordLoading}>{submitNewPasswordLoading ? <Loading /> : "Change Password"}</button>
                    <div className="register">
                        <a onClick={() => resendCode()}>Resend Code</a>
                    </div>
                </>}
                {formType == "newPasswordRequired" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Change Password</span>
                        <p>Please enter your new password!</p>
                    </div>
                    <CreateResultMessage verification="" />
                    <div className="inputs new-password">
                        <label>New Password</label>
                        <i className="fa-solid fa-circle-info password-info" onMouseEnter={() => setRequiredNewPasswordInfoShow(true)}></i>
                        <div className="password-info-area" style={requiredNewPasswordInfoShow ? undefined : {display: "none"}}>
                            <i className="fa-solid fa-circle-xmark close" onClick={() => setRequiredNewPasswordInfoShow(false)}></i>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={requiredNewPasswordConditions[0] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={requiredNewPasswordConditions[0] ? undefined : {display: "none"}}></i>
                                <span>Minimum 8, Maximum 64 characters</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={requiredNewPasswordConditions[1] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={requiredNewPasswordConditions[1] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 number</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={requiredNewPasswordConditions[2] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={requiredNewPasswordConditions[2] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 special character</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={requiredNewPasswordConditions[3] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={requiredNewPasswordConditions[3] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 uppercase letter</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={requiredNewPasswordConditions[4] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={requiredNewPasswordConditions[4] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 lowercase letter</span>
                            </div>
                        </div>
                        <div className="password-show-hide first" style={requiredNewPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setRequiredNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide first" style={requiredNewPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setRequiredNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={requiredNewPasswordShow ? "text" : "password"} ref={passwordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" onChange={(event) => setRequiredNewPassword(event.target.value)} />
                        <label>Confirm New Password</label>
                        <div className="password-show-hide" style={requiredConfirmNewPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setRequiredConfirmNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide" style={requiredConfirmNewPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setRequiredConfirmNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={requiredConfirmNewPasswordShow ? "text" : "password"} ref={confirmPasswordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" onChange={(event) => setRequiredConfirmNewPassword(event.target.value)} />
                    </div>
                    <button type="button" onClick={() => changePasswordFunction()} disabled={submitNewPasswordRequiredLoading}>{submitNewPasswordRequiredLoading ? <Loading /> : "Change Password"}</button>
                </>}
                {formType == "supportPage" && <>
                    <a className="back-to" onClick={() => setFormType("login")}>
                        <i className="fa-solid fa-chevron-left"></i>Back to Login
                    </a>
                    <div className="title">
                        <span>Error</span>
                        <p>Please contact our support team!</p>
                    </div>
                    <button type="button">Contact</button>
                </>}
                <i className='bx bxs-sun darkmode darkmode2' style={darkMode ? undefined : {display: 'none'}} onClick={() => document.body.classList.toggle("dark")}></i>
                <i className='bx bxs-moon darkmode darkmode2' style={darkMode ? {display: 'none'} : undefined} onClick={() => document.body.classList.toggle("dark")}></i>
            </div>
            <div className="login-logo">
                <div className="background"></div>
                <img src={logo} />
                <i className='bx bxs-sun darkmode' style={darkMode ? undefined : {display: 'none'}} onClick={() => document.body.classList.toggle("dark")}></i>
                <i className='bx bxs-moon darkmode' style={darkMode ? {display: 'none'} : undefined} onClick={() => document.body.classList.toggle("dark")}></i>
            </div>
        </div>
    )

}

export default Login;