import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector } from "react-redux";
import { onChangeDepositType } from "../Reducer/kpriceReducer";
import { useDispatch } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import withLoadingData from "../Container/withLoadingData";
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import Popup_DepositNetworkAddressesDetails from "./Popup_DepositNetworkAddressesDetails";

const Deposit = ({ isDepositLoading }) => {

    const currentDepositType = useSelector((state) => state.kprice.depositType);
    const depositData = useSelector((state) => state.kprice.deposit.data);
    
    const dispatch = useDispatch();
    const [viewType, setViewType] = useState("pc");
    const [activeType, setActiveType] = useState("all");//seçili type değeri
    const [typeSelectDisplay, setTypeSelectDisplay] = useState(false);//type select menü görünürlüğü
    const typeSelect = useRef(null);//type select'i
    const typeSelectMenu = useRef(null);//type select menüsü
    const tableArea = useRef(null);//tablo alanı
    const isFirstRenderForTime = useRef(true);

    const [selectedAccountName, setSelectedAccountName] = useState(null);

    useEffect(() => {//type güncellenince çalışacak
        if (isFirstRenderForTime.current) {
            isFirstRenderForTime.current = false;
            return;
        }
        const newType = activeType;
        dispatch(onChangeDepositType({ newType }));
    }, [activeType]);

    useEffect(() => {//animasyon için - type değiştikçe tetiklenecek
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }
    }, [activeType]);

    useEffect(() => {//sayfaya girişte güncel type bilgisi alınıyor
        if(currentDepositType != activeType){
            setActiveType(currentDepositType);
        }
    }, [currentDepositType]);

    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const DepositNetworkAddressesDetailsButton = ({ account_name }) => {
        return (
            <>
                <div className="td-text-with-button account-name">{account_name}</div>
                <Tooltip title="Deposit Network Addresses Details" className="detail-button no-padding" placement="right" onClick={() => setSelectedAccountName(account_name)}>
                    <div>
                        <i className="fa-solid fa-address-card"></i>
                    </div>
                </Tooltip>
            </>
        );
    }

    const AccountInfoMobile = ({ item }) => {
        return (
            <div className="mobile-info">
                <label>Account Name: <span>{item.account_name ? <DepositNetworkAddressesDetailsButton account_name={item.account_name} /> : ""}</span></label>
                <label>Assignment Date: <span>{item.assignment_date ? item.assignment_date : ""}</span></label>
                <label>Adding Date: <span>{item.adding_date ? item.adding_date : ""}</span></label>
            </div>
        );
    }

    const UsingStatusMobile = ({ item }) => {
        return (
            <div className="mobile-info">
                <label>Using Status: <span>{item.using_status ? (item.using_status == "empty_account" ? "Empty Account" : (item.using_status == "full_account" ? "Full Account" : item.using_status)) : ""}</span></label>
                <label>Customer ID: <span>{item.customer_id ? <ProfileButton customer_id={item.customer_id} /> : ""}</span></label>
            </div>
        );
    }

    const tableColumns = useMemo(() => [//deposit tablo başlıkları
        { accessorKey: 'account_name', header: 'Account Name', size: 50, Cell: ({ row }) => row.original.accountNameForTable },
        { accessorKey: 'using_status', header: 'Using Status', size: 50 },
        { accessorKey: 'customer_id', header: 'Customer ID', size: 50, Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'assignment_date', header: 'Assignment Date', size: 50, Cell: ({ row }) => row.original.assignmentDateForTable },
        { accessorKey: 'adding_date', header: 'Adding Date', size: 50, Cell: ({ row }) => row.original.addingDateForTable },
    ], []);

    const tableData = useMemo(() => {//deposit tablo bilgileri
        if (!depositData.length || activeType == "types_counts") return [];
    
        const newData = [];
        depositData.map((item, index) => {
            newData.push({
                account_name: item.account_name ? item.account_name : "",
                accountNameForTable: item.account_name ? <DepositNetworkAddressesDetailsButton account_name={item.account_name} /> : "",
                using_status: item.using_status ? (item.using_status == "empty_account" ? "Empty Account" : (item.using_status == "full_account" ? "Full Account" : item.using_status)) : "",
                customer_id: item.customer_id ? item.customer_id : 0,
                customerIdForTable: item.customer_id ? <ProfileButton customer_id={item.customer_id} /> : "",
                assignment_date: item.assignment_time ? item.assignment_time : 0,
                assignmentDateForTable: item.assignment_date ? item.assignment_date : "",
                adding_date: item.adding_timestamp ? item.adding_timestamp : 0,
                addingDateForTable: item.adding_date ? item.adding_date : "",
            });
        });
        
        return newData;
    }, [depositData, activeType]);

    const tableColumnsMobile = useMemo(() => [//deposit tablo başlıkları
        { accessorKey: 'account_info', header: 'Account Info', size: 50, Cell: ({ row }) => row.original.accountInfoForTable },
        { accessorKey: 'using_status', header: 'Using Status', size: 50, Cell: ({ row }) => row.original.usingStatusForTable },
    ], []);

    const tableDataMobile = useMemo(() => {//deposit tablo bilgileri
        if (!depositData.length || activeType == "types_counts") return [];
    
        const newData = [];
        depositData.map((item, index) => {
            newData.push({
                account_info: item.assignment_time ? item.assignment_time : 0,
                accountInfoForTable: <AccountInfoMobile item={item} />,
                using_status: item.customer_id ? item.customer_id : 0,
                usingStatusForTable: <UsingStatusMobile item={item} />,
            });
        });
        
        return newData;
    }, [depositData, activeType]);

    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 800){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);

        //closed orders time select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
          window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//type select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (typeSelect.current && !typeSelect.current.contains(e.target) && typeSelectMenu.current && !typeSelectMenu.current.contains(e.target)) {
            setTypeSelectDisplay(false);
        }
    };

    return (
        <div className="main">
            <Popup_DepositNetworkAddressesDetails selectedCustomerId={null} setSelectedCustomerId={null} selectedAccountName={selectedAccountName} setSelectedAccountName={setSelectedAccountName} type="User and Addresses Details" />
            <div className="allsections allsections-border table-area">
                <div className="table-select">
                    <div className="title" ref={typeSelect} onClick={() => {if(typeSelectDisplay){setTypeSelectDisplay(false)}else{setTypeSelectDisplay(true)}}}>
                        {activeType == "all" ? "All" : (activeType == "all_assignment" ? "All Assignment" : (activeType == "types_counts" ? "Types Counts" : ""))}
                        <i className="fa-solid fa-caret-up arrow-up" style={typeSelectDisplay ? {} : {display: "none"}}></i>
                        <i className="fa-solid fa-caret-down" style={typeSelectDisplay ? {display: "none"} : {}}></i>
                    </div>
                    <div className="menu" ref={typeSelectMenu} style={typeSelectDisplay ? {display: "block"} : {display: "none"}}>
                        <label className={activeType == "all" ? "active" : undefined} onClick={() => {setActiveType("all");setTypeSelectDisplay(false);}}>All</label>
                        <label className={activeType == "all_assignment" ? "active" : undefined} onClick={() => {setActiveType("all_assignment");setTypeSelectDisplay(false);}}>All Assignment</label>
                        <label className={activeType == "types_counts" ? "active" : undefined} onClick={() => {setActiveType("types_counts");setTypeSelectDisplay(false);}}>Types Counts</label>
                    </div>
                </div>
                <div ref={tableArea} className={isDepositLoading != false ? "list loading" : "list"}>
                    {isDepositLoading != false ?
                    <Loading /> :
                    <>
                        {activeType == "all" || activeType == "all_assignment" ?
                            viewType == "pc" ? 
                                <div className="column7">
                                    <MaterialReactTable columns={tableColumns} data={tableData} initialState={{sorting: [{id: 'assignment_date', desc: true}]}} />
                                </div> : 
                                <div className="column2">
                                    <MaterialReactTable columns={tableColumnsMobile} data={tableDataMobile} initialState={{sorting: [{id: 'account_info', desc: true}]}} />
                                </div>
                            : 
                                <div className="total-info">
                                    <div className="item">
                                        <label>Total Empty Accounts</label>
                                        <span>{depositData && depositData.total_empty_accounts ? depositData.total_empty_accounts : 0}</span>
                                    </div>
                                    <div className="item">
                                        <label>Total Full Accounts</label>
                                        <span>{depositData && depositData.total_full_accounts ? depositData.total_full_accounts : 0}</span>
                                    </div>
                                </div>
                        }
                    </>
                    }
                </div>
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(Deposit, isEqual));