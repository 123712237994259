import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Tooltip from '@mui/material/Tooltip';
import "./Styles/page_withdraw.css";
import { getValueWithShowPlaces, getMinAndStepWithShowPlaces, valueControlWithDigitLimit } from '../Lib/calculationFunctions';

const Deposit = () => {

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const QuickSearchOptions = ["BTC", "USDT", "USDC", "BNB", "ETH"];
    
    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customerWalletInfo, setCustomerWalletInfo] = useState(null);
    const [depositData, setDepositData] = useState(null);
    
    const [findCustomerId, setFindCustomerId] = useState(null);
    const [customerExist, setCustomerExist] = useState(false);
    const [customerExistCustomerId, setCustomerExistCustomerId] = useState(null);
    const [findCustomerIdLoading, setFindCustomerIdLoading] = useState(false);

    const [coinList, setCoinList] = useState([]);//select coin listesi
    const [coinListSearch, setCoinListSearch] = useState("");//select coin listesindeki search input değeri
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const currencyMaxBeforePlaces = useSelector((state) => state.kprice.currencyMaxBeforePlaces);

    const [selectedDepositType, setSelectedDepositType] = useState("crypto_address");//select Deposit type değeri
    const [displaySelectDepositType, setDisplaySelectDepositType] = useState(false);//select Deposit type görünürlüğü
    
    const [selectedCoin, setSelectedCoin] = useState(null);//select coin değeri
    const [displaySelectCoin, setDisplaySelectCoin] = useState(false);//select coin görünürlüğü
    const [selectedCoinMax, setSelectedCoinMax] = useState(0);//selected coin balance değeri
    const [selectedCoinShowPlaces, setSelectedCoinShowPlaces] = useState(null);//selected coin show places değeri

    const [transactionIdValue, setTransactionIdValue] = useState("");//girilen transaction id değeri
    const [transactionIdIsValid, setTransactionIdIsValid] = useState(false);//transaction id değerinin geçerliliği
    const [extraInfoValue, setExtraInfoValue] = useState("");//girilen extra info değeri
    const [extraInfoIsValid, setExtraInfoIsValid] = useState(false);//extra info değerinin geçerliliği
    const [amountValue, setAmountValue] = useState("");//girilen amount değeri
    const [amountValueError2, setAmountValueError2] = useState(true);//amount hatası

    const [selectedNetwork, setSelectedNetwork] = useState(null);//seçili network değeri
    const [displayNetworkSelect, setDisplayNetworkSelect] = useState(false);//network select popup görünürlüğü
    
    const mainForm = useRef(null);
    const amountInput = useRef(null);
    const transactionIdInput = useRef(null);
    const extraInfoInput = useRef(null);
    const [sendRequestLoading, setSendRequestLoading] = useState(false);
    
    //select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);

    useEffect(() => {//customerId gelirse
        if(customerId){
            getCustomerInfo(customerId);
        }
    }, [customerId]);

    useEffect(() => {//animasyon için - link değiştikçe tetiklenecek
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }
    }, [location]);

    useEffect(() => {//customer_id bilgisi varsa çekiliyor
        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
        }
        else{
            setCustomerId(null);
        }
    }, [query]);

    useEffect(() => {
        if(customerId && customerInfo && customerInfo != "User not found"){
            if(depositData == null){
                getDepositData();
            }
            if(customerWalletInfo == null){
                getCustomerWalletInfo();
            }
        }
        else if(findCustomerId && customerInfo == "User not found"){
            toast.error("User not found!");
        }
    }, [customerInfo, customerWalletInfo]);

    useEffect(() => {
        if(customerExist && customerExistCustomerId){
            navigate(`/deposit?customer_id=${customerExistCustomerId}`);
            setFindCustomerId(null);
            setCustomerExist(false);
            setCustomerExistCustomerId(null);
            setFindCustomerIdLoading(false);
        }
    }, [customerExist, customerExistCustomerId]);

    const getCustomerInfo = async (customerIdInfo) => {
        setFindCustomerIdLoading(true);
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer?customer_id=${customerIdInfo}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.customer && response.data.customer[0]){
                    let newData = response.data.customer[0];
                    newData.status_level = Number(newData.status_level);
                    newData.registration_time = Number(newData.registration_time);
                    setCustomerInfo(newData);
                    setCustomerExist(true);
                    setCustomerExistCustomerId(customerIdInfo);
                }
                else{
                    setCustomerInfo("User not found");
                }
            }
            else{
                setCustomerInfo("User not found");
            }
            setFindCustomerIdLoading(false);
        }
        catch (error) {
            setCustomerInfo("User not found");
            if(customerId && !(error && error.response && error.response.data && error.response.data.error && error.response.data.error == "Invalid User")){
                toast.error("There was a problem loading customer information!");
            }
            setFindCustomerIdLoading(false);
        }
    }

    const getCustomerWalletInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/wallet?type=user&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.wallet_items){
                    let newData = {};
                    response.data.wallet_items.forEach((item) => {
                        newData[item.currency] = {
                            currency: item.currency,
                            count: Number(item.total_count)
                        };
                    });
                    
                    setCustomerWalletInfo(newData);
                }
            }
            else{
                toast.error("There was a problem loading the customer's wallet information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the customer's wallet information!");
        }
    }

    const getDepositData = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/deposit?customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                setDepositData(response.data);
            }
            else{
                toast.error("There was a problem loading the deposit data!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the deposit data!");
        }
    }

    useEffect(() => {//select coin listelenecek seçenekler
        const newData = [];

        if(currenciesData && depositData && depositData.currency_networks){
            const coins = Object.keys(depositData.currency_networks);
            coins.forEach((item) => {
                let itemFind = true;
                let searchItem = "";

                if (coinListSearch != "") {
                    itemFind = false;
                    if(item && currenciesData && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${item} ${currenciesData[item].currency_name}`;
                    }
                    else if(item){
                        searchItem = `${item}`;
                    }
                    else if(currenciesData && item && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${currenciesData[item].currency_name}`;
                    }

                    if(searchItem != "" && searchItem.toString().toLowerCase().includes(coinListSearch.toLowerCase())){
                        itemFind = true;
                    }
                }

                if(itemFind){
                    newData.push(item);
                }
            });
        }
        
        const newDataSorted = newData.sort((a, b) => {
            // Sayısal anahtarları doğru sıralamak için ek bir kontrol
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
          
            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            }
          
            return a.localeCompare(b);
        });
        
        setCoinList(newDataSorted);
    }, [currenciesData, depositData, coinListSearch]);

    useEffect(() => {//coin için max değeri
        if(customerWalletInfo && selectedCoin && customerWalletInfo[selectedCoin] && customerWalletInfo[selectedCoin].count){
            setSelectedCoinMax(customerWalletInfo[selectedCoin].count);
        }
        else{
            setSelectedCoinMax(0);
        }
    }, [customerWalletInfo, selectedCoin]);

    useEffect(() => {//coin için show places ve current price güncel değerleri
        if(selectedCoin && currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places){
            setSelectedCoinShowPlaces(currenciesData[selectedCoin].show_places);
        }
        else{
            setSelectedCoinShowPlaces(null);
        }
    }, [currenciesData, selectedCoin]);
    
    useEffect(() => {//amount değeri kontrolleri
        const regex = /^\d+(\.\d+)?$/;

        setAmountValueError2(false);
        
        if(amountValue != ""){
            const lastAmountValue = getValueWithShowPlaces(selectedCoinShowPlaces, amountValue);
            const isRegexValid = regex.test(lastAmountValue);

            if(!isRegexValid || !valueControlWithDigitLimit(lastAmountValue, currencyMaxBeforePlaces) || Number(lastAmountValue) <= 0 || lastAmountValue == "-0"){
                amountInput.current.classList.add('danger');
                setAmountValueError2(true);
            }
            else{
                amountInput.current.classList.remove('danger');
            }
            
            setAmountValue(lastAmountValue);
        }
    }, [amountValue, selectedCoin, selectedCoinShowPlaces, currencyMaxBeforePlaces]);
    
    useEffect(() => {//network temizleme
        setSelectedNetwork(null);
    }, [selectedCoin]);

    useEffect(() => {//animasyon ve listener
        //select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target) && dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
            setDisplaySelectCoin(false);
        }
        if (dropdownRef3.current && !dropdownRef3.current.contains(e.target) && dropdownRef4.current && !dropdownRef4.current.contains(e.target)) {
            setDisplaySelectDepositType(false);
        }
    };

    const validateAndSetTransactionId = (value) => {
        const regex = /^[a-zA-Z0-9_.-]{1,512}$/;
        const currentValue = value != null ? value : transactionIdValue;

        if ((selectedDepositType == "crypto_address" || selectedDepositType == "other") && ((currentValue != "" && !regex.test(currentValue)) || (selectedDepositType == "crypto_address" && currentValue == ""))){
            if(transactionIdInput && transactionIdInput.current){
                transactionIdInput.current.classList.add('danger');
            }
            setTransactionIdIsValid(false);
        }
        else{
            if(transactionIdInput && transactionIdInput.current){
                transactionIdInput.current.classList.remove('danger');
            }
            setTransactionIdIsValid(true);
        }
    };

    const validateAndSetExtraInfo = (value) => {
        const generalPattern = /^[^'`\"“”‘’«»‹›<>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+\=\~\/\(\)]*$/;
        const noDoubleSpaceOrTrimPattern = /^(?! )(?!.*  )(?!.* $).+$/;
        const noOtherSpacesPattern = /^[^\t\n\r]*$/;
        const charLimitPattern512 = /^.{1,512}$/;

        const currentValue = value != null ? value : extraInfoValue;
        const isValid = generalPattern.test(currentValue) && noDoubleSpaceOrTrimPattern.test(currentValue) && noOtherSpacesPattern.test(currentValue) && charLimitPattern512.test(currentValue);
        
        if (!isValid && currentValue != ""){
            if(extraInfoInput && extraInfoInput.current){
                extraInfoInput.current.classList.add('danger');
            }
            setExtraInfoIsValid(false);
        }
        else{
            if(extraInfoInput && extraInfoInput.current){
                extraInfoInput.current.classList.remove('danger');
            }
            setExtraInfoIsValid(true);
        }
    };

    useEffect(() => {
        validateAndSetTransactionId(null);
        validateAndSetExtraInfo(null);
    }, [selectedDepositType]);

    const sendRequest = async () => {
        const checkCurrentCurrencyCount = selectedCoinMax;
        const lengthPattern = /^.{1,216}$/; // 1 to 216 characters
        const numberPattern = /^\d+(\.\d+)?$/; // Digits with optional decimal part

        const checkCurrentCurrencyCountIsLengthValid = lengthPattern.test(checkCurrentCurrencyCount);
        const checkCurrentCurrencyCountIsNumberValid = numberPattern.test(checkCurrentCurrencyCount);

        const checkCurrentCurrencyCountIsValid = checkCurrentCurrencyCountIsLengthValid && checkCurrentCurrencyCountIsNumberValid;
        
        if(((selectedDepositType == "crypto_address" && selectedNetwork && transactionIdValue && transactionIdIsValid) || (selectedDepositType == "other" && transactionIdIsValid) || selectedDepositType == "system_correction") && extraInfoIsValid && checkCurrentCurrencyCountIsValid && customerId && selectedCoin && amountValue && !amountValueError2 && !sendRequestLoading && customerInfo && customerInfo != "User not found" && customerInfo.deposit_permission && customerInfo.deposit_permission == "True"){
            setSendRequestLoading(true);

            let data = {
                "customer_id": customerId,
                "deposit_type": selectedDepositType,
                "check_current_currency_count": String(checkCurrentCurrencyCount),
                "currency": selectedCoin,
                "count": amountValue
            };

            if(extraInfoValue != ""){
                data.extra_info = extraInfoValue;
            }

            if(selectedDepositType == "crypto_address"){
                data.network = selectedNetwork;
                data.transaction_id = transactionIdValue;
            }
            else if(selectedDepositType == "other" && transactionIdValue != ""){
                data.transaction_id = transactionIdValue;
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            
            axios.post('https://operationapi.kpriceapp.com/deposit', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    toast.success("Deposit successful!");
                    setCustomerWalletInfo(null);
                    setCoinListSearch("");
                    setSelectedCoin(null);
                    setTransactionIdValue("");
                    setExtraInfoValue("");
                    setAmountValue("");
                    setSelectedNetwork(null);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem with your deposit, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSendRequestLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem with your deposit, please try again!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setSendRequestLoading(false);
            });
        }
    }
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-withdraw" ref={mainForm}>
                {customerId ? <>{customerInfo && customerInfo != "User not found" ? 
                <>
                    <Dialog header="Select Network" visible={displayNetworkSelect} draggable={false} resizable={false} className="section-withdraw-dialog" onHide={() => setDisplayNetworkSelect(false)}>
                        <div className="section-withdraw-dialog-body">
                            <div className="info">
                                Please note that only supported networks on Kprice platform are shown. If you deposit via another network your assets may be lost.
                            </div>
                            <div className="elements">
                                {depositData && depositData.currency_networks && depositData.currency_networks[selectedCoin] && depositData.currency_networks[selectedCoin].map((item, index) => (
                                    <div key={index} className={selectedNetwork ? (selectedNetwork == item ? "element active" : "element") : "element"} onClick={() => {setSelectedNetwork(item);setDisplayNetworkSelect(false);}}>
                                        <div className="element-left">
                                            <span>{depositData.network_types && depositData.network_types[item] && depositData.network_types[item].network_tag ? depositData.network_types[item].network_tag : undefined}</span>
                                            <span>{item}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Dialog>
                    <div className="withdraw-form">
                        <div className={`withdraw-user ${!(customerInfo && customerInfo != "User not found" && customerInfo.deposit_permission && customerInfo.deposit_permission == "True") && "no-permission"}`}>
                            <div className="withdraw-user-inner">
                                <div>
                                    <i className="fa-solid fa-user"></i>
                                    <span>{customerId ? customerId : "-"}</span>
                                    <Tooltip title="Profile" className="detail-button" placement="right">
                                        <a href={`/profile?customer_id=${customerId}`} target="_blank">
                                            <i className="fa-solid fa-user"></i>
                                        </a>
                                    </Tooltip>
                                </div>
                                {customerInfo && customerInfo != "User not found" && customerInfo.full_name && <div>{customerInfo.full_name}</div>}
                            </div>
                        </div>
                        {customerInfo && customerInfo != "User not found" && customerInfo.deposit_permission && (customerInfo.deposit_permission == "True" ? 
                        <>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Select type
                                </div>
                                <div className="withdraw-form-element-main">
                                    <div className="withdraw-form-element-main-title">Deposit Type</div>
                                    <div className="withdraw-form-element-select" ref={dropdownRef3} onClick={() => {if(displaySelectDepositType){setDisplaySelectDepositType(false)}else{setDisplaySelectDepositType(true)}}}>
                                        <div className="no-image">
                                            <span>{selectedDepositType == "crypto_address" ? "Crypto Address" : (selectedDepositType == "other" ? "Other" : (selectedDepositType == "system_correction" ? "System Correction" : ""))}</span>
                                            <label></label>
                                        </div>
                                        <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                        <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                                    </div>
                                    <div className="withdraw-form-element-select-options" ref={dropdownRef4} style={displaySelectDepositType ? {} : {display: "none"}}>
                                        <div className="items">
                                            <div className={selectedDepositType == "crypto_address" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectDepositType(false);setSelectedDepositType("crypto_address");}}>
                                                <span>Crypto Address</span>
                                                <label></label>
                                            </div>
                                            <div className={selectedDepositType == "other" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectDepositType(false);setSelectedDepositType("other");}}>
                                                <span>Other</span>
                                                <label></label>
                                            </div>
                                            <div className={selectedDepositType == "system_correction" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectDepositType(false);setSelectedDepositType("system_correction");}}>
                                                <span>System Correction</span>
                                                <label></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Select coin
                                </div>
                                <div className="withdraw-form-element-main">
                                    <div className="withdraw-form-element-main-title">Coin</div>
                                    <div className="withdraw-form-element-select" ref={dropdownRef1} onClick={() => {if(displaySelectCoin){setDisplaySelectCoin(false)}else{setDisplaySelectCoin(true)}}}>
                                        {currenciesData ? 
                                            (currenciesData[selectedCoin] ? <div>
                                                <img src={currenciesData[selectedCoin] && currenciesData[selectedCoin].picture_url ? currenciesData[selectedCoin].picture_url : undefined} />
                                                <span>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"}</span>
                                                <label>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency_name ? currenciesData[selectedCoin].currency_name : "NaN"}</label>
                                            </div> 
                                            : <span className="placeholder">Select Coin</span>)
                                        : <div className="section-withdraw-loading"><Loading /></div>}
                                        <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                        <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                                    </div>
                                    <div className="withdraw-form-element-select-options" ref={dropdownRef2} style={displaySelectCoin ? {} : {display: "none"}}>
                                        <div className="search-area">
                                            <div>
                                                <input type="text" className="search" placeholder="Search Coin" value={coinListSearch} onChange={(e) => setCoinListSearch(e.target.value)} />
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                            {QuickSearchOptions && coinList && currenciesData && 
                                                <div className="quick-search">
                                                    {QuickSearchOptions.map((item, index) => (
                                                        currenciesData[item] && 
                                                        <div key={index} className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                            <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                            <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className="items">
                                            {coinList && currenciesData ? 
                                                coinList.map((item, index) => (
                                                    currenciesData[item] && 
                                                    <div key={index} className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                        <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                        <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                        <label>{currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN"}</label>
                                                    </div>
                                                ))
                                            : <Loading />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Send to
                                </div>
                                <div className="withdraw-form-element-main">
                                    {selectedDepositType == "crypto_address" && <>
                                        <div className="withdraw-form-element-main-title">Network</div>
                                        <div className="withdraw-form-element-select noimg" onClick={() => setDisplayNetworkSelect(true)}>
                                            {selectedNetwork ? <div><span>{depositData && depositData.network_types && depositData.network_types[selectedNetwork] && depositData.network_types[selectedNetwork].network_tag ? depositData.network_types[selectedNetwork].network_tag : undefined}</span><label>{selectedNetwork}</label></div> : <span className="placeholder">Select Network</span>}
                                            <i className="fa-solid fa-caret-up" style={displayNetworkSelect ? {} : {display: "none"}}></i>
                                            <i className="fa-solid fa-caret-down" style={displayNetworkSelect ? {display: "none"} : {}}></i>
                                        </div>
                                    </>}
                                    {(selectedDepositType == "crypto_address" || selectedDepositType == "other") && <>
                                        <div className="withdraw-form-element-main-title">Transaction ID {selectedDepositType == "other" ? <span>(Optional)</span> : ""}</div>
                                        <input type="text" placeholder={selectedDepositType == "other" ? "Transaction ID (Optional)" : "Enter Transaction ID"} ref={transactionIdInput} value={transactionIdValue} onChange={(event) => {setTransactionIdValue(event.target.value);validateAndSetTransactionId(event.target.value);}} />
                                    </>}
                                    <div className="withdraw-form-element-main-title">Extra Info <span>(Optional)</span></div>
                                    <input type="text" placeholder="Extra Info (Optional)" ref={extraInfoInput} value={extraInfoValue} onChange={(event) => {setExtraInfoValue(event.target.value);validateAndSetExtraInfo(event.target.value);}} />
                                    <div className="withdraw-form-element-main-title">Amount</div>
                                    <input type="number" placeholder="Enter Amount" className="amount" step={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} min={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} value={amountValue} ref={amountInput} onChange={(event) => setAmountValue(event.target.value)} />
                                    <div className="withdraw-form-element-button">
                                        <button type="button" className={((selectedDepositType == "crypto_address" && selectedNetwork && transactionIdValue && transactionIdIsValid) || (selectedDepositType == "other" && transactionIdIsValid) || selectedDepositType == "system_correction") && extraInfoIsValid && customerId && selectedCoin && amountValue && !amountValueError2 && !sendRequestLoading && customerInfo && customerInfo != "User not found" && customerInfo.deposit_permission && customerInfo.deposit_permission == "True" ? "active" : undefined} onClick={() => sendRequest()}>{sendRequestLoading ? <Loading /> : "Deposit"}</button>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <div className="withdraw-form-element-amount-error">Customer does not have deposit permission, needs to complete verification steps!</div>)}
                    </div>
                </>
                : (customerInfo == "User not found" ? 
                    <div className="no-user">
                        <i className="fa-solid fa-user-slash"></i>
                        <div>User not found</div>
                    </div>
                    : <Loading />)}</>
                : <div className="find-customer-id">
                    <div className="withdraw-form">
                        <div className="withdraw-form-element">
                            <div className="withdraw-form-element-main">
                                <div className="withdraw-form-element-main-title">Customer ID</div>
                                <input type="text" placeholder="Customer ID" value={findCustomerId} onChange={(event) => setFindCustomerId(event.target.value)} />
                                <div className="withdraw-form-element-button">
                                    <button type="button" className={findCustomerId && !findCustomerIdLoading ? "active" : undefined} disabled={findCustomerId == null || findCustomerIdLoading} onClick={() => getCustomerInfo(findCustomerId)}>{findCustomerIdLoading ? <Loading /> : "Find Customer"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )

}

export default Deposit;