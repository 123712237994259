import Decimal from 'decimal.js';

//TEK BAŞINA ÇAĞRILAN FONKSİYONLAR - BİLGİ FORMATLAMA
// show_places ile virgül sonrası hanenin hesaplanması
const getValueWithShowPlaces = (show_places, value) => {
    let newValue = value;

    if(String(value).includes("e") || String(value).includes("-")){
        try {
            newValue = Decimal(value).toFixed();
        } catch (error) {}
    }

    let returnValue = String(newValue), splitValue = null, type = null;
    
    if(show_places != null && show_places !== "" && value != null && value !== ""){
        if(returnValue.includes(".")){
            splitValue = returnValue.split('.');
            type = ".";
        }
        else if(returnValue.includes(",")){
            splitValue = returnValue.split(',');
            type = ",";
        }

        if(splitValue){
            let tempType = Number(show_places) > 0 ? type : "";
            returnValue = splitValue[0] + tempType + splitValue[1].substring(0, Number(show_places));
        }
    }
    
    return returnValue;
}

// input'lar için show_places kullanılarak step ve min değerlerinin hesaplanması
const getMinAndStepWithShowPlaces = (show_places) => {
    let returnValue = "1";

    if(Number(show_places) > 0){
        let zeroValue = "";
        for (let i = 1; i < Number(show_places); i++) {
            zeroValue += "0";
        }
        returnValue = "0." + zeroValue + "1";
    }

    return returnValue;
}

// wallet listesindeki her bir para birimi için price hesabı
const getPriceForWalletCurrencies = (item, walletData, productsData) => {
    let price = "NaN";
    
    if(walletData[item] && walletData[item].count){
        if(item == "USDT"){
            price = walletData[item].count;
        }
        else{
            if(productsData[item+"-USDT"] && productsData[item+"-USDT"].currentPrice){//ABC-USDT var mı diye bakılıyor
                price = new Decimal(productsData[item+"-USDT"].currentPrice).times(new Decimal(walletData[item].count));
            }
            else{//ABC-USDT yok. bağlantılı olduğu başka bir product var ise oradan çevrilecek
                const productsDataKeys = Object.keys(productsData);

                productsDataKeys.forEach((key) => {
                    if(key.includes(item + "-") && price == "NaN"){//ABC-D bulunması için döngü. ABC- olarak aratıyoruz.
                        let tempCurrency = key.split("-")[1];//D
                        let tempPrice = productsData[key] && productsData[key].currentPrice ? productsData[key].currentPrice : null;//1 ABC için D değeri
                        if(tempPrice != null){//tempPrice değeri var ise
                            productsDataKeys.forEach((tempKey) => {
                                if(tempKey == tempCurrency + "-USDT" && price == "NaN"){//D-USDT bulunacak. price değeri NaN değilse döngüye devam edilmeyecek, zaten price bulunmuş.
                                    if(productsData[tempKey] && productsData[tempKey].currentPrice){//D-USDT için price değeri var mı
                                        price = new Decimal(productsData[tempKey].currentPrice).times(new Decimal(tempPrice));//1 ABC için D değeri ile D'nin USDT değeri çarpılıyor. yani ABC için USDT değeri bulunmuş oluyor.
                                        price = price.times(new Decimal(walletData[item].count));//bulunan değeri de cüzdandaki değer ile çarpıyoruz
                                    }
                                }
                            });
                        }
                    }
                });

            }
        }
    }
    
    return price;
}

// gelen item için currentPrice USDT değeri
const getPriceForCurrency = (item, productsData) => {
    let price = "NaN";
    
    if(item == "USDT"){
        price = 1;
    }
    else{
        if(productsData[item+"-USDT"] && productsData[item+"-USDT"].currentPrice){//ABC-USDT var mı diye bakılıyor
            price = new Decimal(productsData[item+"-USDT"].currentPrice);
        }
        else{//ABC-USDT yok. bağlantılı olduğu başka bir product var ise oradan çevrilecek
            const productsDataKeys = Object.keys(productsData);

            productsDataKeys.forEach((key) => {
                if(key.includes(item + "-") && price == "NaN"){//ABC-D bulunması için döngü. ABC- olarak aratıyoruz.
                    let tempCurrency = key.split("-")[1];//D
                    let tempPrice = productsData[key] && productsData[key].currentPrice ? productsData[key].currentPrice : null;//1 ABC için D değeri
                    if(tempPrice != null){//tempPrice değeri var ise
                        productsDataKeys.forEach((tempKey) => {
                            if(tempKey == tempCurrency + "-USDT" && price == "NaN"){//D-USDT bulunacak. price değeri NaN değilse döngüye devam edilmeyecek, zaten price bulunmuş.
                                if(productsData[tempKey] && productsData[tempKey].currentPrice){//D-USDT için price değeri var mı
                                    price = new Decimal(productsData[tempKey].currentPrice).times(new Decimal(tempPrice));//1 ABC için D değeri ile D'nin USDT değeri çarpılıyor. yani ABC için USDT değeri bulunmuş oluyor.
                                }
                            }
                        });
                    }
                }
            });

        }
    }
    
    return price != "NaN" && price != 1 ? price.toString() : price;
}

// gelen value'nun tam sayı kısmını basamak sınırına göre kontrol ediyor
const valueControlWithDigitLimit = (value, digit_limit) => {
    let stringValue = String(value);
    let parts = stringValue.split(/[.,]/);
    let integerPart = parts[0];
  
    //tam sayı için basamak sayısının kontrolü
    if (integerPart.length > digit_limit) {
      return false;
    }
  
    return true;
}





//BİRLİKTE KULLANILAN FONKSİYONLAR - BİLGİ FORMATLAMA
//sayının noktanın sağındaki kısmının sonundaki gereksiz sıfırları temizleme
const editNumber_reducing_trailing_zeros = (value) => {
    const stringValue = String(value).replace(',', '.');
    let [integerPart, decimalPart] = stringValue.split('.');
    
    // Eğer ondalık kısmı varsa, sonundaki sıfırları temizleyelim
    if (decimalPart) {
        // Sonundaki sıfırları silmek için RegExp kullanıyoruz
        decimalPart = decimalPart.replace(/0+$/, '');
        
        // Eğer ondalık kısmı tamamen sıfırdan temizlendiyse, sadece tam sayıyı döndür
        if (decimalPart === '') {
            return integerPart;
        } else {
            return `${integerPart}.${decimalPart}`;
        }
    } else {
        // Ondalık kısmı yoksa, direkt tam sayıyı döndür
        return integerPart;
    }
};

//sayının noktanın sağındaki kısmında art arda 2 tane sıfır yakalama ve sonrasındakiler sıfır ise temizleme
const editNumber_reducing_trailing_zeros_special = (price) => {
    const stringValue = String(price).replace(',', '.');
    let [integerPart, decimalPart] = stringValue.split('.');

    if (decimalPart) {
        const pattern = /00+$/;
        if (pattern.test(decimalPart)) {
            const match = decimalPart.match(pattern)[0];
            const position = decimalPart.lastIndexOf(match);

            if (position !== -1) {
                decimalPart = decimalPart.substring(0, position + 2);
            }
        }
        return `${integerPart}.${decimalPart}`;
    } else {
        return integerPart;
    }
};

//sayının noktanın sağındaki kısmının basamak sayısını gerekiyorsa azalt
const editNumber_reducing_decimal_places = (value, show_places) => {
    if(show_places != "" && show_places != null && show_places != "NaN"){
        const stringValue = String(value).replace(',', '.');
        let [integerPart, decimalPart] = stringValue.split('.');
        
        //ondalık kısmı yoksa boş string yapıyoruz
        decimalPart = decimalPart || '';
        
        //fazla hane varsa azaltıyoruz
        if (decimalPart.length > show_places) {
            decimalPart = decimalPart.slice(0, show_places);
        }
        
        if(decimalPart){
            return `${integerPart}.${decimalPart}`;
        }
        else{
            return `${integerPart}`;
        }
    }
    else{
        return value;
    }
}

//sayının noktanın sağındaki kısmının basamak sayısını gerekiyorsa arttır
const editNumber_increasing_decimal_places = (value, show_places) => {
    if(show_places != "" && show_places != null && show_places != "NaN"){
        const stringValue = String(value).replace(',', '.');
        let [integerPart, decimalPart] = stringValue.split('.');
        
        //ondalık kısmı yoksa boş string yapıyoruz
        decimalPart = decimalPart || '';
        
        //ondalık kısmına uzunluk kadar sıfır ekliyoruz
        while (decimalPart.length < show_places) {
            decimalPart += '0';
        }
    
        if(decimalPart){
            return `${integerPart}.${decimalPart}`;
        }
        else{
            return `${integerPart}`;
        }
    }
    else{
        return value;
    }
}

//sayının noktanın solundaki kısmını virgül ile ayır
const editNumber_separate_with_comma = (value) => {
    const stringValue = String(value).replace(',', '.');
    const [integerPart, decimalPart] = stringValue.split('.');

    if(integerPart.length > 3){
        //virgül ekleme
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        //ondalık kısmı varsa onu da dahil ediyoruz
        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    }
    else{
        return value;
    }
}

//sayının noktanın sağındaki kısmının basamak sayısı ile show places değerinin karşılaştırılması
const editNumber_control_decimal_places_length = (value, show_places) => {
    if(show_places != "" && show_places != null && show_places != "NaN" && value != "" && value != null && value != "NaN"){
        const stringValue = String(value).replace(',', '.');
        let [integerPart, decimalPart] = stringValue.split('.');
        
        if(decimalPart){
            if (decimalPart.length >= show_places) {
                return false;
            }
            else{
                return true;
            }
        }
        else{
            return true;
        }
    }
    else{
        return false;
    }
}





//TEK BAŞINA ÇAĞRILAN FONKSİYONLAR - UTILS İÇİNDE BİLGİ HESAPLAMA VE GÜNCELLEME
// gelen bilgileri data içindekilerle karşılaştırıyor, veriler yeni ise güncelliyor
const setDataWithLastValues = (data, last_price, last_MT, last_flags = null, last_f = null) => {
    if(last_f){
        if(data["currentPrice"] == null || data["currentPriceTimestamp"] == null || data["currentPriceLastFirstTradeId"] == null || data["currentPriceTimestamp"] < last_MT || data["currentPriceLastFirstTradeId"] < last_f){
            data["previousCurrentPrice"] = Number(data["currentPrice"]);
            data["currentPrice"] = Number(last_price);
            data["currentPriceTimestamp"] = Number(last_MT);
            data["currentPriceLastFirstTradeId"] = Number(last_f);
            if(last_flags){data["flags"] = Number(last_flags);}
            //console.log("current price updated -> current price: " + last_price + " - last f: " + last_f + " - last MT: " + last_MT);
        }
        else{
            //console.log("---not updated---");
        }
    }
    else{
        if(data["currentPrice"] == null || data["currentPriceTimestamp"] == null || data["currentPriceTimestamp"] < last_MT){
            data["previousCurrentPrice"] = Number(data["currentPrice"]);
            data["currentPrice"] = Number(last_price);
            data["currentPriceTimestamp"] = Number(last_MT);
            if(last_flags){data["flags"] = Number(last_flags);}
            //console.log("current price updated -> current price: " + last_price + " - last MT: " + last_MT);
        }
        else{
            //console.log("---not updated---");
        }
    }
    
    return data;
}

const formatNumberWithShowPlaces = (value, show_places) => {
    if(show_places != "" && show_places != null && show_places != "NaN"){
        const stringValue = String(value).replace(',', '.');
        let [integerPart, decimalPart] = stringValue.split('.');
        
        //ondalık kısmı yoksa boş string yapıyoruz
        decimalPart = decimalPart || '';
    
        //ondalık kısmına uzunluk kadar sıfır ekliyoruz
        while (decimalPart.length < show_places) {
            decimalPart += '0';
        }
    
        //fazla hane varsa azaltıyoruz
        if (decimalPart.length > show_places) {
            decimalPart = decimalPart.slice(0, show_places);
        }
    
        return `${integerPart}.${decimalPart}`;
    }
    else{
        return value;
    }
}

// gelen open ve close değerlerine göre 24 saatlik değişim farkı ve yüzdesi hesaplanıyor
const calcPriceAndPercentageDifference = (open, close) => {
    let price_difference = new Decimal(close).minus(new Decimal(open));
    let percentage_difference;
    if(price_difference != 0){
        percentage_difference = (price_difference.dividedBy(new Decimal(open))).times(new Decimal("100"));
    }
    else{
        percentage_difference = new Decimal("0");
    }

    //yüzdeyi yuvarlama işlemi
    let tempFactor = Math.pow(10, 2);
    percentage_difference = Math.floor(percentage_difference * tempFactor) / tempFactor;

    //ondalıklı kısmı 2 basamağa indirme işlemi
    percentage_difference = formatNumberWithShowPlaces(percentage_difference, 2);

    return [Number(price_difference), Number(percentage_difference)];
}





//TEK BAŞINA ÇAĞRILAN FONKSİYONLAR - BİRDEN FAZLA FONKSİYONU KULLANARAK BİLGİ FORMATLAMA
const formatValuePriceControl = (value, showPlaces) => {
    let newValue = value;
    try {
        newValue = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros((Decimal(value)).toFixed()), showPlaces);
    } catch (error) {}
    return newValue;
}

const formatValue1 = (value, showPlaces) => {
    let newValue = value;
    try {
        newValue = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((Decimal(value)).toFixed(), showPlaces), showPlaces)));
    } catch (error) {}
    return newValue;
}

const formatValue2 = (value, showPlaces) => {
    let newValue = value;
    try {
        newValue = editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((Decimal(value)).toFixed(), showPlaces), showPlaces));
    } catch (error) {}
    return newValue;
}

const formatValue3 = (value, showPlaces) => {
    let newValue = value;
    try {
        newValue = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros((Decimal(value)).toFixed()), showPlaces), showPlaces));
    } catch (error) {}
    return newValue;
}

const formatValue4 = (value) => {
    let newValue = value;
    try {
        newValue = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros((Decimal(value)).toFixed()));
    } catch (error) {}
    return newValue;
}

const formatValue5 = (value, showPlaces) => {
    let newValue = value;
    try {
        newValue = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((Decimal(value)).toFixed(), showPlaces), showPlaces));
    } catch (error) {}
    return newValue;
}





export {
    getValueWithShowPlaces,
    getMinAndStepWithShowPlaces,
    getPriceForWalletCurrencies,
    getPriceForCurrency,
    valueControlWithDigitLimit,
    setDataWithLastValues,
    calcPriceAndPercentageDifference,
    editNumber_separate_with_comma,
    formatValuePriceControl,
    formatValue1,
    formatValue2,
    formatValue3,
    formatValue4,
    formatValue5,
};