import React, { useState, useEffect, useRef } from "react";
import "./Styles/page_verification_steps.css";
import Loading from "../components/Global/Loading";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import Step1Icon from '@mui/icons-material/EmailRounded';
import Step2Icon from '@mui/icons-material/DescriptionRounded';
import Step3Icon from '@mui/icons-material/PhotoLibraryRounded';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const VerificationSteps = ({ pageType, userInfo }) => {

    const [activeStep2, setActiveStep2] = useState(0);
    const [activeStep3, setActiveStep3] = useState(0);
    const [formType, setFormType] = useState("");
    const [formNumber, setFormNumber] = useState("");
    const [formNumber1ClassName, setFormNumber1ClassName] = useState("");
    const [formNumber2ClassName, setFormNumber2ClassName] = useState("");
    const [formNumberInner, setFormNumberInner] = useState("");
    const [formNumberInner1ClassName, setFormNumberInner1ClassName] = useState("");
    const [formNumberInner2ClassName, setFormNumberInner2ClassName] = useState("");
    const [formNumberInner3ClassName, setFormNumberInner3ClassName] = useState("");
    const [formNumberInner4ClassName, setFormNumberInner4ClassName] = useState("");
    const mainForm = useRef(null);
    const mainFormOuter = useRef(null);


    const [infoVerification, setInfoVerification] = useState("");
    const [phoneVerification, setPhoneVerification] = useState("");
    const [identityType, setIdentityType] = useState("");
    const [identity1Verification, setIdentity1Verification] = useState("");
    const [identity2Verification, setIdentity2Verification] = useState("");
    const [customerImageVerification, setCustomerImageVerification] = useState("");
    const [residenceVerification, setResidenceVerification] = useState("");

    const InfoForm = ({verification, infoTextLower, infoTextUpper}) => {//pending ve approved bilgilendirme ekranları
        if(infoTextLower == "all"){
            return (
                <div className={verification == "approved" ? "title result success" : "title result waiting"}>
                    {verification == "approved" ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-clock"></i>}
                    {verification == "approved" ? <p>Customer verification process is complete!</p> : <p>Customer information submission process has been completed and approval is awaited!</p>}
                </div>
            );
        }
        else if(infoTextLower == "section all" || infoTextUpper == "section all"){
            return (
                <div className={verification == "approved" ? "title result success" : "title result waiting"}>
                    {verification == "approved" ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-clock"></i>}
                    {verification == "approved" ? <p>{infoTextUpper} verification completed!</p> : <p>Pending approval for {infoTextLower} verification!</p>}
                </div>
            );
        }
        else{
            return (
                <div className={verification == "approved" ? "title result success" : (verification == "pending" ? "title result waiting" : "title result")}>
                    {verification == "approved" ? <p>{infoTextUpper} verification completed!</p> : 
                    (verification == "pending" ? <p>Pending approval for {infoTextLower} verification!</p>
                    : <p>Waiting for information for {infoTextLower} verification!</p>)}
                </div>
            );
        }
    }
    
    const formNumbersClickControl_class = (number, numberType) => {
        return "clickable";
    }

    const formNumbersClickControl_function = (number, numberType) => {
        if(numberType == "outer"){
            if(number == "0"){
                setFormType("f1.0.0");
            }
            else if(number == "1"){
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    setFormType("f1.3.0");
                }
                else if(infoVerification == "approved"){
                    setFormType("f1.2.1");
                }
                else{
                    setFormType("f1.1.1");
                }
            }
            else if(number == "2"){
                if(identityType == ""){
                    setFormType("f2.0.0");
                }
                else if(identity1Verification != "approved" && identity1Verification != "pending"){
                    setFormType("f2.1.1");
                }
                else if(identity2Verification != "approved" && identity2Verification != "pending"){
                    setFormType("f2.2.1");
                }
                else if(customerImageVerification != "approved" && customerImageVerification != "pending"){
                    setFormType("f2.3.1");
                }
                else if(residenceVerification != "approved" && residenceVerification != "pending"){
                    setFormType("f2.4.1");
                }
                else if(identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending"){
                    setFormType("f3.0.0");
                }
                else if(identity1Verification == "approved" && identity2Verification == "approved" && customerImageVerification == "approved" && residenceVerification == "approved"){
                    setFormType("f4.0.0");
                }
            }
        }
        else if(numberType == "inner"){
            if(formNumber == "1" || formNumber == "01"){
                if(number == "1"){
                    setFormType("f1.1.1");
                }
                else if(number == "2"){
                    setFormType("f1.2.1");
                }
            }
            else{
                if(number == "1"){
                    if(identityType == ""){
                        setFormType("f2.0.0");
                    }
                    else{
                        setFormType("f2.1.1");
                    }
                }
                else if(number == "2"){
                    setFormType("f2.2.1");
                }
                else if(number == "3"){
                    setFormType("f2.3.1");
                }
                else if(number == "4"){
                    setFormType("f2.4.1");
                }
            }
        }
    }

    useEffect(() => {//güncel user info değerlerinden güncel verification değerleri çekiliyor
        if(userInfo && userInfo.customer_id){
            let temp_info_verification = userInfo.info_verification ? userInfo.info_verification : "";
            let temp_phone_verification = userInfo.phone_verification ? userInfo.phone_verification : "";
            let temp_identity_type = userInfo.identity_type ? userInfo.identity_type : "";
            let temp_identity1_verification = userInfo.identity1_verification ? userInfo.identity1_verification : "";
            let temp_identity2_verification = userInfo.identity2_verification ? userInfo.identity2_verification : "";
            let temp_customer_image_verification = userInfo.customer_image_verification ? userInfo.customer_image_verification : "";
            let temp_residence_verification = userInfo.residence_verification ? userInfo.residence_verification : "";

            setInfoVerification(temp_info_verification);
            setPhoneVerification(temp_phone_verification);
            setIdentityType(temp_identity_type);
            setIdentity1Verification(temp_identity1_verification);
            setIdentity2Verification(temp_identity2_verification);
            setCustomerImageVerification(temp_customer_image_verification);
            setResidenceVerification(temp_residence_verification);
        }
        else{
            setFormType("f0.0.0");
        }
    }, [userInfo]);

    useEffect(() => {//verification değerleri güncellendikçe formlar belirleniyor
        if(userInfo && userInfo.customer_id){
            if(infoVerification != "approved"){
                setFormType("f1.1.1");//ikametgah ülkesi seçimi
            }
            else if(phoneVerification != "approved"){
                setFormType("f1.2.1");//telefon girişi
            }
            else if(identityType == "" && (identity1Verification == "" || identity1Verification == "denied") && (identity2Verification == "" || identity2Verification == "denied")){
                setFormType("f2.0.0");//yükleme seçenekleri
            }
            else if(identityType != "" && identity1Verification != "approved" && identity1Verification != "pending"){
                setFormType("f2.1.1");//ön taraf
            }
            else if(identityType != "" && identity2Verification != "approved" && identity2Verification != "pending"){
                setFormType("f2.2.1");//arka taraf
            }
            else if(customerImageVerification != "approved" && customerImageVerification != "pending"){
                setFormType("f2.3.1");//anlık fotoğraf çekimi
            }
            else if(residenceVerification != "approved" && residenceVerification != "pending"){
                setFormType("f2.4.1");//ikametgah belgesinin yüklenmesi
            }
            else if(identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending"){
                setFormType("f3.0.0");//onay bekleniyor
            }
            else{
                setFormType("f4.0.0");//doğrulama tamamlandı
            }
        }
        else{
            setFormType("f0.0.0");
        }
    }, [userInfo, infoVerification, phoneVerification, identityType, identity1Verification, identity2Verification, customerImageVerification, residenceVerification]);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor. animasyon sağlıyoruz, bilgileri temizliyoruz ve verification kodunun yapıştırılmasını algılıyoruz.
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }

        if(formType=="f0.0.0"){
            setFormNumber("01");
            setFormNumberInner("0");
            setActiveStep2(0);
            setActiveStep3(0);
        }
        if(formType=="f3.0.0" || formType=="f4.0.0"){
            setFormNumber("2");
            setFormNumberInner("0");
            setActiveStep2(1);
            setActiveStep3(4);
        }
        else if(formType=="f1.0.0"){
            setFormNumber("0");
            setFormNumberInner("0");
        }
        else if(formType=="f1.1.1" || formType=="f1.1.2" || formType=="f1.1.3"){
            setFormNumber("1");
            setFormNumberInner("1");
            setActiveStep2(0);
        }
        else if(formType=="f1.2.1" || formType=="f1.2.2" || formType=="f1.3.0"){
            setFormNumber("1");
            if(formType=="f1.3.0"){
                setActiveStep2(3);
                setFormNumberInner("3");
            }
            else{
                setActiveStep2(1);
                setFormNumberInner("2");
            }
        }
        else if(formType=="f2.0.0" || formType=="f2.1.1"){
            setFormNumber("2");
            setFormNumberInner("1");
            setActiveStep3(0);
        }
        else if(formType=="f2.2.1"){
            setFormNumber("2");
            setFormNumberInner("2");
            setActiveStep3(1);
        }
        else if(formType=="f2.3.1"){
            setFormNumber("2");
            setFormNumberInner("3");
            setActiveStep3(2);
        }
        else if(formType=="f2.4.1"){
            setFormNumber("2");
            setFormNumberInner("4");
            setActiveStep3(3);
        }
    }, [formType]);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor. animasyon sağlıyoruz, bilgileri temizliyoruz ve verification kodunun yapıştırılmasını algılıyoruz.
        if(mainFormOuter && mainFormOuter.current){
            mainFormOuter.current.style.animation = "none";
            let tempForAnimation = mainFormOuter.current.offsetHeight;
            mainFormOuter.current.style.animation = "open-animation 1.5s";
        }
    }, [formNumber]);

    useEffect(() => {//form numaralarının class isimleri belirleniyor
        if(infoVerification == "approved" && phoneVerification == "approved"){
            setFormNumber1ClassName("approved");
        }
        else if(infoVerification == "denied" || phoneVerification == "denied"){
            setFormNumber1ClassName("denied");
        }
        else{
            setFormNumber1ClassName("");
        }

        if(identity1Verification == "approved" && identity2Verification == "approved" && customerImageVerification == "approved" && residenceVerification == "approved"){
            setFormNumber2ClassName("approved");
        }
        else if(identity1Verification == "denied" || identity2Verification == "denied" || customerImageVerification == "denied" || residenceVerification == "denied"){
            setFormNumber2ClassName("denied");
        }
        else if(identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending"){
            setFormNumber2ClassName("pending");
        }
        else{
            setFormNumber2ClassName("");
        }

        if(formNumber == "1" || formNumber == "01"){
            if(infoVerification == "approved"){
                setFormNumberInner1ClassName("approved");
            }
            else if(infoVerification == "denied"){
                setFormNumberInner1ClassName("denied");
            }
            else{
                setFormNumberInner1ClassName("");
            }

            if(phoneVerification == "approved"){
                setFormNumberInner2ClassName("approved");
            }
            else if(phoneVerification == "denied"){
                setFormNumberInner2ClassName("denied");
            }
            else{
                setFormNumberInner2ClassName("");
            }
        }
        else if(formNumber == "2" || formNumber == "02"){
            if(identity1Verification == "approved"){
                setFormNumberInner1ClassName("approved");
            }
            else if(identity1Verification == "denied"){
                setFormNumberInner1ClassName("denied");
            }
            else if(identity1Verification == "pending"){
                setFormNumberInner1ClassName("pending");
            }
            else{
                setFormNumberInner1ClassName("");
            }

            if(identity2Verification == "approved"){
                setFormNumberInner2ClassName("approved");
            }
            else if(identity2Verification == "denied"){
                setFormNumberInner2ClassName("denied");
            }
            else if(identity2Verification == "pending"){
                setFormNumberInner2ClassName("pending");
            }
            else{
                setFormNumberInner2ClassName("");
            }

            if(customerImageVerification == "approved"){
                setFormNumberInner3ClassName("approved");
            }
            else if(customerImageVerification == "denied"){
                setFormNumberInner3ClassName("denied");
            }
            else if(customerImageVerification == "pending"){
                setFormNumberInner3ClassName("pending");
            }
            else{
                setFormNumberInner3ClassName("");
            }

            if(residenceVerification == "approved"){
                setFormNumberInner4ClassName("approved");
            }
            else if(residenceVerification == "denied"){
                setFormNumberInner4ClassName("denied");
            }
            else if(residenceVerification == "pending"){
                setFormNumberInner4ClassName("pending");
            }
            else{
                setFormNumberInner4ClassName("");
            }
        }
    }, [formType, formNumber, formNumberInner, infoVerification, phoneVerification, identityType, identity1Verification, identity2Verification, customerImageVerification, residenceVerification]);

    useEffect(() => {//madde başlığındaki class ismi bir üst katmana taşınıyor
        const stepTitles = document.getElementsByClassName("step-title");

        if(stepTitles){
            let container = null;
            for (let i = 0; i < stepTitles.length; i++) {
                container = null;
                if(stepTitles[i]){
                    container = stepTitles[i].closest(".MuiStepLabel-root");
                    if(container){
                        container.classList.remove("clickable");
                        container.classList.remove("not-clickable");
                        container.classList.remove("approved");
                        container.classList.remove("pending");
                        container.classList.remove("denied");
                        container.classList.remove("active");
                        container.classList.remove("deactive");
                        for (let x = 0; x < stepTitles[i].classList.length; x++) {
                            if(stepTitles[i].classList[x] && stepTitles[i].classList[x] != "step-title"){
                                container.classList.add(stepTitles[i].classList[x]);
                            }
                        }
                    }
                }
            }
        }
    }, [formNumber, formNumberInner, formNumber1ClassName, formNumber2ClassName, formNumberInner1ClassName, formNumberInner2ClassName, formNumberInner3ClassName, formNumberInner4ClassName]);





    //üst maddeler - başlangıç
    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
          color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
          color: '#784af4',
          zIndex: 1,
          fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
    }));
      
    function QontoStepIcon(props) {
        const { active, completed, className } = props;
      
        return (
          <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
              <Check className="QontoStepIcon-completedIcon" />
            ) : (
              <div className="QontoStepIcon-circle" />
            )}
          </QontoStepIconRoot>
        );
    }
      
    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };
      
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
    }));
      
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
    }));
      
    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
        const icons = {
          1: <Step1Icon />,
          2: <Step2Icon />,
          3: <Step3Icon />,
        };
      
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
    }
      
    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };
    //üst maddeler - bitiş

    //alt maddeler - başlangıç
    const steps1 = [
        {
            label: <div className={`step-title active approved`}>Email Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {formType == "f1.0.0" && <InfoForm verification="approved" infoTextLower="email" infoTextUpper="Email" />}
                </div>
            </>,
        }
    ];

    const steps2 = [
        {
            label: <div className={`step-title ${formNumberInner == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "inner")} ${formNumberInner1ClassName}`} onClick={() => formNumbersClickControl_function("1", "inner")}>Info Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {(formType == "f1.1.1" || formType == "f1.1.2" || formType == "f1.1.3") && <InfoForm verification={infoVerification} infoTextLower="info" infoTextUpper="Info" />}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "inner")} ${formNumberInner2ClassName}`} onClick={() => formNumbersClickControl_function("2", "inner")}>Phone Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {(formType == "f1.2.1" || formType == "f1.2.2") && <InfoForm verification={phoneVerification} infoTextLower="phone" infoTextUpper="Phone" />}
                </div>
            </>,
        },
    ];

    const steps3 = [
        {
            label: <div className={`step-title ${formNumberInner == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "inner")} ${formNumberInner1ClassName}`} onClick={() => formNumbersClickControl_function("1", "inner")}>Front ID Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {(formType == "f2.0.0" || formType == "f2.1.1") && <InfoForm verification={identity1Verification} infoTextLower={`front side of customer ${identityType}`} infoTextUpper={`Front side of customer ${identityType}`} />}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "inner")} ${formNumberInner2ClassName}`} onClick={() => formNumbersClickControl_function("2", "inner")}>Back ID Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {formType == "f2.2.1" && <InfoForm verification={identity2Verification} infoTextLower={`back side of customer ${identityType}`} infoTextUpper={`Back side of customer ${identityType}`} />}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "3" ? "active" : "deactive"} ${formNumbersClickControl_class("3", "inner")} ${formNumberInner3ClassName}`} onClick={() => formNumbersClickControl_function("3", "inner")}>User Photo</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {formType == "f2.3.1" && <InfoForm verification={customerImageVerification} infoTextLower="customer photo" infoTextUpper="Customer photo" />}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "4" ? "active" : "deactive"} ${formNumbersClickControl_class("4", "inner")} ${formNumberInner4ClassName}`} onClick={() => formNumbersClickControl_function("4", "inner")}>Residence Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {formType == "f2.4.1" && <InfoForm verification={residenceVerification} infoTextLower="residence" infoTextUpper="Residence" />}
                </div>
            </>,
        },
    ];
    //alt maddeler - bitiş

    return(
        <div className={pageType != "readonly" ? "section-verification-steps" : "section-verification-steps readonly"}>
            {formType != "f0.0.0" && <div className="form-numbers">
                <Stepper alternativeLabel activeStep={Number(formNumber)} connector={<ColorlibConnector />}>
                    <Step key="Register" className={`outer-step-title ${formNumber == "0" ? "active" : "deactive"} clickable approved`} onClick={() => formNumbersClickControl_function("0", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Register</div><div className="level">{userInfo && userInfo.status_level && (userInfo.status_level == "1" || userInfo.status_level == "2" || userInfo.status_level == "3") && <i className="fa-solid fa-crown"></i>}Lvl 1</div></StepLabel>
                    </Step>
                    <Step key="Informations" className={`outer-step-title ${formNumber == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "outer")} ${formNumber1ClassName}`} onClick={() => formNumbersClickControl_function("1", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Informations</div><div className="level">{userInfo && userInfo.status_level && (userInfo.status_level == "2" || userInfo.status_level == "3") && <i className="fa-solid fa-crown"></i>}Lvl 2</div></StepLabel>
                    </Step>
                    <Step key="Images" className={`outer-step-title ${formNumber == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "outer")} ${formNumber2ClassName}`} onClick={() => formNumbersClickControl_function("2", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Images</div><div className="level">{userInfo && userInfo.status_level && userInfo.status_level == "3" && <i className="fa-solid fa-crown"></i>}Lvl 3</div></StepLabel>
                    </Step>
                </Stepper>
            </div>}
            {formType == "f0.0.0" ? <>
                <div className="loading">
                    <Loading />
                </div>
            </>
            :
            <div className="main-form-outer" ref={mainFormOuter}>
                <Box>
                    {formNumber == "0" ? 
                        <Stepper activeStep={1} orientation="vertical">
                            {steps1.map((step, index) => (
                            <Step key={`${step.label}-${index}`}>
                                <StepLabel>{step.label}</StepLabel>
                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                </StepContent>
                            </Step>
                            ))}
                        </Stepper>
                    :
                        (formNumber == "1" || formNumber == "01" ? 
                            <Stepper activeStep={activeStep2} orientation="vertical">
                                {steps2.map((step, index) => (
                                <Step key={`${step.label}-${index}`}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>
                        : 
                            <Stepper activeStep={activeStep3} orientation="vertical">
                                {steps3.map((step, index) => (
                                <Step key={`${step.label}-${index}`}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>)
                    }
                    {formType == "f1.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="section all" infoTextUpper="Email" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f1.3.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="section all" infoTextUpper="Informations" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f3.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="pending" infoTextLower="all" infoTextUpper="All" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f4.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="all" infoTextUpper="All" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                </Box>
            </div>
            }
        </div>
    )

}

export default VerificationSteps;