import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import axios from "axios";
import moment from "moment-timezone";
import Loading from "../components/Global/Loading";
import Tooltip from '@mui/material/Tooltip';

const Popup_DepositNetworkAddressesDetails = ({ selectedCustomerId, setSelectedCustomerId, selectedAccountName, setSelectedAccountName, type }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    
    const [selectedCustomerIdInfo, setSelectedCustomerIdInfo] = useState(null);
    const [selectedAccountNameInfo, setSelectedAccountNameInfo] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [internalAccountName, setInternalAccountName] = useState(null);

    useEffect(() => {
        if(selectedCustomerId != null || selectedAccountName != null){
            setLoadingError(false);
            setDisplayDialog(true);
            if(selectedCustomerId != null){
                getUserDetailsWithCustomerId();
            }
            else{
                getUserDetailsWithAccountName(selectedAccountName);
            }
        }
        else{
            setLoadingError(false);
            if(internalAccountName == null){
                setDisplayDialog(false);
            }
            setSelectedCustomerIdInfo(null);
            setSelectedAccountNameInfo(null);
        }
    }, [selectedCustomerId, selectedAccountName]);

    useEffect(() => {
        if(internalAccountName != null){
            setLoadingError(false);
            if(selectedCustomerId != null){
                setSelectedCustomerId(null);
            }
            if(selectedAccountName != null){
                setSelectedAccountName(null);
            }
            getUserDetailsWithAccountName(internalAccountName);
            setDisplayDialog(false);
            setDisplayDialog(true);
        }
        else{
            setLoadingError(false);
            setDisplayDialog(false);
            setSelectedCustomerIdInfo(null);
            setSelectedAccountNameInfo(null);
        }
    }, [internalAccountName]);

    const getUserDetailsWithCustomerId = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/deposit/account?customer_id=${selectedCustomerId}&type=user`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.deposit_accounts && response.data.deposit_accounts[0]){
                    let newData = response.data.deposit_accounts[0];
                    if(newData.adding_timestamp){
                        newData.adding_timestamp = Number(newData.adding_timestamp);
                        newData.adding_date = moment(newData.adding_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    }
                    if(newData.assignment_time){
                        newData.assignment_time = Number(newData.assignment_time);
                        newData.assignment_date = moment(newData.assignment_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    }
                    setSelectedCustomerIdInfo(newData);

                    if(newData.deposit_addresses){
                        setSelectedAccountNameInfo(newData.deposit_addresses);
                    }
                }
                else{
                    setLoadingError(true);
                }
            }
            else{
                setLoadingError(true);
            }
        }
        catch (error) {
            if(selectedCustomerId != null){
                setSelectedCustomerId(null);
            }
            toast.error("There was a problem loading the details!");
        }
    }

    const getUserDetailsWithAccountName = async (accountName) => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/deposit/account?type=deposit_account&deposit_account_name=${accountName}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.deposit_accounts && response.data.deposit_accounts[0]){
                    let newData = response.data.deposit_accounts[0];
                    if(newData.adding_timestamp){
                        newData.adding_timestamp = Number(newData.adding_timestamp);
                        newData.adding_date = moment(newData.adding_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    }
                    if(newData.assignment_time){
                        newData.assignment_time = Number(newData.assignment_time);
                        newData.assignment_date = moment(newData.assignment_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    }
                    setSelectedCustomerIdInfo(newData);

                    if(newData.deposit_addresses){
                        setSelectedAccountNameInfo(newData.deposit_addresses);
                    }
                }
                else{
                    setLoadingError(true);
                }
            }
            else{
                setLoadingError(true);
            }
        }
        catch (error) {
            if(selectedAccountName != null){
                setSelectedAccountName(null);
            }
            if(internalAccountName != null){
                setInternalAccountName(null);
            }
            toast.error("There was a problem loading the details!");
        }
    }

    const copyInfo = async (info) => {
        try {
            await navigator.clipboard.writeText(info);
            toast.success("Copied successfully!");
        } catch (err) {
            toast.error("There was a problem copying the info!");
        }
    }
    
    return(
        <Dialog header={type == "User and Addresses Details" || internalAccountName != null ? "Deposit Network Addresses Details" : "User Details"} visible={displayDialog} draggable={false} resizable={false} className="info-dialog" onHide={() => {if(selectedCustomerId != null){setSelectedCustomerId(null);}else if(selectedAccountName != null){setSelectedAccountName(null);}else if(internalAccountName != null){setInternalAccountName(null);}}}>
            {loadingError ? <div className="loading-error">Details not found!</div>
            : <>
                {selectedCustomerIdInfo ? <>
                    {type == "User and Addresses Details" || internalAccountName != null ? 
                        <>
                            <div className="user-details">
                                {selectedCustomerIdInfo.customer_id && 
                                    <div className="item">
                                        <label>Customer ID</label>
                                        <div>
                                            <span>{selectedCustomerIdInfo.customer_id}</span>
                                        </div>
                                    </div>
                                }
                                {selectedCustomerIdInfo.account_name && 
                                    <div className="item">
                                        <label>Account Name</label>
                                        <div>
                                            <span>{selectedCustomerIdInfo.account_name}</span>
                                        </div>
                                    </div>
                                }
                                {selectedCustomerIdInfo.adding_date && 
                                    <div className="item">
                                        <label>Adding Date</label>
                                        <div>
                                            <span>{selectedCustomerIdInfo.adding_date}</span>
                                        </div>
                                    </div>
                                }
                                {selectedCustomerIdInfo.assignment_date && 
                                    <div className="item">
                                        <label>Assignment Date</label>
                                        <div>
                                            <span>{selectedCustomerIdInfo.assignment_date}</span>
                                        </div>
                                    </div>
                                }
                                {selectedCustomerIdInfo.using_status && 
                                    <div className="item">
                                        <label>Using Status</label>
                                        <div>
                                            <span>{selectedCustomerIdInfo.using_status}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="user-details addresses">
                                {Object.keys(selectedAccountNameInfo).sort().map((key) => (
                                    <div className="item" key={key}>
                                        <label>{key}</label>
                                        <div>
                                            <span>{selectedAccountNameInfo[key]}</span>
                                            <Tooltip title="Copy" placement="bottom">
                                                <i className="fa-solid fa-copy" onClick={() => copyInfo(selectedAccountNameInfo[key])}></i>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                        : 
                        <div className="user-details">
                            {selectedCustomerIdInfo.customer_id && 
                                <div className="item">
                                    <label>Customer ID</label>
                                    <div>
                                        <span>{selectedCustomerIdInfo.customer_id}</span>
                                    </div>
                                </div>
                            }
                            {selectedCustomerIdInfo.account_name && 
                                <div className="item">
                                    <label>Account Name</label>
                                    <div>
                                        <span>{selectedCustomerIdInfo.account_name}</span>
                                        <i className="fa-solid fa-share-from-square" onClick={() => setInternalAccountName(selectedCustomerIdInfo.account_name)}></i>
                                    </div>
                                </div>
                            }
                            {selectedCustomerIdInfo.adding_date && 
                                <div className="item">
                                    <label>Adding Date</label>
                                    <div>
                                        <span>{selectedCustomerIdInfo.adding_date}</span>
                                    </div>
                                </div>
                            }
                            {selectedCustomerIdInfo.assignment_date && 
                                <div className="item">
                                    <label>Assignment Date</label>
                                    <div>
                                        <span>{selectedCustomerIdInfo.assignment_date}</span>
                                    </div>
                                </div>
                            }
                            {selectedCustomerIdInfo.using_status && 
                                <div className="item">
                                    <label>Using Status</label>
                                    <div>
                                        <span>{selectedCustomerIdInfo.using_status}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </> : <Loading />}
            </>}
        </Dialog>
    )

}

export default Popup_DepositNetworkAddressesDetails;