import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import Loading from "../components/Global/Loading";
import { formatValue1, formatValue2, formatValue3, getValueWithShowPlaces, getMinAndStepWithShowPlaces, getPriceForCurrency, valueControlWithDigitLimit } from '../Lib/calculationFunctions';
import Decimal from 'decimal.js';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Tooltip from '@mui/material/Tooltip';
import "./Styles/page_withdraw.css";

const Withdraw = () => {

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const QuickSearchOptions = ["BTC", "USDT", "USDC", "BNB", "ETH"];
    
    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customerWalletInfo, setCustomerWalletInfo] = useState(null);
    const [withdrawData, setWithdrawData] = useState(null);
    
    const [findCustomerId, setFindCustomerId] = useState(null);
    const [customerExist, setCustomerExist] = useState(false);
    const [customerExistCustomerId, setCustomerExistCustomerId] = useState(null);
    const [findCustomerIdLoading, setFindCustomerIdLoading] = useState(false);

    const [coinList, setCoinList] = useState([]);//select coin listesi
    const [coinListSearch, setCoinListSearch] = useState("");//select coin listesindeki search input değeri
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const currencyMaxBeforePlaces = useSelector((state) => state.kprice.currencyMaxBeforePlaces);

    const [selectedWithdrawType, setSelectedWithdrawType] = useState("crypto_address");//select withdraw type değeri
    const [displaySelectWithdrawType, setDisplaySelectWithdrawType] = useState(false);//select withdraw type görünürlüğü
    
    const [selectedCoin, setSelectedCoin] = useState(null);//select coin değeri
    const [displaySelectCoin, setDisplaySelectCoin] = useState(false);//select coin görünürlüğü
    const [selectedCoinMax, setSelectedCoinMax] = useState(0);//selected coin balance değeri
    const [selectedCoinShowPlaces, setSelectedCoinShowPlaces] = useState(null);//selected coin show places değeri
    const [selectedCoinCurrentPrice, setSelectedCoinCurrentPrice] = useState(null);//selected coin current price değeri

    const [addressValue, setAddressValue] = useState("");//girilen address değeri
    const [addressIsValid, setAddressIsValid] = useState(false);//address değerinin geçerliliği
    const [feeusdValue, setFeeusdValue] = useState("");//input fee değeri
    const [feeusdIsValid, setFeeusdIsValid] = useState(false);//fee değerinin geçerliliği
    const [amountValue, setAmountValue] = useState("");//girilen amount değeri
    const [amountValueError, setAmountValueError] = useState(true);//amount hatası
    const [amountValueError2, setAmountValueError2] = useState(true);//amount hatası
    const [amountValueError3, setAmountValueError3] = useState(true);//amount hatası
    const [minimumWithdraw, setMinimumWithdraw] = useState(0);//minimumWithdraw kontrol değeri
    const [minimumWithdrawShow, setMinimumWithdrawShow] = useState(0);//minimumWithdraw gösterilen değeri
    const [minimumWithdrawShowUsd, setMinimumWithdrawShowUsd] = useState(0);//minimumWithdraw gösterilen değeri usd

    const [selectedNetwork, setSelectedNetwork] = useState(null);//seçili network değeri
    const [displayNetworkSelect, setDisplayNetworkSelect] = useState(false);//network select popup görünürlüğü
    const [networkSelectPrices, setNetworkSelectPrices] = useState(null);//seçili network değeri
    
    const mainForm = useRef(null);
    const amountInput = useRef(null);
    const amountInputMax = useRef(null);
    const [sendRequestLoading, setSendRequestLoading] = useState(false);
    
    //select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);

    useEffect(() => {//customerId gelirse
        if(customerId){
            getCustomerInfo(customerId);
        }
    }, [customerId]);

    useEffect(() => {//animasyon için - link değiştikçe tetiklenecek
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }
    }, [location]);

    useEffect(() => {//customer_id bilgisi varsa çekiliyor
        const currentCustomerId = query.get("customer_id");
        if(currentCustomerId){
            setCustomerId(currentCustomerId);
        }
        else{
            setCustomerId(null);
        }
    }, [query]);

    useEffect(() => {
        if(customerId && customerInfo && customerInfo != "User not found"){
            if(withdrawData == null){
                getWithdrawData();
            }
            if(customerWalletInfo == null){
                getCustomerWalletInfo();
            }
        }
        else if(findCustomerId && customerInfo == "User not found"){
            toast.error("User not found!");
        }
    }, [customerInfo, customerWalletInfo]);

    useEffect(() => {
        if(customerExist && customerExistCustomerId){
            navigate(`/withdraw?customer_id=${customerExistCustomerId}`);
            setFindCustomerId(null);
            setCustomerExist(false);
            setCustomerExistCustomerId(null);
            setFindCustomerIdLoading(false);
        }
    }, [customerExist, customerExistCustomerId]);

    const getCustomerInfo = async (customerIdInfo) => {
        setFindCustomerIdLoading(true);
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer?customer_id=${customerIdInfo}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.customer && response.data.customer[0]){
                    let newData = response.data.customer[0];
                    newData.status_level = Number(newData.status_level);
                    newData.registration_time = Number(newData.registration_time);
                    setCustomerInfo(newData);
                    setCustomerExist(true);
                    setCustomerExistCustomerId(customerIdInfo);
                }
                else{
                    setCustomerInfo("User not found");
                }
            }
            else{
                setCustomerInfo("User not found");
            }
            setFindCustomerIdLoading(false);
        }
        catch (error) {
            setCustomerInfo("User not found");
            if(customerId && !(error && error.response && error.response.data && error.response.data.error && error.response.data.error == "Invalid User")){
                toast.error("There was a problem loading customer information!");
            }
            setFindCustomerIdLoading(false);
        }
    }

    const getCustomerWalletInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/wallet?type=user&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.wallet_items){
                    let newData = {};
                    response.data.wallet_items.forEach((item) => {
                        newData[item.currency] = {
                            currency: item.currency,
                            count: Number(item.total_count)
                        };
                    });
                    setCustomerWalletInfo(newData);
                }
            }
            else{
                toast.error("There was a problem loading the customer's wallet information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the customer's wallet information!");
        }
    }

    const getWithdrawData = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/withdraw`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.currency_networks && response.data.network_types){
                    let newData = response.data;

                    const columnsToAdjust = ['fee_usd', 'min_amount_usd'];
                    Object.keys(newData.network_types).forEach(network => {
                        columnsToAdjust.forEach(col => {
                        if (col in newData.network_types[network]) {
                            newData.network_types[network][col] = Number(newData.network_types[network][col]);
                        }
                        });
                    });
                    
                    setWithdrawData(newData);
                }
            }
            else{
                toast.error("There was a problem loading the withdraw data!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the withdraw data!");
        }
    }

    useEffect(() => {//select coin listelenecek seçenekler
        const newData = [];

        if(currenciesData && withdrawData && withdrawData.currency_networks){
            const coins = Object.keys(withdrawData.currency_networks);
            coins.forEach((item) => {
                let itemFind = true;
                let searchItem = "";

                if (coinListSearch != "") {
                    itemFind = false;
                    if(item && currenciesData && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${item} ${currenciesData[item].currency_name}`;
                    }
                    else if(item){
                        searchItem = `${item}`;
                    }
                    else if(currenciesData && item && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${currenciesData[item].currency_name}`;
                    }

                    if(searchItem != "" && searchItem.toString().toLowerCase().includes(coinListSearch.toLowerCase())){
                        itemFind = true;
                    }
                }

                if(itemFind){
                    newData.push(item);
                }
            });
        }
        
        const newDataSorted = newData.sort((a, b) => {
            // Sayısal anahtarları doğru sıralamak için ek bir kontrol
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
          
            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            }
          
            return a.localeCompare(b);
        });
        
        setCoinList(newDataSorted);
    }, [currenciesData, withdrawData, coinListSearch]);

    useEffect(() => {//coin için max değeri
        if(customerWalletInfo && selectedCoin && customerWalletInfo[selectedCoin] && customerWalletInfo[selectedCoin].count){
            setSelectedCoinMax(customerWalletInfo[selectedCoin].count);
        }
        else{
            setSelectedCoinMax(0);
        }
    }, [customerWalletInfo, selectedCoin]);

    useEffect(() => {//coin için show places ve current price güncel değerleri
        if(selectedCoin && currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places){
            setSelectedCoinShowPlaces(currenciesData[selectedCoin].show_places);
        }
        else{
            setSelectedCoinShowPlaces(null);
        }
        
        setSelectedCoinCurrentPrice(getPriceForCurrency(selectedCoin, productsMiniTickerData));
    }, [currenciesData, productsMiniTickerData, selectedCoin]);

    useEffect(() => {//fee input danger
        if(document.getElementById("input-feeusd")){
            if(selectedWithdrawType == "crypto_address"){
                document.getElementById("input-feeusd").classList.remove('danger');
            }
            else if(selectedWithdrawType == "other" || selectedWithdrawType == "system_correction"){
                if(feeusdValue != ""){
                    validateAndSetFee(document.getElementById("input-feeusd"));
                }
            }
        }
    }, [selectedWithdrawType]);

    useEffect(() => {//minimumWithdraw güncelleme
        let minValue = selectedNetwork && withdrawData && selectedCoin ? new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1).div(new Decimal(selectedCoinCurrentPrice ? selectedCoinCurrentPrice : 1)).toString() : 0;
        let minValueShow = selectedNetwork && withdrawData ? formatValue1((new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1).div(new Decimal(selectedCoinCurrentPrice ? selectedCoinCurrentPrice : 1)).toString()), selectedCoinShowPlaces) : 0;
        
        setMinimumWithdraw(selectedWithdrawType == "crypto_address" ? minValue : 0);
        setMinimumWithdrawShow(selectedWithdrawType == "crypto_address" ? minValueShow : 0);
        setMinimumWithdrawShowUsd(selectedWithdrawType == "crypto_address" ? (withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1) : 0);
    }, [selectedWithdrawType, selectedNetwork, withdrawData, selectedCoinCurrentPrice, selectedCoinShowPlaces]);
    
    useEffect(() => {//amount değeri kontrolleri
        const regex = /^\d+(\.\d+)?$/;

        setAmountValueError(false);
        setAmountValueError2(false);
        setAmountValueError3(false);
        
        if(amountValue != ""){
            const lastAmountValue = getValueWithShowPlaces(selectedCoinShowPlaces, amountValue);
            const isRegexValid = regex.test(lastAmountValue);

            if(!isRegexValid || !valueControlWithDigitLimit(lastAmountValue, currencyMaxBeforePlaces) || Number(lastAmountValue) > selectedCoinMax || Number(lastAmountValue) <= 0 || lastAmountValue == "-0" || Number(minimumWithdraw) > Number(lastAmountValue)){
                amountInput.current.classList.add('danger');
                amountInputMax.current.classList.add('danger');
                setAmountValueError2(true);
            }
            else{
                amountInput.current.classList.remove('danger');
                amountInputMax.current.classList.remove('danger');
            }
            
            setAmountValue(lastAmountValue);

            if(Number(minimumWithdraw) > Number(lastAmountValue)){
                setAmountValueError(true);
            }

            if(selectedWithdrawType != "crypto_address"){
                const currentNetAmount = networkSelectPrices ? new Decimal(lastAmountValue).minus(new Decimal(formatValue2((new Decimal(feeusdValue != "" && feeusdValue != null && feeusdValue != undefined ? feeusdValue : 0).div(new Decimal(selectedCoin && networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? networkSelectPrices[`${selectedCoin}-USDT`].currentPrice : 1)).toString()).toString(), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null)))).toString() : null;
                if(currentNetAmount && Number(currentNetAmount) <= 0){
                    setAmountValueError3(true);
                }
            }
        }
    }, [amountValue, feeusdValue, selectedCoin, selectedCoinCurrentPrice, selectedCoinShowPlaces, minimumWithdraw, currencyMaxBeforePlaces, selectedWithdrawType]);
    
    useEffect(() => {//network temizleme
        setSelectedNetwork(null);
    }, [selectedCoin]);

    useEffect(() => {
        if(selectedWithdrawType == "crypto_address"){
            if(selectedNetwork){
                setFeeusdValue(withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : "");
            }
            else{
                setFeeusdValue("");
            }
        }
    }, [selectedNetwork, withdrawData, selectedWithdrawType]);

    useEffect(() => {//network popup içinde gösterilecek fiyatlar çekiliyor
        let newData = {};
        Object.keys(productsMiniTickerData).forEach((item) => {
            newData[item] = {
                currentPrice: productsMiniTickerData[item] && productsMiniTickerData[item].currentPrice ? productsMiniTickerData[item].currentPrice : 0
            };
        });
        setNetworkSelectPrices(newData);
    }, [productsMiniTickerData]);

    useEffect(() => {//animasyon ve listener
        //select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target) && dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
            setDisplaySelectCoin(false);
        }
        if (dropdownRef3.current && !dropdownRef3.current.contains(e.target) && dropdownRef4.current && !dropdownRef4.current.contains(e.target)) {
            setDisplaySelectWithdrawType(false);
        }
    };

    const validateAndSetAddress = (element) => {///[^a-zA-Z0-9_.-]/g
        const regex = /^[a-zA-Z0-9_.-]{1,512}$/;
        const noDoubleSpaceOrTrimPattern = /^(?! )(?!.*  )(?!.* $).+$/;
        const noOtherSpacesPattern = /^[^\t\n\r]*$/;

        const isRegexValid = regex.test(element.target.value);
        const isNoDoubleSpaceOrTrimValid = noDoubleSpaceOrTrimPattern.test(element.target.value);
        const isNoOtherSpacesValid = noOtherSpacesPattern.test(element.target.value);

        const isValid = isRegexValid && isNoDoubleSpaceOrTrimValid && isNoOtherSpacesValid;
    
        if (!isValid || element.target.value == "" || element.target.value.length > 512){
            element.target.classList.add('danger');
            setAddressIsValid(false);
        }
        else{
            element.target.classList.remove('danger');
            setAddressIsValid(true);
        }
        
        setAddressValue(element.target.value);
    };

    const validateAndSetFee = (element) => {///r'^\d+(\.\d+)?$'
        const regex = /^\d+(\.\d+)?$/;
        const isValid = regex.test(element.value);
    
        if (!isValid || element.value == "" || Number(element.value) < 0 || !valueControlWithDigitLimit(element.value, currencyMaxBeforePlaces)){
            element.classList.add('danger');
            setFeeusdIsValid(false);
        }
        else{
            element.classList.remove('danger');
            setFeeusdIsValid(true);
        }
        
        setFeeusdValue(getValueWithShowPlaces(currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null, element.value));
    };

    const sendRequest = async () => {
        const checkCurrentCurrencyCount = selectedCoinMax;
        const lengthPattern = /^.{1,216}$/; // 1 to 216 characters
        const numberPattern = /^\d+(\.\d+)?$/; // Digits with optional decimal part

        const checkCurrentCurrencyCountIsLengthValid = lengthPattern.test(checkCurrentCurrencyCount);
        const checkCurrentCurrencyCountIsNumberValid = numberPattern.test(checkCurrentCurrencyCount);

        const checkCurrentCurrencyCountIsValid = checkCurrentCurrencyCountIsLengthValid && checkCurrentCurrencyCountIsNumberValid;
        
        if(((selectedWithdrawType == "crypto_address" && selectedNetwork) || ((selectedWithdrawType == "other" || selectedWithdrawType == "system_correction") && feeusdValue != "" && feeusdValue != null && feeusdValue != undefined && feeusdIsValid)) && checkCurrentCurrencyCountIsValid && customerId && selectedCoin && addressValue && addressIsValid && amountValue && !amountValueError && !amountValueError2 && !amountValueError3 && !sendRequestLoading && customerInfo && customerInfo != "User not found" && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "True"){
            setSendRequestLoading(true);

            let data = {};

            if(selectedWithdrawType == "crypto_address"){
                data = {
                    "customer_id": customerId,
                    "withdraw_type": selectedWithdrawType,
                    "check_current_currency_count": String(checkCurrentCurrencyCount),
                    "currency": selectedCoin,
                    "network": selectedNetwork,
                    "address": addressValue,
                    "count": amountValue
                }
            }
            else if(selectedWithdrawType == "other" || selectedWithdrawType == "system_correction"){
                data = {
                    "customer_id": customerId,
                    "withdraw_type": selectedWithdrawType,
                    "check_current_currency_count": String(checkCurrentCurrencyCount),
                    "currency": selectedCoin,
                    "fee_usd": feeusdValue,
                    "address": addressValue,
                    "count": amountValue
                }
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            
            axios.post('https://operationapi.kpriceapp.com/withdraw/request', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    toast.success("Withdraw request sent successfully!");
                    setCustomerWalletInfo(null);
                    setCoinListSearch("");
                    setSelectedCoin(null);
                    setAddressValue("");
                    setFeeusdValue("");
                    setFeeusdIsValid(true);
                    setAmountValue("");
                    setSelectedNetwork(null);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem sending a withdraw request, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSendRequestLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem sending a withdraw request, please try again!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setSendRequestLoading(false);
            });
        }
    }
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-withdraw" ref={mainForm}>
                {customerId ? <>{customerInfo && customerInfo != "User not found" ? 
                <>
                    <Dialog header="Select Network" visible={displayNetworkSelect} draggable={false} resizable={false} className="section-withdraw-dialog" onHide={() => setDisplayNetworkSelect(false)}>
                        <div className="section-withdraw-dialog-body">
                            <div className="info">
                                Please note that only supports the token and network you are withdrawing. If you are unsure, kindly check with the receving platform first.
                            </div>
                            <div className="elements">
                                {withdrawData && withdrawData.currency_networks && withdrawData.currency_networks[selectedCoin] && withdrawData.currency_networks[selectedCoin].map((item, index) => (
                                    <div key={index} className={selectedNetwork ? (selectedNetwork == item ? "element active" : "element") : "element"} onClick={() => {setSelectedNetwork(item);setDisplayNetworkSelect(false);}}>
                                        <div className="element-left">
                                            <span>{withdrawData.network_types && withdrawData.network_types[item] && withdrawData.network_types[item].network_tag ? withdrawData.network_types[item].network_tag : undefined}</span>
                                            <span>{item}</span>
                                        </div>
                                        <div className="element-right">
                                            <label>{withdrawData.network_types && withdrawData.network_types[item] && withdrawData.network_types[item].withdraw_arrival_time ? (<>Arrival Time <p>{withdrawData.network_types[item].withdraw_arrival_time}</p></>) : ""}</label>
                                            <label>Fee <p>$ {withdrawData.network_types && withdrawData.network_types[item] && (withdrawData.network_types[item].fee_usd || withdrawData.network_types[item].fee_usd == 0) ? withdrawData.network_types[item].fee_usd : undefined}</p>{networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? <><p>≈ {formatValue3(new Decimal(withdrawData.network_types && withdrawData.network_types[item] && (withdrawData.network_types[item].fee_usd || withdrawData.network_types[item].fee_usd == 0) ? withdrawData.network_types[item].fee_usd : 0).div(new Decimal(networkSelectPrices[`${selectedCoin}-USDT`].currentPrice)).toString(), currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null)}</p><span>{selectedCoin}</span></> : ""}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Dialog>
                    <div className="withdraw-form">
                        <div className={`withdraw-user ${!(customerInfo && customerInfo != "User not found" && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "True") && "no-permission"}`}>
                            <div className="withdraw-user-inner">
                                <div>
                                    <i className="fa-solid fa-user"></i>
                                    <span>{customerId ? customerId : "-"}</span>
                                    <Tooltip title="Profile" className="detail-button" placement="right">
                                        <a href={`/profile?customer_id=${customerId}`} target="_blank">
                                            <i className="fa-solid fa-user"></i>
                                        </a>
                                    </Tooltip>
                                </div>
                                {customerInfo && customerInfo != "User not found" && customerInfo.full_name && <div>{customerInfo.full_name}</div>}
                            </div>
                        </div>
                        {customerInfo && customerInfo != "User not found" && customerInfo.withdraw_permission && (customerInfo.withdraw_permission == "True" ? 
                        <>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Select type
                                </div>
                                <div className="withdraw-form-element-main">
                                    <div className="withdraw-form-element-main-title">Withdraw Type</div>
                                    <div className="withdraw-form-element-select" ref={dropdownRef3} onClick={() => {if(displaySelectWithdrawType){setDisplaySelectWithdrawType(false)}else{setDisplaySelectWithdrawType(true)}}}>
                                        <div className="no-image">
                                            <span>{selectedWithdrawType == "crypto_address" ? "Crypto Address" : (selectedWithdrawType == "other" ? "Other" : (selectedWithdrawType == "system_correction" ? "System Correction" : ""))}</span>
                                            <label></label>
                                        </div>
                                        <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                        <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                                    </div>
                                    <div className="withdraw-form-element-select-options" ref={dropdownRef4} style={displaySelectWithdrawType ? {} : {display: "none"}}>
                                        <div className="items">
                                            <div className={selectedWithdrawType == "crypto_address" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectWithdrawType(false);setSelectedWithdrawType("crypto_address");}}>
                                                <span>Crypto Address</span>
                                                <label></label>
                                            </div>
                                            <div className={selectedWithdrawType == "other" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectWithdrawType(false);setSelectedWithdrawType("other");}}>
                                                <span>Other</span>
                                                <label></label>
                                            </div>
                                            <div className={selectedWithdrawType == "system_correction" ? "item no-image active" : "item no-image"} onClick={() => {setDisplaySelectWithdrawType(false);setSelectedWithdrawType("system_correction");}}>
                                                <span>System Correction</span>
                                                <label></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Select coin
                                </div>
                                <div className="withdraw-form-element-main">
                                    <div className="withdraw-form-element-main-title">Coin</div>
                                    <div className="withdraw-form-element-select" ref={dropdownRef1} onClick={() => {if(displaySelectCoin){setDisplaySelectCoin(false)}else{setDisplaySelectCoin(true)}}}>
                                        {currenciesData ? 
                                            (currenciesData[selectedCoin] ? <div>
                                                <img src={currenciesData[selectedCoin] && currenciesData[selectedCoin].picture_url ? currenciesData[selectedCoin].picture_url : undefined} />
                                                <span>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"}</span>
                                                <label>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency_name ? currenciesData[selectedCoin].currency_name : "NaN"}</label>
                                            </div> 
                                            : <span className="placeholder">Select Coin</span>)
                                        : <div className="section-withdraw-loading"><Loading /></div>}
                                        <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                        <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                                    </div>
                                    <div className="withdraw-form-element-select-options" ref={dropdownRef2} style={displaySelectCoin ? {} : {display: "none"}}>
                                        <div className="search-area">
                                            <div>
                                                <input type="text" className="search" placeholder="Search Coin" value={coinListSearch} onChange={(e) => setCoinListSearch(e.target.value)} />
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                            {QuickSearchOptions && coinList && currenciesData && 
                                                <div className="quick-search">
                                                    {QuickSearchOptions.map((item, index) => (
                                                        currenciesData[item] && 
                                                        <div key={index} className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                            <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                            <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className="items">
                                            {coinList && currenciesData ? 
                                                coinList.map((item, index) => (
                                                    currenciesData[item] && 
                                                    <div key={index} className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                        <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                        <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                        <label>{currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN"}</label>
                                                    </div>
                                                ))
                                            : <Loading />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-form-element">
                                <div className="withdraw-form-element-title">
                                    Send to
                                </div>
                                <div className="withdraw-form-element-main">
                                    <div className="withdraw-form-element-main-title">Address</div>
                                    <input type="text" placeholder="Enter Address" value={addressValue} onChange={(event) => validateAndSetAddress(event)} />
                                    <i className="fa-solid fa-newspaper address-icon"></i>
                                    {selectedWithdrawType == "crypto_address" && <>
                                        <div className="withdraw-form-element-main-title">Network</div>
                                        <div className="withdraw-form-element-select noimg" onClick={() => setDisplayNetworkSelect(true)}>
                                            {selectedNetwork ? <div><span>{withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].network_tag ? withdrawData.network_types[selectedNetwork].network_tag : undefined}</span><label>{selectedNetwork}</label></div> : <span className="placeholder">Select Network</span>}
                                            <i className="fa-solid fa-caret-up" style={displayNetworkSelect ? {} : {display: "none"}}></i>
                                            <i className="fa-solid fa-caret-down" style={displayNetworkSelect ? {display: "none"} : {}}></i>
                                        </div>
                                    </>}
                                    <div className="withdraw-form-element-main-title">Fee (USD)</div>
                                    <input type="number" id="input-feeusd" placeholder={selectedWithdrawType == "crypto_address" ? "Fee" : "Enter Fee"} className="fee" step={currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? getMinAndStepWithShowPlaces(currenciesData["USDT"].show_places) : undefined} min={currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? getMinAndStepWithShowPlaces(currenciesData["USDT"].show_places) : undefined} value={feeusdValue} onChange={(event) => validateAndSetFee(event.target)} disabled={selectedWithdrawType == "crypto_address" ? true : false} />
                                    <div className="withdraw-form-element-main-title">Amount</div>
                                    <input type="number" placeholder="Enter Amount" className="amount" step={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} min={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} value={amountValue} ref={amountInput} onChange={(event) => setAmountValue(event.target.value)} />
                                    <div className={selectedCoinMax && amountValue != "" && getValueWithShowPlaces(selectedCoinShowPlaces, selectedCoinMax) == amountValue ? "amount-max-button active" : "amount-max-button"} ref={amountInputMax} onClick={() => setAmountValue(selectedCoinMax ? selectedCoinMax : 0)}>MAX</div>
                                    <div className="withdraw-form-element-result">
                                        <div className="result-left">
                                            <label style={!selectedCoin ? {visibility: "hidden"} : undefined}>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"} balance</label>
                                            <span style={!selectedCoin ? {visibility: "hidden"} : undefined}>{formatValue1(selectedCoinMax, selectedCoinShowPlaces)}</span>
                                            <label style={((selectedWithdrawType == "crypto_address" && !selectedNetwork) || (selectedWithdrawType != "crypto_address" && !feeusdIsValid)) ? {visibility: "hidden"} : undefined}>Network fee</label>
                                            <span style={((selectedWithdrawType == "crypto_address" && !selectedNetwork) || (selectedWithdrawType != "crypto_address" && !feeusdIsValid)) ? {visibility: "hidden"} : undefined} className="fee"><p>$ {selectedWithdrawType == "crypto_address" ? (withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0) : feeusdValue}</p><span></span>{selectedWithdrawType == "crypto_address" ? (selectedNetwork && networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? <><p>≈ {formatValue1(new Decimal(withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0).div(new Decimal(networkSelectPrices[`${selectedCoin}-USDT`].currentPrice)).toString(), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null))}</p><span>{selectedCoin}</span></> : "") : (networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? <><p>≈ {formatValue1(new Decimal(feeusdValue != "" && feeusdValue != null && feeusdValue != undefined ? feeusdValue : 0).div(new Decimal(networkSelectPrices[`${selectedCoin}-USDT`].currentPrice)).toString(), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null))}</p><span>{selectedCoin}</span></> : "")}</span>
                                        </div>
                                        <div className="result-right" style={selectedWithdrawType == "crypto_address" && !selectedNetwork ? {visibility: "hidden"} : undefined}>
                                            <label>Minimum withdrawal</label>
                                            <span>$ {minimumWithdrawShowUsd} ≈ {minimumWithdrawShow} {selectedCoin}</span>
                                            <label style={selectedWithdrawType != "crypto_address" && !feeusdIsValid ? {visibility: "hidden"} : undefined} className={amountValueError3 ? "danger" : undefined}>Net amount</label>
                                            <span style={selectedWithdrawType != "crypto_address" && !feeusdIsValid ? {visibility: "hidden"} : undefined} className={amountValueError3 ? "danger" : undefined}>{amountValue && !amountValueError && networkSelectPrices ? new Decimal(amountValue).minus(new Decimal(formatValue2((new Decimal(selectedWithdrawType == "crypto_address" ? (withdrawData && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0) : (feeusdValue != "" && feeusdValue != null && feeusdValue != undefined ? feeusdValue : 0)).div(new Decimal(selectedCoin && networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? networkSelectPrices[`${selectedCoin}-USDT`].currentPrice : 1)).toString()).toString(), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null)))).toString() : <div style={{visibility: "hidden"}}>0</div>}</span>
                                        </div>
                                    </div>
                                    <div className="withdraw-form-element-button">
                                        <button type="button" className={((selectedWithdrawType == "crypto_address" && selectedNetwork) || ((selectedWithdrawType == "other" || selectedWithdrawType == "system_correction") && feeusdValue != "" && feeusdValue != null && feeusdValue != undefined && feeusdIsValid)) && customerId && selectedCoin && addressValue && addressIsValid && amountValue && !amountValueError && !amountValueError2 && !amountValueError3 && !sendRequestLoading && customerInfo && customerInfo != "User not found" && customerInfo.withdraw_permission && customerInfo.withdraw_permission == "True" ? "active" : undefined} onClick={() => sendRequest()}>{sendRequestLoading ? <Loading /> : "Send Request"}</button>
                                    </div>
                                    <div className="withdraw-form-element-amount-error" style={amountValueError ? undefined : {display: "none"}}>Minimum withdrawal amount must be {minimumWithdraw} {selectedCoin} or more!</div>
                                </div>
                            </div>
                        </>
                        : <div className="withdraw-form-element-amount-error">Customer does not have withdraw permission, needs to complete verification steps!</div>)}
                    </div>
                </>
                : (customerInfo == "User not found" ? 
                    <div className="no-user">
                        <i className="fa-solid fa-user-slash"></i>
                        <div>User not found</div>
                    </div>
                    : <Loading />)}</>
                : <div className="find-customer-id">
                    <div className="withdraw-form">
                        <div className="withdraw-form-element">
                            <div className="withdraw-form-element-main">
                                <div className="withdraw-form-element-main-title">Customer ID</div>
                                <input type="text" placeholder="Customer ID" value={findCustomerId} onChange={(event) => setFindCustomerId(event.target.value)} />
                                <div className="withdraw-form-element-button">
                                    <button type="button" className={findCustomerId && !findCustomerIdLoading ? "active" : undefined} disabled={findCustomerId == null || findCustomerIdLoading} onClick={() => getCustomerInfo(findCustomerId)}>{findCustomerIdLoading ? <Loading /> : "Find Customer"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )

}

export default Withdraw;