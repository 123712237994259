import React from "react";
import { useSelector } from "react-redux";

const withLoadingData = () => (OriginalComponent) => (props) => {
  const isProductsLoading = useSelector(
    (state) => state.Loading["coin/GET_PRODUCT_IDS"]
  );
  const isUsersLoading = useSelector(
    (state) => state.Loading["coin/GET_USERS"]
  );
  const isDepositLoading = useSelector(
    (state) => state.Loading["coin/GET_DEPOSIT"]
  );
  const isSpotLoading = useSelector(
    (state) => state.Loading["coin/GET_SPOT"]
  );
  const isFutureProfilesLoading = useSelector(
    (state) => state.Loading["coin/GET_FUTURE_PROFILES"]
  );
  const isFutureProcessesLoading = useSelector(
    (state) => state.Loading["coin/GET_FUTURE_PROCESSES"]
  );

  return (
    <OriginalComponent
      {...props}
      isProductsLoading={isProductsLoading}
      isUsersLoading={isUsersLoading}
      isDepositLoading={isDepositLoading}
      isSpotLoading={isSpotLoading}
      isFutureProfilesLoading={isFutureProfilesLoading}
      isFutureProcessesLoading={isFutureProcessesLoading}
    />
  );
};

export default withLoadingData;
